// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutPushTokensInputSchema } from './AccountCreateWithoutPushTokensInputSchema';
import { AccountUncheckedCreateWithoutPushTokensInputSchema } from './AccountUncheckedCreateWithoutPushTokensInputSchema';
import { AccountCreateOrConnectWithoutPushTokensInputSchema } from './AccountCreateOrConnectWithoutPushTokensInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutPushTokensInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutPushTokensInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedCreateWithoutPushTokensInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutPushTokensInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutPushTokensInputSchema;
