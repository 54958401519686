// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const CollaborationCountOrderByAggregateInputSchema: z.ZodType<Prisma.CollaborationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  showInSearch: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default CollaborationCountOrderByAggregateInputSchema;
