// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationWhereInputSchema } from '../inputTypeSchemas/CollaborationWhereInputSchema'
import { CollaborationOrderByWithAggregationInputSchema } from '../inputTypeSchemas/CollaborationOrderByWithAggregationInputSchema'
import { CollaborationScalarFieldEnumSchema } from '../inputTypeSchemas/CollaborationScalarFieldEnumSchema'
import { CollaborationScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/CollaborationScalarWhereWithAggregatesInputSchema'

export const CollaborationGroupByArgsSchema: z.ZodType<Prisma.CollaborationGroupByArgs> = z.object({
  where: CollaborationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationOrderByWithAggregationInputSchema.array(),CollaborationOrderByWithAggregationInputSchema ]).optional(),
  by: CollaborationScalarFieldEnumSchema.array(),
  having: CollaborationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default CollaborationGroupByArgsSchema;
