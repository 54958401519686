// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountCountOutputTypeSelectSchema } from './AccountCountOutputTypeSelectSchema';

export const AccountCountOutputTypeArgsSchema: z.ZodType<Prisma.AccountCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => AccountCountOutputTypeSelectSchema).nullish(),
}).strict();

export default AccountCountOutputTypeSelectSchema;
