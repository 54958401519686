// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutSentCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutSentCollaborationInvitationsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutSentCollaborationInvitationsInputSchema;
