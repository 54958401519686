// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { JsonFilterSchema } from './JsonFilterSchema';
import { JsonNullableFilterSchema } from './JsonNullableFilterSchema';

export const IspProductWhereInputSchema: z.ZodType<Prisma.IspProductWhereInput> = z.object({
  AND: z.union([ z.lazy(() => IspProductWhereInputSchema),z.lazy(() => IspProductWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => IspProductWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => IspProductWhereInputSchema),z.lazy(() => IspProductWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  record: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  transactionReference: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  therapeuticEquivalenceOrBiosimilar: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  holder: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  recordStatus: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  registrationResolution: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  registrationDate: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastRenewal: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nextRenewalDate: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  regime: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  administrationRoute: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  salesCondition: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  establishmentType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  indication: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  packagings: z.lazy(() => JsonFilterSchema).optional(),
  companyFunctions: z.lazy(() => JsonFilterSchema).optional(),
  formulas: z.lazy(() => JsonFilterSchema).optional(),
  cenabast: z.lazy(() => JsonNullableFilterSchema).optional()
}).strict();

export default IspProductWhereInputSchema;
