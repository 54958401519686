// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';
import { NestedEnumACCOUNT_VISIBILITYFilterSchema } from './NestedEnumACCOUNT_VISIBILITYFilterSchema';

export const EnumACCOUNT_VISIBILITYFilterSchema: z.ZodType<Prisma.EnumACCOUNT_VISIBILITYFilter> = z.object({
  equals: z.lazy(() => ACCOUNT_VISIBILITYSchema).optional(),
  in: z.lazy(() => ACCOUNT_VISIBILITYSchema).array().optional(),
  notIn: z.lazy(() => ACCOUNT_VISIBILITYSchema).array().optional(),
  not: z.union([ z.lazy(() => ACCOUNT_VISIBILITYSchema),z.lazy(() => NestedEnumACCOUNT_VISIBILITYFilterSchema) ]).optional(),
}).strict();

export default EnumACCOUNT_VISIBILITYFilterSchema;
