// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventWhereUniqueInputSchema } from './EventWhereUniqueInputSchema';
import { EventUpdateWithoutOrganizationInputSchema } from './EventUpdateWithoutOrganizationInputSchema';
import { EventUncheckedUpdateWithoutOrganizationInputSchema } from './EventUncheckedUpdateWithoutOrganizationInputSchema';

export const EventUpdateWithWhereUniqueWithoutOrganizationInputSchema: z.ZodType<Prisma.EventUpdateWithWhereUniqueWithoutOrganizationInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => EventUpdateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedUpdateWithoutOrganizationInputSchema) ]),
}).strict();

export default EventUpdateWithWhereUniqueWithoutOrganizationInputSchema;
