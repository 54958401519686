// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { CollaborationOrderByWithRelationInputSchema } from './CollaborationOrderByWithRelationInputSchema';
import { RoleOrderByWithRelationInputSchema } from './RoleOrderByWithRelationInputSchema';
import { RolesOnCollaborationOrderByRelevanceInputSchema } from './RolesOnCollaborationOrderByRelevanceInputSchema';

export const RolesOnCollaborationOrderByWithRelationInputSchema: z.ZodType<Prisma.RolesOnCollaborationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  collaborationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  collaboration: z.lazy(() => CollaborationOrderByWithRelationInputSchema).optional(),
  role: z.lazy(() => RoleOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => RolesOnCollaborationOrderByRelevanceInputSchema).optional()
}).strict();

export default RolesOnCollaborationOrderByWithRelationInputSchema;
