// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateManyRoleInputSchema } from './RolesOnCollaborationCreateManyRoleInputSchema';

export const RolesOnCollaborationCreateManyRoleInputEnvelopeSchema: z.ZodType<Prisma.RolesOnCollaborationCreateManyRoleInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RolesOnCollaborationCreateManyRoleInputSchema),z.lazy(() => RolesOnCollaborationCreateManyRoleInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default RolesOnCollaborationCreateManyRoleInputEnvelopeSchema;
