// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { ReportedContentCountOrderByAggregateInputSchema } from './ReportedContentCountOrderByAggregateInputSchema';
import { ReportedContentAvgOrderByAggregateInputSchema } from './ReportedContentAvgOrderByAggregateInputSchema';
import { ReportedContentMaxOrderByAggregateInputSchema } from './ReportedContentMaxOrderByAggregateInputSchema';
import { ReportedContentMinOrderByAggregateInputSchema } from './ReportedContentMinOrderByAggregateInputSchema';
import { ReportedContentSumOrderByAggregateInputSchema } from './ReportedContentSumOrderByAggregateInputSchema';

export const ReportedContentOrderByWithAggregationInputSchema: z.ZodType<Prisma.ReportedContentOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  postId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  commentId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  wasChecked: z.lazy(() => SortOrderSchema).optional(),
  reportedById: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ReportedContentCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ReportedContentAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ReportedContentMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ReportedContentMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ReportedContentSumOrderByAggregateInputSchema).optional()
}).strict();

export default ReportedContentOrderByWithAggregationInputSchema;
