// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema } from './AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema';
import { AccountCreateNestedOneWithoutCollaborationsInputSchema } from './AccountCreateNestedOneWithoutCollaborationsInputSchema';
import { RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema';

export const CollaborationCreateInputSchema: z.ZodType<Prisma.CollaborationCreateInput> = z.object({
  id: z.string().optional(),
  showInSearch: z.boolean().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema),
  collaborator: z.lazy(() => AccountCreateNestedOneWithoutCollaborationsInputSchema),
  roles: z.lazy(() => RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema).optional()
}).strict();

export default CollaborationCreateInputSchema;
