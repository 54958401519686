// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensSelectSchema } from '../inputTypeSchemas/PushTokensSelectSchema';
import { PushTokensIncludeSchema } from '../inputTypeSchemas/PushTokensIncludeSchema';

export const PushTokensArgsSchema: z.ZodType<Prisma.PushTokensDefaultArgs> = z.object({
  select: z.lazy(() => PushTokensSelectSchema).optional(),
  include: z.lazy(() => PushTokensIncludeSchema).optional(),
}).strict();

export default PushTokensArgsSchema;
