// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const ColegioMedicoMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ColegioMedicoMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  icm: z.lazy(() => SortOrderSchema).optional(),
  names: z.lazy(() => SortOrderSchema).optional(),
  lastNames: z.lazy(() => SortOrderSchema).optional(),
  afiliation: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default ColegioMedicoMaxOrderByAggregateInputSchema;
