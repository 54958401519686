// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentSelectSchema } from '../inputTypeSchemas/ReportedContentSelectSchema';
import { ReportedContentIncludeSchema } from '../inputTypeSchemas/ReportedContentIncludeSchema';

export const ReportedContentArgsSchema: z.ZodType<Prisma.ReportedContentDefaultArgs> = z.object({
  select: z.lazy(() => ReportedContentSelectSchema).optional(),
  include: z.lazy(() => ReportedContentIncludeSchema).optional(),
}).strict();

export default ReportedContentArgsSchema;
