// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereInputSchema'
import { ColegioMedicoOrderByWithRelationInputSchema } from '../inputTypeSchemas/ColegioMedicoOrderByWithRelationInputSchema'
import { ColegioMedicoWhereUniqueInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereUniqueInputSchema'
import { ColegioMedicoScalarFieldEnumSchema } from '../inputTypeSchemas/ColegioMedicoScalarFieldEnumSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ColegioMedicoSelectSchema: z.ZodType<Prisma.ColegioMedicoSelect> = z.object({
  id: z.boolean().optional(),
  icm: z.boolean().optional(),
  names: z.boolean().optional(),
  lastNames: z.boolean().optional(),
  afiliation: z.boolean().optional(),
  city: z.boolean().optional(),
}).strict()

export const ColegioMedicoFindManyArgsSchema: z.ZodType<Prisma.ColegioMedicoFindManyArgs> = z.object({
  select: ColegioMedicoSelectSchema.optional(),
  where: ColegioMedicoWhereInputSchema.optional(),
  orderBy: z.union([ ColegioMedicoOrderByWithRelationInputSchema.array(),ColegioMedicoOrderByWithRelationInputSchema ]).optional(),
  cursor: ColegioMedicoWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ColegioMedicoScalarFieldEnumSchema,ColegioMedicoScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default ColegioMedicoFindManyArgsSchema;
