// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutSitesInputSchema } from './AccountUpdateWithoutSitesInputSchema';
import { AccountUncheckedUpdateWithoutSitesInputSchema } from './AccountUncheckedUpdateWithoutSitesInputSchema';

export const AccountUpdateToOneWithWhereWithoutSitesInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutSitesInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSitesInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutSitesInputSchema;
