// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithoutAccountInputSchema } from './RolesOnAccountUpdateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedUpdateWithoutAccountInputSchema } from './RolesOnAccountUncheckedUpdateWithoutAccountInputSchema';

export const RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RolesOnAccountUpdateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateWithoutAccountInputSchema) ]),
}).strict();

export default RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema;
