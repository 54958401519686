// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteWhereInputSchema } from './SiteWhereInputSchema';
import { EnumDomainActivationStatusFilterSchema } from './EnumDomainActivationStatusFilterSchema';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const SiteWhereUniqueInputSchema: z.ZodType<Prisma.SiteWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    domain: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    domain: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  domain: z.string().optional(),
  AND: z.union([ z.lazy(() => SiteWhereInputSchema),z.lazy(() => SiteWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SiteWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SiteWhereInputSchema),z.lazy(() => SiteWhereInputSchema).array() ]).optional(),
  status: z.union([ z.lazy(() => EnumDomainActivationStatusFilterSchema),z.lazy(() => DomainActivationStatusSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
}).strict());

export default SiteWhereUniqueInputSchema;
