// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { BoolFieldUpdateOperationsInputSchema } from './BoolFieldUpdateOperationsInputSchema';
import { RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationNestedInputSchema } from './RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationNestedInputSchema';

export const CollaborationUncheckedUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUncheckedUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  showInSearch: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  roles: z.lazy(() => RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationNestedInputSchema).optional()
}).strict();

export default CollaborationUncheckedUpdateWithoutCollaboratorInputSchema;
