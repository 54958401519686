// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutCollaborationsInputSchema } from './AccountUpdateWithoutCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutCollaborationsInputSchema';
import { AccountCreateWithoutCollaborationsInputSchema } from './AccountCreateWithoutCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutCollaborationsInputSchema } from './AccountUncheckedCreateWithoutCollaborationsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutCollaborationsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutCollaborationsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutCollaborationsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutCollaborationsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutCollaborationsInputSchema;
