// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const PushTokensCreateWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  pushToken: z.string(),
  created: z.coerce.date().optional(),
  updated: z.coerce.date().optional()
}).strict();

export default PushTokensCreateWithoutAccountInputSchema;
