// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithoutCollaborationInputSchema } from './RolesOnCollaborationUpdateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema';
import { RolesOnCollaborationCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema';

export const RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema) ]),
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema;
