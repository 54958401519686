// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountCreateWithoutRoleInputSchema } from './RolesOnAccountCreateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedCreateWithoutRoleInputSchema } from './RolesOnAccountUncheckedCreateWithoutRoleInputSchema';

export const RolesOnAccountCreateOrConnectWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountCreateOrConnectWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnAccountCreateOrConnectWithoutRoleInputSchema;
