// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensWhereInputSchema } from '../inputTypeSchemas/PushTokensWhereInputSchema'

export const PushTokensDeleteManyArgsSchema: z.ZodType<Prisma.PushTokensDeleteManyArgs> = z.object({
  where: PushTokensWhereInputSchema.optional(),
}).strict() ;

export default PushTokensDeleteManyArgsSchema;
