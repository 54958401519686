// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithoutCollaboratorInputSchema } from './CollaborationUpdateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedUpdateWithoutCollaboratorInputSchema } from './CollaborationUncheckedUpdateWithoutCollaboratorInputSchema';

export const CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUpdateWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CollaborationUpdateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutCollaboratorInputSchema) ]),
}).strict();

export default CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema;
