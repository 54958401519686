// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabWhereInputSchema } from '../inputTypeSchemas/ExamLabWhereInputSchema'
import { ExamLabOrderByWithRelationInputSchema } from '../inputTypeSchemas/ExamLabOrderByWithRelationInputSchema'
import { ExamLabWhereUniqueInputSchema } from '../inputTypeSchemas/ExamLabWhereUniqueInputSchema'
import { ExamLabScalarFieldEnumSchema } from '../inputTypeSchemas/ExamLabScalarFieldEnumSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ExamLabSelectSchema: z.ZodType<Prisma.ExamLabSelect> = z.object({
  id: z.boolean().optional(),
  name_es: z.boolean().optional(),
  category: z.boolean().optional(),
}).strict()

export const ExamLabFindFirstArgsSchema: z.ZodType<Prisma.ExamLabFindFirstArgs> = z.object({
  select: ExamLabSelectSchema.optional(),
  where: ExamLabWhereInputSchema.optional(),
  orderBy: z.union([ ExamLabOrderByWithRelationInputSchema.array(),ExamLabOrderByWithRelationInputSchema ]).optional(),
  cursor: ExamLabWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ExamLabScalarFieldEnumSchema,ExamLabScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default ExamLabFindFirstArgsSchema;
