// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateWithoutRoleInputSchema } from './RolesOnCollaborationCreateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema';
import { RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema } from './RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema';
import { RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema } from './RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema';
import { RolesOnCollaborationCreateManyRoleInputEnvelopeSchema } from './RolesOnCollaborationCreateManyRoleInputEnvelopeSchema';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema } from './RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema';
import { RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema } from './RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema';
import { RolesOnCollaborationScalarWhereInputSchema } from './RolesOnCollaborationScalarWhereInputSchema';

export const RolesOnCollaborationUncheckedUpdateManyWithoutRoleNestedInputSchema: z.ZodType<Prisma.RolesOnCollaborationUncheckedUpdateManyWithoutRoleNestedInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema).array(),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnCollaborationCreateManyRoleInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RolesOnCollaborationScalarWhereInputSchema),z.lazy(() => RolesOnCollaborationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default RolesOnCollaborationUncheckedUpdateManyWithoutRoleNestedInputSchema;
