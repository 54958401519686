// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutEventsInputSchema } from './AccountUpdateWithoutEventsInputSchema';
import { AccountUncheckedUpdateWithoutEventsInputSchema } from './AccountUncheckedUpdateWithoutEventsInputSchema';
import { AccountCreateWithoutEventsInputSchema } from './AccountCreateWithoutEventsInputSchema';
import { AccountUncheckedCreateWithoutEventsInputSchema } from './AccountUncheckedCreateWithoutEventsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutEventsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutEventsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutEventsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutEventsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutEventsInputSchema;
