// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationUpdateWithoutInviteeInputSchema } from './CollaborationInvitationUpdateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema';
import { CollaborationInvitationCreateWithoutInviteeInputSchema } from './CollaborationInvitationCreateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema';

export const CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInput> = z.object({
  where: z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CollaborationInvitationUpdateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema) ]),
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema) ]),
}).strict();

export default CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema;
