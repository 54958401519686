// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteWhereInputSchema } from './SiteWhereInputSchema';

export const SiteListRelationFilterSchema: z.ZodType<Prisma.SiteListRelationFilter> = z.object({
  every: z.lazy(() => SiteWhereInputSchema).optional(),
  some: z.lazy(() => SiteWhereInputSchema).optional(),
  none: z.lazy(() => SiteWhereInputSchema).optional()
}).strict();

export default SiteListRelationFilterSchema;
