// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithoutAccountInputSchema } from './CollaborationUpdateWithoutAccountInputSchema';
import { CollaborationUncheckedUpdateWithoutAccountInputSchema } from './CollaborationUncheckedUpdateWithoutAccountInputSchema';

export const CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationUpdateWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CollaborationUpdateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema;
