// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateManyAccountInputSchema } from './CollaborationCreateManyAccountInputSchema';

export const CollaborationCreateManyAccountInputEnvelopeSchema: z.ZodType<Prisma.CollaborationCreateManyAccountInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CollaborationCreateManyAccountInputSchema),z.lazy(() => CollaborationCreateManyAccountInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default CollaborationCreateManyAccountInputEnvelopeSchema;
