// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteCreateManyAccountInputSchema } from './SiteCreateManyAccountInputSchema';

export const SiteCreateManyAccountInputEnvelopeSchema: z.ZodType<Prisma.SiteCreateManyAccountInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SiteCreateManyAccountInputSchema),z.lazy(() => SiteCreateManyAccountInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default SiteCreateManyAccountInputEnvelopeSchema;
