// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationCreateManyInputSchema } from '../inputTypeSchemas/CollaborationInvitationCreateManyInputSchema'

export const CollaborationInvitationCreateManyArgsSchema: z.ZodType<Prisma.CollaborationInvitationCreateManyArgs> = z.object({
  data: z.union([ CollaborationInvitationCreateManyInputSchema,CollaborationInvitationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default CollaborationInvitationCreateManyArgsSchema;
