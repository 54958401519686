// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationCreateWithoutAccountInputSchema } from './CollaborationInvitationCreateWithoutAccountInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutAccountInputSchema } from './CollaborationInvitationUncheckedCreateWithoutAccountInputSchema';
import { CollaborationInvitationCreateOrConnectWithoutAccountInputSchema } from './CollaborationInvitationCreateOrConnectWithoutAccountInputSchema';
import { CollaborationInvitationUpsertWithWhereUniqueWithoutAccountInputSchema } from './CollaborationInvitationUpsertWithWhereUniqueWithoutAccountInputSchema';
import { CollaborationInvitationCreateManyAccountInputEnvelopeSchema } from './CollaborationInvitationCreateManyAccountInputEnvelopeSchema';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema } from './CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema';
import { CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema } from './CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema';
import { CollaborationInvitationScalarWhereInputSchema } from './CollaborationInvitationScalarWhereInputSchema';

export const CollaborationInvitationUpdateManyWithoutAccountNestedInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateManyWithoutAccountNestedInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationCreateWithoutAccountInputSchema).array(),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationInvitationCreateOrConnectWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CollaborationInvitationUpsertWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUpsertWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationInvitationCreateManyAccountInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CollaborationInvitationScalarWhereInputSchema),z.lazy(() => CollaborationInvitationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default CollaborationInvitationUpdateManyWithoutAccountNestedInputSchema;
