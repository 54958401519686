// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"

export const ReportedContentIncludeSchema: z.ZodType<Prisma.ReportedContentInclude> = z.object({
  reportedBy: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export default ReportedContentIncludeSchema;
