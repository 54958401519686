// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateWithoutAccountsInputSchema } from './RoleCreateWithoutAccountsInputSchema';
import { RoleUncheckedCreateWithoutAccountsInputSchema } from './RoleUncheckedCreateWithoutAccountsInputSchema';
import { RoleCreateOrConnectWithoutAccountsInputSchema } from './RoleCreateOrConnectWithoutAccountsInputSchema';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';

export const RoleCreateNestedOneWithoutAccountsInputSchema: z.ZodType<Prisma.RoleCreateNestedOneWithoutAccountsInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional()
}).strict();

export default RoleCreateNestedOneWithoutAccountsInputSchema;
