// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema } from './CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { BoolFilterSchema } from './BoolFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { RolesOnCollaborationListRelationFilterSchema } from './RolesOnCollaborationListRelationFilterSchema';

export const CollaborationWhereUniqueInputSchema: z.ZodType<Prisma.CollaborationWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    accountId_collaboratorId: z.lazy(() => CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema)
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    accountId_collaboratorId: z.lazy(() => CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema),
  }),
])
.and(z.object({
  id: z.string().optional(),
  accountId_collaboratorId: z.lazy(() => CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => CollaborationWhereInputSchema),z.lazy(() => CollaborationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationWhereInputSchema),z.lazy(() => CollaborationWhereInputSchema).array() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  showInSearch: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  roles: z.lazy(() => RolesOnCollaborationListRelationFilterSchema).optional()
}).strict());

export default CollaborationWhereUniqueInputSchema;
