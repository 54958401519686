// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesUpdateManyMutationInputSchema } from '../inputTypeSchemas/ExamImagesUpdateManyMutationInputSchema'
import { ExamImagesUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/ExamImagesUncheckedUpdateManyInputSchema'
import { ExamImagesWhereInputSchema } from '../inputTypeSchemas/ExamImagesWhereInputSchema'

export const ExamImagesUpdateManyArgsSchema: z.ZodType<Prisma.ExamImagesUpdateManyArgs> = z.object({
  data: z.union([ ExamImagesUpdateManyMutationInputSchema,ExamImagesUncheckedUpdateManyInputSchema ]),
  where: ExamImagesWhereInputSchema.optional(),
}).strict() ;

export default ExamImagesUpdateManyArgsSchema;
