// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountIncludeSchema } from '../inputTypeSchemas/AccountIncludeSchema'
import { AccountWhereInputSchema } from '../inputTypeSchemas/AccountWhereInputSchema'
import { AccountOrderByWithRelationInputSchema } from '../inputTypeSchemas/AccountOrderByWithRelationInputSchema'
import { AccountWhereUniqueInputSchema } from '../inputTypeSchemas/AccountWhereUniqueInputSchema'
import { AccountScalarFieldEnumSchema } from '../inputTypeSchemas/AccountScalarFieldEnumSchema'
import { RolesOnAccountFindManyArgsSchema } from "../outputTypeSchemas/RolesOnAccountFindManyArgsSchema"
import { CollaborationFindManyArgsSchema } from "../outputTypeSchemas/CollaborationFindManyArgsSchema"
import { CollaborationInvitationFindManyArgsSchema } from "../outputTypeSchemas/CollaborationInvitationFindManyArgsSchema"
import { EventFindManyArgsSchema } from "../outputTypeSchemas/EventFindManyArgsSchema"
import { SiteFindManyArgsSchema } from "../outputTypeSchemas/SiteFindManyArgsSchema"
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { PushTokensFindManyArgsSchema } from "../outputTypeSchemas/PushTokensFindManyArgsSchema"
import { ReportedContentFindManyArgsSchema } from "../outputTypeSchemas/ReportedContentFindManyArgsSchema"
import { AccountCountOutputTypeArgsSchema } from "../outputTypeSchemas/AccountCountOutputTypeArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const AccountSelectSchema: z.ZodType<Prisma.AccountSelect> = z.object({
  id: z.boolean().optional(),
  firebaseId: z.boolean().optional(),
  wordpressId: z.boolean().optional(),
  wooCommerceTermId: z.boolean().optional(),
  stripeCustomerId: z.boolean().optional(),
  slug: z.boolean().optional(),
  email: z.boolean().optional(),
  visibility: z.boolean().optional(),
  type: z.boolean().optional(),
  current_company_name: z.boolean().optional(),
  current_position_name: z.boolean().optional(),
  description: z.boolean().optional(),
  plan: z.boolean().optional(),
  language: z.boolean().optional(),
  logo: z.boolean().optional(),
  profilePicture: z.boolean().optional(),
  backgroundImage: z.boolean().optional(),
  timeZone: z.boolean().optional(),
  quickBio: z.boolean().optional(),
  about: z.boolean().optional(),
  displayName: z.boolean().optional(),
  title: z.boolean().optional(),
  birthDate: z.boolean().optional(),
  prescriptionLogo: z.boolean().optional(),
  prescriptionSignature: z.boolean().optional(),
  medicalSchoolNumber: z.boolean().optional(),
  branches: z.boolean().optional(),
  workers: z.boolean().optional(),
  mainService: z.boolean().optional(),
  otherServices: z.boolean().optional(),
  color: z.boolean().optional(),
  website: z.boolean().optional(),
  categories: z.boolean().optional(),
  categoriesSlugs: z.boolean().optional(),
  defaultProductId: z.boolean().optional(),
  rating: z.boolean().optional(),
  firstName: z.boolean().optional(),
  lastName: z.boolean().optional(),
  sex: z.boolean().optional(),
  mainMenuId: z.boolean().optional(),
  landingPageId: z.boolean().optional(),
  isHighlightedAccount: z.boolean().optional(),
  postal_code: z.boolean().optional(),
  country: z.boolean().optional(),
  city: z.boolean().optional(),
  address1: z.boolean().optional(),
  address2: z.boolean().optional(),
  region: z.boolean().optional(),
  phoneNumber: z.boolean().optional(),
  nationalIdentityNumber: z.boolean().optional(),
  marketingConsent: z.boolean().optional(),
  termsConsent: z.boolean().optional(),
  ownerId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  roles: z.union([z.boolean(),z.lazy(() => RolesOnAccountFindManyArgsSchema)]).optional(),
  initiatedCollaborations: z.union([z.boolean(),z.lazy(() => CollaborationFindManyArgsSchema)]).optional(),
  collaborations: z.union([z.boolean(),z.lazy(() => CollaborationFindManyArgsSchema)]).optional(),
  receivedCollaborationInvitations: z.union([z.boolean(),z.lazy(() => CollaborationInvitationFindManyArgsSchema)]).optional(),
  sentCollaborationInvitations: z.union([z.boolean(),z.lazy(() => CollaborationInvitationFindManyArgsSchema)]).optional(),
  events: z.union([z.boolean(),z.lazy(() => EventFindManyArgsSchema)]).optional(),
  sites: z.union([z.boolean(),z.lazy(() => SiteFindManyArgsSchema)]).optional(),
  ownedAccounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  owner: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  pushTokens: z.union([z.boolean(),z.lazy(() => PushTokensFindManyArgsSchema)]).optional(),
  reported: z.union([z.boolean(),z.lazy(() => ReportedContentFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => AccountCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const AccountFindManyArgsSchema: z.ZodType<Prisma.AccountFindManyArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default AccountFindManyArgsSchema;
