// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensWhereInputSchema } from '../inputTypeSchemas/PushTokensWhereInputSchema'
import { PushTokensOrderByWithRelationInputSchema } from '../inputTypeSchemas/PushTokensOrderByWithRelationInputSchema'
import { PushTokensWhereUniqueInputSchema } from '../inputTypeSchemas/PushTokensWhereUniqueInputSchema'

export const PushTokensAggregateArgsSchema: z.ZodType<Prisma.PushTokensAggregateArgs> = z.object({
  where: PushTokensWhereInputSchema.optional(),
  orderBy: z.union([ PushTokensOrderByWithRelationInputSchema.array(),PushTokensOrderByWithRelationInputSchema ]).optional(),
  cursor: PushTokensWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default PushTokensAggregateArgsSchema;
