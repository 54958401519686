// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateNestedOneWithoutAccountsInputSchema } from './RoleCreateNestedOneWithoutAccountsInputSchema';

export const RolesOnAccountCreateWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  role: z.lazy(() => RoleCreateNestedOneWithoutAccountsInputSchema)
}).strict();

export default RolesOnAccountCreateWithoutAccountInputSchema;
