// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteCreateWithoutAccountInputSchema } from './SiteCreateWithoutAccountInputSchema';
import { SiteUncheckedCreateWithoutAccountInputSchema } from './SiteUncheckedCreateWithoutAccountInputSchema';
import { SiteCreateOrConnectWithoutAccountInputSchema } from './SiteCreateOrConnectWithoutAccountInputSchema';
import { SiteCreateManyAccountInputEnvelopeSchema } from './SiteCreateManyAccountInputEnvelopeSchema';
import { SiteWhereUniqueInputSchema } from './SiteWhereUniqueInputSchema';

export const SiteCreateNestedManyWithoutAccountInputSchema: z.ZodType<Prisma.SiteCreateNestedManyWithoutAccountInput> = z.object({
  create: z.union([ z.lazy(() => SiteCreateWithoutAccountInputSchema),z.lazy(() => SiteCreateWithoutAccountInputSchema).array(),z.lazy(() => SiteUncheckedCreateWithoutAccountInputSchema),z.lazy(() => SiteUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SiteCreateOrConnectWithoutAccountInputSchema),z.lazy(() => SiteCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SiteCreateManyAccountInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SiteWhereUniqueInputSchema),z.lazy(() => SiteWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default SiteCreateNestedManyWithoutAccountInputSchema;
