// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';

export const SiteCreateWithoutAccountInputSchema: z.ZodType<Prisma.SiteCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  domain: z.string(),
  status: z.lazy(() => DomainActivationStatusSchema).optional(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional()
}).strict();

export default SiteCreateWithoutAccountInputSchema;
