// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutRolesInputSchema } from './CollaborationCreateWithoutRolesInputSchema';
import { CollaborationUncheckedCreateWithoutRolesInputSchema } from './CollaborationUncheckedCreateWithoutRolesInputSchema';
import { CollaborationCreateOrConnectWithoutRolesInputSchema } from './CollaborationCreateOrConnectWithoutRolesInputSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';

export const CollaborationCreateNestedOneWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationCreateNestedOneWithoutRolesInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutRolesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CollaborationCreateOrConnectWithoutRolesInputSchema).optional(),
  connect: z.lazy(() => CollaborationWhereUniqueInputSchema).optional()
}).strict();

export default CollaborationCreateNestedOneWithoutRolesInputSchema;
