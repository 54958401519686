// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { RolesOnAccountCountOrderByAggregateInputSchema } from './RolesOnAccountCountOrderByAggregateInputSchema';
import { RolesOnAccountMaxOrderByAggregateInputSchema } from './RolesOnAccountMaxOrderByAggregateInputSchema';
import { RolesOnAccountMinOrderByAggregateInputSchema } from './RolesOnAccountMinOrderByAggregateInputSchema';

export const RolesOnAccountOrderByWithAggregationInputSchema: z.ZodType<Prisma.RolesOnAccountOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => RolesOnAccountCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => RolesOnAccountMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => RolesOnAccountMinOrderByAggregateInputSchema).optional()
}).strict();

export default RolesOnAccountOrderByWithAggregationInputSchema;
