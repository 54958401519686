// @ts-nocheck
import { z } from 'zod';
import { DomainActivationStatusSchema } from '../inputTypeSchemas/DomainActivationStatusSchema'

/////////////////////////////////////////
// SITE SCHEMA
/////////////////////////////////////////

export const SiteSchema = z.object({
  status: DomainActivationStatusSchema,
  id: z.string(),
  domain: z.string(),
  accountId: z.string(),
  created: z.coerce.date(),
  deleted: z.coerce.date().nullable(),
  updated: z.coerce.date(),
})

export type Site = z.infer<typeof SiteSchema>

export default SiteSchema;
