// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutReportedInputSchema } from './AccountCreateWithoutReportedInputSchema';
import { AccountUncheckedCreateWithoutReportedInputSchema } from './AccountUncheckedCreateWithoutReportedInputSchema';
import { AccountCreateOrConnectWithoutReportedInputSchema } from './AccountCreateOrConnectWithoutReportedInputSchema';
import { AccountUpsertWithoutReportedInputSchema } from './AccountUpsertWithoutReportedInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutReportedInputSchema } from './AccountUpdateToOneWithWhereWithoutReportedInputSchema';
import { AccountUpdateWithoutReportedInputSchema } from './AccountUpdateWithoutReportedInputSchema';
import { AccountUncheckedUpdateWithoutReportedInputSchema } from './AccountUncheckedUpdateWithoutReportedInputSchema';

export const AccountUpdateOneRequiredWithoutReportedNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutReportedNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReportedInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutReportedInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutReportedInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutReportedInputSchema),z.lazy(() => AccountUpdateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutReportedInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutReportedNestedInputSchema;
