// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteWhereUniqueInputSchema } from './SiteWhereUniqueInputSchema';
import { SiteUpdateWithoutAccountInputSchema } from './SiteUpdateWithoutAccountInputSchema';
import { SiteUncheckedUpdateWithoutAccountInputSchema } from './SiteUncheckedUpdateWithoutAccountInputSchema';
import { SiteCreateWithoutAccountInputSchema } from './SiteCreateWithoutAccountInputSchema';
import { SiteUncheckedCreateWithoutAccountInputSchema } from './SiteUncheckedCreateWithoutAccountInputSchema';

export const SiteUpsertWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.SiteUpsertWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => SiteWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SiteUpdateWithoutAccountInputSchema),z.lazy(() => SiteUncheckedUpdateWithoutAccountInputSchema) ]),
  create: z.union([ z.lazy(() => SiteCreateWithoutAccountInputSchema),z.lazy(() => SiteUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default SiteUpsertWithWhereUniqueWithoutAccountInputSchema;
