// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteWhereUniqueInputSchema } from './SiteWhereUniqueInputSchema';
import { SiteCreateWithoutAccountInputSchema } from './SiteCreateWithoutAccountInputSchema';
import { SiteUncheckedCreateWithoutAccountInputSchema } from './SiteUncheckedCreateWithoutAccountInputSchema';

export const SiteCreateOrConnectWithoutAccountInputSchema: z.ZodType<Prisma.SiteCreateOrConnectWithoutAccountInput> = z.object({
  where: z.lazy(() => SiteWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SiteCreateWithoutAccountInputSchema),z.lazy(() => SiteUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default SiteCreateOrConnectWithoutAccountInputSchema;
