// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { AccountOrderByWithRelationInputSchema } from './AccountOrderByWithRelationInputSchema';
import { SiteOrderByRelevanceInputSchema } from './SiteOrderByRelevanceInputSchema';

export const SiteOrderByWithRelationInputSchema: z.ZodType<Prisma.SiteOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  domain: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  account: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => SiteOrderByRelevanceInputSchema).optional()
}).strict();

export default SiteOrderByWithRelationInputSchema;
