// @ts-nocheck
import { z } from 'zod';
import { INVITATION_STATUSSchema } from '../inputTypeSchemas/INVITATION_STATUSSchema'

/////////////////////////////////////////
// COLLABORATION INVITATION SCHEMA
/////////////////////////////////////////

export const CollaborationInvitationSchema = z.object({
  status: INVITATION_STATUSSchema,
  id: z.string(),
  inviteeId: z.string().nullable(),
  inviteeEmail: z.string(),
  accountId: z.string(),
  created: z.coerce.date(),
  deleted: z.coerce.date().nullable(),
  updated: z.coerce.date(),
})

export type CollaborationInvitation = z.infer<typeof CollaborationInvitationSchema>

export default CollaborationInvitationSchema;
