// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensWhereInputSchema } from '../inputTypeSchemas/PushTokensWhereInputSchema'
import { PushTokensOrderByWithAggregationInputSchema } from '../inputTypeSchemas/PushTokensOrderByWithAggregationInputSchema'
import { PushTokensScalarFieldEnumSchema } from '../inputTypeSchemas/PushTokensScalarFieldEnumSchema'
import { PushTokensScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/PushTokensScalarWhereWithAggregatesInputSchema'

export const PushTokensGroupByArgsSchema: z.ZodType<Prisma.PushTokensGroupByArgs> = z.object({
  where: PushTokensWhereInputSchema.optional(),
  orderBy: z.union([ PushTokensOrderByWithAggregationInputSchema.array(),PushTokensOrderByWithAggregationInputSchema ]).optional(),
  by: PushTokensScalarFieldEnumSchema.array(),
  having: PushTokensScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default PushTokensGroupByArgsSchema;
