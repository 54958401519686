// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema';

export const CollaborationUncheckedCreateWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationUncheckedCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  showInSearch: z.boolean().optional(),
  collaboratorId: z.string(),
  roles: z.lazy(() => RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema).optional()
}).strict();

export default CollaborationUncheckedCreateWithoutAccountInputSchema;
