// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensWhereUniqueInputSchema } from './PushTokensWhereUniqueInputSchema';
import { PushTokensUpdateWithoutAccountInputSchema } from './PushTokensUpdateWithoutAccountInputSchema';
import { PushTokensUncheckedUpdateWithoutAccountInputSchema } from './PushTokensUncheckedUpdateWithoutAccountInputSchema';
import { PushTokensCreateWithoutAccountInputSchema } from './PushTokensCreateWithoutAccountInputSchema';
import { PushTokensUncheckedCreateWithoutAccountInputSchema } from './PushTokensUncheckedCreateWithoutAccountInputSchema';

export const PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensUpsertWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => PushTokensWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PushTokensUpdateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedUpdateWithoutAccountInputSchema) ]),
  create: z.union([ z.lazy(() => PushTokensCreateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema;
