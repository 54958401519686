// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { AccountUpdateOneRequiredWithoutRolesNestedInputSchema } from './AccountUpdateOneRequiredWithoutRolesNestedInputSchema';
import { RoleUpdateOneRequiredWithoutAccountsNestedInputSchema } from './RoleUpdateOneRequiredWithoutAccountsNestedInputSchema';

export const RolesOnAccountUpdateInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  account: z.lazy(() => AccountUpdateOneRequiredWithoutRolesNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneRequiredWithoutAccountsNestedInputSchema).optional()
}).strict();

export default RolesOnAccountUpdateInputSchema;
