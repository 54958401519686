// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutRolesInputSchema } from './AccountCreateNestedOneWithoutRolesInputSchema';

export const RolesOnAccountCreateWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountCreateWithoutRoleInput> = z.object({
  id: z.string().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutRolesInputSchema)
}).strict();

export default RolesOnAccountCreateWithoutRoleInputSchema;
