// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutOwnedAccountsInputSchema } from './AccountCreateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedCreateWithoutOwnedAccountsInputSchema } from './AccountUncheckedCreateWithoutOwnedAccountsInputSchema';

export const AccountCreateOrConnectWithoutOwnedAccountsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutOwnedAccountsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnedAccountsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutOwnedAccountsInputSchema;
