// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutCollaborationsInputSchema } from './AccountCreateWithoutCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutCollaborationsInputSchema } from './AccountUncheckedCreateWithoutCollaborationsInputSchema';

export const AccountCreateOrConnectWithoutCollaborationsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutCollaborationsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutCollaborationsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutCollaborationsInputSchema;
