// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { IntWithAggregatesFilterSchema } from './IntWithAggregatesFilterSchema';
import { JsonWithAggregatesFilterSchema } from './JsonWithAggregatesFilterSchema';
import { StringNullableWithAggregatesFilterSchema } from './StringNullableWithAggregatesFilterSchema';

export const ProductExamsScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ProductExamsScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ProductExamsScalarWhereWithAggregatesInputSchema),z.lazy(() => ProductExamsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductExamsScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductExamsScalarWhereWithAggregatesInputSchema),z.lazy(() => ProductExamsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  wooCommerceProductId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  images: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  labs: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  doctorAccountId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export default ProductExamsScalarWhereWithAggregatesInputSchema;
