// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// COLEGIO MEDICO SCHEMA
/////////////////////////////////////////

export const ColegioMedicoSchema = z.object({
  id: z.string(),
  icm: z.string(),
  names: z.string(),
  lastNames: z.string(),
  afiliation: z.string(),
  city: z.string(),
})

export type ColegioMedico = z.infer<typeof ColegioMedicoSchema>

export default ColegioMedicoSchema;
