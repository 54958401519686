// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutCollaboratorInputSchema } from './CollaborationCreateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedCreateWithoutCollaboratorInputSchema } from './CollaborationUncheckedCreateWithoutCollaboratorInputSchema';
import { CollaborationCreateOrConnectWithoutCollaboratorInputSchema } from './CollaborationCreateOrConnectWithoutCollaboratorInputSchema';
import { CollaborationCreateManyCollaboratorInputEnvelopeSchema } from './CollaborationCreateManyCollaboratorInputEnvelopeSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';

export const CollaborationCreateNestedManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationCreateNestedManyWithoutCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CollaborationCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationCreateManyCollaboratorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default CollaborationCreateNestedManyWithoutCollaboratorInputSchema;
