// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutRolesInputSchema } from './AccountCreateWithoutRolesInputSchema';
import { AccountUncheckedCreateWithoutRolesInputSchema } from './AccountUncheckedCreateWithoutRolesInputSchema';

export const AccountCreateOrConnectWithoutRolesInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutRolesInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutRolesInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutRolesInputSchema;
