// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationScalarWhereInputSchema } from './CollaborationInvitationScalarWhereInputSchema';
import { CollaborationInvitationUpdateManyMutationInputSchema } from './CollaborationInvitationUpdateManyMutationInputSchema';
import { CollaborationInvitationUncheckedUpdateManyWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedUpdateManyWithoutInviteeInputSchema';

export const CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateManyWithWhereWithoutInviteeInput> = z.object({
  where: z.lazy(() => CollaborationInvitationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CollaborationInvitationUpdateManyMutationInputSchema),z.lazy(() => CollaborationInvitationUncheckedUpdateManyWithoutInviteeInputSchema) ]),
}).strict();

export default CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema;
