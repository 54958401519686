// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema } from './CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema';

export const RolesOnCollaborationUpdateWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateWithoutRoleInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboration: z.lazy(() => CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema).optional()
}).strict();

export default RolesOnCollaborationUpdateWithoutRoleInputSchema;
