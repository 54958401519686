// @ts-nocheck
import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'

/////////////////////////////////////////
// PRODUCT EXAMS SCHEMA
/////////////////////////////////////////

export const ProductExamsSchema = z.object({
  id: z.string(),
  wooCommerceProductId: z.number().int(),
  images: JsonValueSchema,
  labs: JsonValueSchema,
  accountId: z.string(),
  doctorAccountId: z.string().nullable(),
})

export type ProductExams = z.infer<typeof ProductExamsSchema>

export default ProductExamsSchema;
