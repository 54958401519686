// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensIncludeSchema } from '../inputTypeSchemas/PushTokensIncludeSchema'
import { PushTokensWhereInputSchema } from '../inputTypeSchemas/PushTokensWhereInputSchema'
import { PushTokensOrderByWithRelationInputSchema } from '../inputTypeSchemas/PushTokensOrderByWithRelationInputSchema'
import { PushTokensWhereUniqueInputSchema } from '../inputTypeSchemas/PushTokensWhereUniqueInputSchema'
import { PushTokensScalarFieldEnumSchema } from '../inputTypeSchemas/PushTokensScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const PushTokensSelectSchema: z.ZodType<Prisma.PushTokensSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  pushToken: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const PushTokensFindFirstArgsSchema: z.ZodType<Prisma.PushTokensFindFirstArgs> = z.object({
  select: PushTokensSelectSchema.optional(),
  include: PushTokensIncludeSchema.optional(),
  where: PushTokensWhereInputSchema.optional(),
  orderBy: z.union([ PushTokensOrderByWithRelationInputSchema.array(),PushTokensOrderByWithRelationInputSchema ]).optional(),
  cursor: PushTokensWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PushTokensScalarFieldEnumSchema,PushTokensScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default PushTokensFindFirstArgsSchema;
