// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpWhereUniqueInputSchema } from '../inputTypeSchemas/OtpWhereUniqueInputSchema'
import { OtpCreateInputSchema } from '../inputTypeSchemas/OtpCreateInputSchema'
import { OtpUncheckedCreateInputSchema } from '../inputTypeSchemas/OtpUncheckedCreateInputSchema'
import { OtpUpdateInputSchema } from '../inputTypeSchemas/OtpUpdateInputSchema'
import { OtpUncheckedUpdateInputSchema } from '../inputTypeSchemas/OtpUncheckedUpdateInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const OtpSelectSchema: z.ZodType<Prisma.OtpSelect> = z.object({
  id: z.boolean().optional(),
  email: z.boolean().optional(),
  password: z.boolean().optional(),
  exp: z.boolean().optional(),
}).strict()

export const OtpUpsertArgsSchema: z.ZodType<Prisma.OtpUpsertArgs> = z.object({
  select: OtpSelectSchema.optional(),
  where: OtpWhereUniqueInputSchema,
  create: z.union([ OtpCreateInputSchema,OtpUncheckedCreateInputSchema ]),
  update: z.union([ OtpUpdateInputSchema,OtpUncheckedUpdateInputSchema ]),
}).strict() ;

export default OtpUpsertArgsSchema;
