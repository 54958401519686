// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpWhereInputSchema } from '../inputTypeSchemas/OtpWhereInputSchema'

export const OtpDeleteManyArgsSchema: z.ZodType<Prisma.OtpDeleteManyArgs> = z.object({
  where: OtpWhereInputSchema.optional(),
}).strict() ;

export default OtpDeleteManyArgsSchema;
