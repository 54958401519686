// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { NestedIntFilterSchema } from './NestedIntFilterSchema';

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export default IntFilterSchema;
