// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutReportedInputSchema } from './AccountUpdateWithoutReportedInputSchema';
import { AccountUncheckedUpdateWithoutReportedInputSchema } from './AccountUncheckedUpdateWithoutReportedInputSchema';
import { AccountCreateWithoutReportedInputSchema } from './AccountCreateWithoutReportedInputSchema';
import { AccountUncheckedCreateWithoutReportedInputSchema } from './AccountUncheckedCreateWithoutReportedInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutReportedInputSchema: z.ZodType<Prisma.AccountUpsertWithoutReportedInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutReportedInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReportedInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutReportedInputSchema;
