// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { PushTokensCountOrderByAggregateInputSchema } from './PushTokensCountOrderByAggregateInputSchema';
import { PushTokensMaxOrderByAggregateInputSchema } from './PushTokensMaxOrderByAggregateInputSchema';
import { PushTokensMinOrderByAggregateInputSchema } from './PushTokensMinOrderByAggregateInputSchema';

export const PushTokensOrderByWithAggregationInputSchema: z.ZodType<Prisma.PushTokensOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  pushToken: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PushTokensCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PushTokensMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PushTokensMinOrderByAggregateInputSchema).optional()
}).strict();

export default PushTokensOrderByWithAggregationInputSchema;
