// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountOrderByRelevanceFieldEnumSchema } from './RolesOnAccountOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const RolesOnAccountOrderByRelevanceInputSchema: z.ZodType<Prisma.RolesOnAccountOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => RolesOnAccountOrderByRelevanceFieldEnumSchema),z.lazy(() => RolesOnAccountOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default RolesOnAccountOrderByRelevanceInputSchema;
