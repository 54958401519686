// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ColegioMedicoOrderByRelevanceFieldEnumSchema } from './ColegioMedicoOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const ColegioMedicoOrderByRelevanceInputSchema: z.ZodType<Prisma.ColegioMedicoOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ColegioMedicoOrderByRelevanceFieldEnumSchema),z.lazy(() => ColegioMedicoOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default ColegioMedicoOrderByRelevanceInputSchema;
