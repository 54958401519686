// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { DateTimeWithAggregatesFilterSchema } from './DateTimeWithAggregatesFilterSchema';

export const PushTokensScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PushTokensScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PushTokensScalarWhereWithAggregatesInputSchema),z.lazy(() => PushTokensScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PushTokensScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PushTokensScalarWhereWithAggregatesInputSchema),z.lazy(() => PushTokensScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  pushToken: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updated: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default PushTokensScalarWhereWithAggregatesInputSchema;
