// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsWhereInputSchema } from '../inputTypeSchemas/ProductExamsWhereInputSchema'

export const ProductExamsDeleteManyArgsSchema: z.ZodType<Prisma.ProductExamsDeleteManyArgs> = z.object({
  where: ProductExamsWhereInputSchema.optional(),
}).strict() ;

export default ProductExamsDeleteManyArgsSchema;
