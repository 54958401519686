// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutSitesInputSchema } from './AccountUpdateWithoutSitesInputSchema';
import { AccountUncheckedUpdateWithoutSitesInputSchema } from './AccountUncheckedUpdateWithoutSitesInputSchema';
import { AccountCreateWithoutSitesInputSchema } from './AccountCreateWithoutSitesInputSchema';
import { AccountUncheckedCreateWithoutSitesInputSchema } from './AccountUncheckedCreateWithoutSitesInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutSitesInputSchema: z.ZodType<Prisma.AccountUpsertWithoutSitesInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSitesInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSitesInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutSitesInputSchema;
