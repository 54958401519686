// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateManyAccountInputSchema } from './RolesOnAccountCreateManyAccountInputSchema';

export const RolesOnAccountCreateManyAccountInputEnvelopeSchema: z.ZodType<Prisma.RolesOnAccountCreateManyAccountInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RolesOnAccountCreateManyAccountInputSchema),z.lazy(() => RolesOnAccountCreateManyAccountInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default RolesOnAccountCreateManyAccountInputEnvelopeSchema;
