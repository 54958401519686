// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentWhereInputSchema } from './ReportedContentWhereInputSchema';

export const ReportedContentListRelationFilterSchema: z.ZodType<Prisma.ReportedContentListRelationFilter> = z.object({
  every: z.lazy(() => ReportedContentWhereInputSchema).optional(),
  some: z.lazy(() => ReportedContentWhereInputSchema).optional(),
  none: z.lazy(() => ReportedContentWhereInputSchema).optional()
}).strict();

export default ReportedContentListRelationFilterSchema;
