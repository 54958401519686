// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutEventsInputSchema } from './AccountCreateNestedOneWithoutEventsInputSchema';

export const EventCreateInputSchema: z.ZodType<Prisma.EventCreateInput> = z.object({
  id: z.string().optional(),
  title: z.string(),
  subtitle: z.string(),
  content: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  organization: z.lazy(() => AccountCreateNestedOneWithoutEventsInputSchema)
}).strict();

export default EventCreateInputSchema;
