// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereUniqueInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ColegioMedicoSelectSchema: z.ZodType<Prisma.ColegioMedicoSelect> = z.object({
  id: z.boolean().optional(),
  icm: z.boolean().optional(),
  names: z.boolean().optional(),
  lastNames: z.boolean().optional(),
  afiliation: z.boolean().optional(),
  city: z.boolean().optional(),
}).strict()

export const ColegioMedicoFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ColegioMedicoFindUniqueOrThrowArgs> = z.object({
  select: ColegioMedicoSelectSchema.optional(),
  where: ColegioMedicoWhereUniqueInputSchema,
}).strict() ;

export default ColegioMedicoFindUniqueOrThrowArgsSchema;
