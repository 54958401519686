// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventWhereUniqueInputSchema } from './EventWhereUniqueInputSchema';
import { EventUpdateWithoutOrganizationInputSchema } from './EventUpdateWithoutOrganizationInputSchema';
import { EventUncheckedUpdateWithoutOrganizationInputSchema } from './EventUncheckedUpdateWithoutOrganizationInputSchema';
import { EventCreateWithoutOrganizationInputSchema } from './EventCreateWithoutOrganizationInputSchema';
import { EventUncheckedCreateWithoutOrganizationInputSchema } from './EventUncheckedCreateWithoutOrganizationInputSchema';

export const EventUpsertWithWhereUniqueWithoutOrganizationInputSchema: z.ZodType<Prisma.EventUpsertWithWhereUniqueWithoutOrganizationInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => EventUpdateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedUpdateWithoutOrganizationInputSchema) ]),
  create: z.union([ z.lazy(() => EventCreateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema) ]),
}).strict();

export default EventUpsertWithWhereUniqueWithoutOrganizationInputSchema;
