// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationArgsSchema } from "../outputTypeSchemas/CollaborationArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"

export const RolesOnCollaborationIncludeSchema: z.ZodType<Prisma.RolesOnCollaborationInclude> = z.object({
  collaboration: z.union([z.boolean(),z.lazy(() => CollaborationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export default RolesOnCollaborationIncludeSchema;
