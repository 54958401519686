// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereInputSchema'
import { ColegioMedicoOrderByWithAggregationInputSchema } from '../inputTypeSchemas/ColegioMedicoOrderByWithAggregationInputSchema'
import { ColegioMedicoScalarFieldEnumSchema } from '../inputTypeSchemas/ColegioMedicoScalarFieldEnumSchema'
import { ColegioMedicoScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/ColegioMedicoScalarWhereWithAggregatesInputSchema'

export const ColegioMedicoGroupByArgsSchema: z.ZodType<Prisma.ColegioMedicoGroupByArgs> = z.object({
  where: ColegioMedicoWhereInputSchema.optional(),
  orderBy: z.union([ ColegioMedicoOrderByWithAggregationInputSchema.array(),ColegioMedicoOrderByWithAggregationInputSchema ]).optional(),
  by: ColegioMedicoScalarFieldEnumSchema.array(),
  having: ColegioMedicoScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ColegioMedicoGroupByArgsSchema;
