// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationWhereInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereInputSchema'
import { RolesOnCollaborationOrderByWithRelationInputSchema } from '../inputTypeSchemas/RolesOnCollaborationOrderByWithRelationInputSchema'
import { RolesOnCollaborationWhereUniqueInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereUniqueInputSchema'

export const RolesOnCollaborationAggregateArgsSchema: z.ZodType<Prisma.RolesOnCollaborationAggregateArgs> = z.object({
  where: RolesOnCollaborationWhereInputSchema.optional(),
  orderBy: z.union([ RolesOnCollaborationOrderByWithRelationInputSchema.array(),RolesOnCollaborationOrderByWithRelationInputSchema ]).optional(),
  cursor: RolesOnCollaborationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default RolesOnCollaborationAggregateArgsSchema;
