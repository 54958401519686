// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutPushTokensInputSchema } from './AccountCreateWithoutPushTokensInputSchema';
import { AccountUncheckedCreateWithoutPushTokensInputSchema } from './AccountUncheckedCreateWithoutPushTokensInputSchema';
import { AccountCreateOrConnectWithoutPushTokensInputSchema } from './AccountCreateOrConnectWithoutPushTokensInputSchema';
import { AccountUpsertWithoutPushTokensInputSchema } from './AccountUpsertWithoutPushTokensInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutPushTokensInputSchema } from './AccountUpdateToOneWithWhereWithoutPushTokensInputSchema';
import { AccountUpdateWithoutPushTokensInputSchema } from './AccountUpdateWithoutPushTokensInputSchema';
import { AccountUncheckedUpdateWithoutPushTokensInputSchema } from './AccountUncheckedUpdateWithoutPushTokensInputSchema';

export const AccountUpdateOneRequiredWithoutPushTokensNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutPushTokensNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedCreateWithoutPushTokensInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutPushTokensInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutPushTokensInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutPushTokensInputSchema),z.lazy(() => AccountUpdateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutPushTokensInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutPushTokensNestedInputSchema;
