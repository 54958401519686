// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentUpdateManyMutationInputSchema } from '../inputTypeSchemas/ReportedContentUpdateManyMutationInputSchema'
import { ReportedContentUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/ReportedContentUncheckedUpdateManyInputSchema'
import { ReportedContentWhereInputSchema } from '../inputTypeSchemas/ReportedContentWhereInputSchema'

export const ReportedContentUpdateManyArgsSchema: z.ZodType<Prisma.ReportedContentUpdateManyArgs> = z.object({
  data: z.union([ ReportedContentUpdateManyMutationInputSchema,ReportedContentUncheckedUpdateManyInputSchema ]),
  where: ReportedContentWhereInputSchema.optional(),
}).strict() ;

export default ReportedContentUpdateManyArgsSchema;
