// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const ExamLabCreateManyInputSchema: z.ZodType<Prisma.ExamLabCreateManyInput> = z.object({
  id: z.string().optional(),
  name_es: z.string(),
  category: z.string()
}).strict();

export default ExamLabCreateManyInputSchema;
