// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutEventsInputSchema } from './AccountCreateWithoutEventsInputSchema';
import { AccountUncheckedCreateWithoutEventsInputSchema } from './AccountUncheckedCreateWithoutEventsInputSchema';
import { AccountCreateOrConnectWithoutEventsInputSchema } from './AccountCreateOrConnectWithoutEventsInputSchema';
import { AccountUpsertWithoutEventsInputSchema } from './AccountUpsertWithoutEventsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutEventsInputSchema } from './AccountUpdateToOneWithWhereWithoutEventsInputSchema';
import { AccountUpdateWithoutEventsInputSchema } from './AccountUpdateWithoutEventsInputSchema';
import { AccountUncheckedUpdateWithoutEventsInputSchema } from './AccountUncheckedUpdateWithoutEventsInputSchema';

export const AccountUpdateOneRequiredWithoutEventsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutEventsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutEventsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutEventsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutEventsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutEventsInputSchema),z.lazy(() => AccountUpdateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutEventsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutEventsNestedInputSchema;
