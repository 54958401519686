// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInputSchema';

export const CollaborationInvitationCreateWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  inviteeEmail: z.string(),
  status: z.lazy(() => INVITATION_STATUSSchema).optional(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  invitee: z.lazy(() => AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInputSchema).optional()
}).strict();

export default CollaborationInvitationCreateWithoutAccountInputSchema;
