// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { RoleRelationFilterSchema } from './RoleRelationFilterSchema';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';

export const RolesOnAccountWhereInputSchema: z.ZodType<Prisma.RolesOnAccountWhereInput> = z.object({
  AND: z.union([ z.lazy(() => RolesOnAccountWhereInputSchema),z.lazy(() => RolesOnAccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RolesOnAccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RolesOnAccountWhereInputSchema),z.lazy(() => RolesOnAccountWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  roleId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleRelationFilterSchema),z.lazy(() => RoleWhereInputSchema) ]).optional(),
}).strict();

export default RolesOnAccountWhereInputSchema;
