// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutPushTokensInputSchema } from './AccountUpdateWithoutPushTokensInputSchema';
import { AccountUncheckedUpdateWithoutPushTokensInputSchema } from './AccountUncheckedUpdateWithoutPushTokensInputSchema';
import { AccountCreateWithoutPushTokensInputSchema } from './AccountCreateWithoutPushTokensInputSchema';
import { AccountUncheckedCreateWithoutPushTokensInputSchema } from './AccountUncheckedCreateWithoutPushTokensInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutPushTokensInputSchema: z.ZodType<Prisma.AccountUpsertWithoutPushTokensInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutPushTokensInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedCreateWithoutPushTokensInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutPushTokensInputSchema;
