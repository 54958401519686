// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ExamImagesOrderByRelevanceFieldEnumSchema } from './ExamImagesOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const ExamImagesOrderByRelevanceInputSchema: z.ZodType<Prisma.ExamImagesOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ExamImagesOrderByRelevanceFieldEnumSchema),z.lazy(() => ExamImagesOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default ExamImagesOrderByRelevanceInputSchema;
