// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';

export const RolesOnCollaborationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.RolesOnCollaborationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => RolesOnCollaborationScalarWhereWithAggregatesInputSchema),z.lazy(() => RolesOnCollaborationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => RolesOnCollaborationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RolesOnCollaborationScalarWhereWithAggregatesInputSchema),z.lazy(() => RolesOnCollaborationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  collaborationId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  roleId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default RolesOnCollaborationScalarWhereWithAggregatesInputSchema;
