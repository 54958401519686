// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationCreateWithoutInviteeInputSchema } from './CollaborationInvitationCreateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema';
import { CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema } from './CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema';
import { CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema } from './CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema';
import { CollaborationInvitationCreateManyInviteeInputEnvelopeSchema } from './CollaborationInvitationCreateManyInviteeInputEnvelopeSchema';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema } from './CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema';
import { CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema } from './CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema';
import { CollaborationInvitationScalarWhereInputSchema } from './CollaborationInvitationScalarWhereInputSchema';

export const CollaborationInvitationUpdateManyWithoutInviteeNestedInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateManyWithoutInviteeNestedInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema).array(),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUpsertWithWhereUniqueWithoutInviteeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationInvitationCreateManyInviteeInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUpdateManyWithWhereWithoutInviteeInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CollaborationInvitationScalarWhereInputSchema),z.lazy(() => CollaborationInvitationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default CollaborationInvitationUpdateManyWithoutInviteeNestedInputSchema;
