// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductWhereUniqueInputSchema } from '../inputTypeSchemas/IspProductWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const IspProductSelectSchema: z.ZodType<Prisma.IspProductSelect> = z.object({
  id: z.boolean().optional(),
  record: z.boolean().optional(),
  name: z.boolean().optional(),
  transactionReference: z.boolean().optional(),
  therapeuticEquivalenceOrBiosimilar: z.boolean().optional(),
  holder: z.boolean().optional(),
  recordStatus: z.boolean().optional(),
  registrationResolution: z.boolean().optional(),
  registrationDate: z.boolean().optional(),
  lastRenewal: z.boolean().optional(),
  nextRenewalDate: z.boolean().optional(),
  regime: z.boolean().optional(),
  administrationRoute: z.boolean().optional(),
  salesCondition: z.boolean().optional(),
  establishmentType: z.boolean().optional(),
  indication: z.boolean().optional(),
  packagings: z.boolean().optional(),
  companyFunctions: z.boolean().optional(),
  formulas: z.boolean().optional(),
  cenabast: z.boolean().optional(),
}).strict()

export const IspProductDeleteArgsSchema: z.ZodType<Prisma.IspProductDeleteArgs> = z.object({
  select: IspProductSelectSchema.optional(),
  where: IspProductWhereUniqueInputSchema,
}).strict() ;

export default IspProductDeleteArgsSchema;
