// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { EnumINVITATION_STATUSFilterSchema } from './EnumINVITATION_STATUSFilterSchema';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';

export const CollaborationInvitationScalarWhereInputSchema: z.ZodType<Prisma.CollaborationInvitationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CollaborationInvitationScalarWhereInputSchema),z.lazy(() => CollaborationInvitationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationInvitationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationInvitationScalarWhereInputSchema),z.lazy(() => CollaborationInvitationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inviteeId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  inviteeEmail: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumINVITATION_STATUSFilterSchema),z.lazy(() => INVITATION_STATUSSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default CollaborationInvitationScalarWhereInputSchema;
