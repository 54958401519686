// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutRolesInputSchema } from './AccountCreateWithoutRolesInputSchema';
import { AccountUncheckedCreateWithoutRolesInputSchema } from './AccountUncheckedCreateWithoutRolesInputSchema';
import { AccountCreateOrConnectWithoutRolesInputSchema } from './AccountCreateOrConnectWithoutRolesInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutRolesInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutRolesInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutRolesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutRolesInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutRolesInputSchema;
