// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesCreateManyInputSchema } from '../inputTypeSchemas/ExamImagesCreateManyInputSchema'

export const ExamImagesCreateManyArgsSchema: z.ZodType<Prisma.ExamImagesCreateManyArgs> = z.object({
  data: z.union([ ExamImagesCreateManyInputSchema,ExamImagesCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default ExamImagesCreateManyArgsSchema;
