// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutRolesInputSchema } from './AccountCreateNestedOneWithoutRolesInputSchema';
import { RoleCreateNestedOneWithoutAccountsInputSchema } from './RoleCreateNestedOneWithoutAccountsInputSchema';

export const RolesOnAccountCreateInputSchema: z.ZodType<Prisma.RolesOnAccountCreateInput> = z.object({
  id: z.string().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutRolesInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutAccountsInputSchema)
}).strict();

export default RolesOnAccountCreateInputSchema;
