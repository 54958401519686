// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationWhereInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereInputSchema'
import { CollaborationInvitationOrderByWithAggregationInputSchema } from '../inputTypeSchemas/CollaborationInvitationOrderByWithAggregationInputSchema'
import { CollaborationInvitationScalarFieldEnumSchema } from '../inputTypeSchemas/CollaborationInvitationScalarFieldEnumSchema'
import { CollaborationInvitationScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/CollaborationInvitationScalarWhereWithAggregatesInputSchema'

export const CollaborationInvitationGroupByArgsSchema: z.ZodType<Prisma.CollaborationInvitationGroupByArgs> = z.object({
  where: CollaborationInvitationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationInvitationOrderByWithAggregationInputSchema.array(),CollaborationInvitationOrderByWithAggregationInputSchema ]).optional(),
  by: CollaborationInvitationScalarFieldEnumSchema.array(),
  having: CollaborationInvitationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default CollaborationInvitationGroupByArgsSchema;
