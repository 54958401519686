// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { IntNullableFilterSchema } from './IntNullableFilterSchema';
import { BoolFilterSchema } from './BoolFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';

export const ReportedContentScalarWhereInputSchema: z.ZodType<Prisma.ReportedContentScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ReportedContentScalarWhereInputSchema),z.lazy(() => ReportedContentScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ReportedContentScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ReportedContentScalarWhereInputSchema),z.lazy(() => ReportedContentScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  reason: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  postId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  commentId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  wasChecked: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  reportedById: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default ReportedContentScalarWhereInputSchema;
