// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithoutRoleInputSchema } from './RolesOnAccountUpdateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedUpdateWithoutRoleInputSchema } from './RolesOnAccountUncheckedUpdateWithoutRoleInputSchema';

export const RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RolesOnAccountUpdateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema;
