// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationSelectSchema } from '../inputTypeSchemas/CollaborationInvitationSelectSchema';
import { CollaborationInvitationIncludeSchema } from '../inputTypeSchemas/CollaborationInvitationIncludeSchema';

export const CollaborationInvitationArgsSchema: z.ZodType<Prisma.CollaborationInvitationDefaultArgs> = z.object({
  select: z.lazy(() => CollaborationInvitationSelectSchema).optional(),
  include: z.lazy(() => CollaborationInvitationIncludeSchema).optional(),
}).strict();

export default CollaborationInvitationArgsSchema;
