// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateManyCollaboratorInputSchema } from './CollaborationCreateManyCollaboratorInputSchema';

export const CollaborationCreateManyCollaboratorInputEnvelopeSchema: z.ZodType<Prisma.CollaborationCreateManyCollaboratorInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CollaborationCreateManyCollaboratorInputSchema),z.lazy(() => CollaborationCreateManyCollaboratorInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default CollaborationCreateManyCollaboratorInputEnvelopeSchema;
