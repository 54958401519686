// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereInputSchema } from './CollaborationInvitationWhereInputSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { EnumINVITATION_STATUSFilterSchema } from './EnumINVITATION_STATUSFilterSchema';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';
import { AccountNullableRelationFilterSchema } from './AccountNullableRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';

export const CollaborationInvitationWhereUniqueInputSchema: z.ZodType<Prisma.CollaborationInvitationWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CollaborationInvitationWhereInputSchema),z.lazy(() => CollaborationInvitationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationInvitationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationInvitationWhereInputSchema),z.lazy(() => CollaborationInvitationWhereInputSchema).array() ]).optional(),
  inviteeId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  inviteeEmail: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumINVITATION_STATUSFilterSchema),z.lazy(() => INVITATION_STATUSSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  invitee: z.union([ z.lazy(() => AccountNullableRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional().nullable(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
}).strict());

export default CollaborationInvitationWhereUniqueInputSchema;
