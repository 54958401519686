// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationCountOutputTypeSelectSchema } from './CollaborationCountOutputTypeSelectSchema';

export const CollaborationCountOutputTypeArgsSchema: z.ZodType<Prisma.CollaborationCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CollaborationCountOutputTypeSelectSchema).nullish(),
}).strict();

export default CollaborationCountOutputTypeSelectSchema;
