// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationScalarWhereInputSchema } from './RolesOnCollaborationScalarWhereInputSchema';
import { RolesOnCollaborationUpdateManyMutationInputSchema } from './RolesOnCollaborationUpdateManyMutationInputSchema';
import { RolesOnCollaborationUncheckedUpdateManyWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedUpdateManyWithoutRoleInputSchema';

export const RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateManyWithWhereWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RolesOnCollaborationUpdateManyMutationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateManyWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpdateManyWithWhereWithoutRoleInputSchema;
