// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema';

export const CollaborationUncheckedCreateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUncheckedCreateWithoutCollaboratorInput> = z.object({
  id: z.string().optional(),
  accountId: z.string(),
  showInSearch: z.boolean().optional(),
  roles: z.lazy(() => RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema).optional()
}).strict();

export default CollaborationUncheckedCreateWithoutCollaboratorInputSchema;
