// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventWhereUniqueInputSchema } from './EventWhereUniqueInputSchema';
import { EventCreateWithoutOrganizationInputSchema } from './EventCreateWithoutOrganizationInputSchema';
import { EventUncheckedCreateWithoutOrganizationInputSchema } from './EventUncheckedCreateWithoutOrganizationInputSchema';

export const EventCreateOrConnectWithoutOrganizationInputSchema: z.ZodType<Prisma.EventCreateOrConnectWithoutOrganizationInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => EventCreateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema) ]),
}).strict();

export default EventCreateOrConnectWithoutOrganizationInputSchema;
