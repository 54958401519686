// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteIncludeSchema } from '../inputTypeSchemas/SiteIncludeSchema'
import { SiteWhereInputSchema } from '../inputTypeSchemas/SiteWhereInputSchema'
import { SiteOrderByWithRelationInputSchema } from '../inputTypeSchemas/SiteOrderByWithRelationInputSchema'
import { SiteWhereUniqueInputSchema } from '../inputTypeSchemas/SiteWhereUniqueInputSchema'
import { SiteScalarFieldEnumSchema } from '../inputTypeSchemas/SiteScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const SiteSelectSchema: z.ZodType<Prisma.SiteSelect> = z.object({
  id: z.boolean().optional(),
  domain: z.boolean().optional(),
  status: z.boolean().optional(),
  accountId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const SiteFindManyArgsSchema: z.ZodType<Prisma.SiteFindManyArgs> = z.object({
  select: SiteSelectSchema.optional(),
  include: SiteIncludeSchema.optional(),
  where: SiteWhereInputSchema.optional(),
  orderBy: z.union([ SiteOrderByWithRelationInputSchema.array(),SiteOrderByWithRelationInputSchema ]).optional(),
  cursor: SiteWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SiteScalarFieldEnumSchema,SiteScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default SiteFindManyArgsSchema;
