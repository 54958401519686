// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateWithoutAccountInputSchema } from './RolesOnAccountCreateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedCreateWithoutAccountInputSchema } from './RolesOnAccountUncheckedCreateWithoutAccountInputSchema';
import { RolesOnAccountCreateOrConnectWithoutAccountInputSchema } from './RolesOnAccountCreateOrConnectWithoutAccountInputSchema';
import { RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema } from './RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema';
import { RolesOnAccountCreateManyAccountInputEnvelopeSchema } from './RolesOnAccountCreateManyAccountInputEnvelopeSchema';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema } from './RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema';
import { RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema } from './RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema';
import { RolesOnAccountScalarWhereInputSchema } from './RolesOnAccountScalarWhereInputSchema';

export const RolesOnAccountUpdateManyWithoutAccountNestedInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateManyWithoutAccountNestedInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema).array(),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnAccountCreateOrConnectWithoutAccountInputSchema),z.lazy(() => RolesOnAccountCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnAccountCreateManyAccountInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUpdateWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RolesOnAccountScalarWhereInputSchema),z.lazy(() => RolesOnAccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default RolesOnAccountUpdateManyWithoutAccountNestedInputSchema;
