// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { LANGSchema } from './LANGSchema';
import { NestedEnumLANGNullableFilterSchema } from './NestedEnumLANGNullableFilterSchema';

export const EnumLANGNullableFilterSchema: z.ZodType<Prisma.EnumLANGNullableFilter> = z.object({
  equals: z.lazy(() => LANGSchema).optional().nullable(),
  in: z.lazy(() => LANGSchema).array().optional().nullable(),
  notIn: z.lazy(() => LANGSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => LANGSchema),z.lazy(() => NestedEnumLANGNullableFilterSchema) ]).optional().nullable(),
}).strict();

export default EnumLANGNullableFilterSchema;
