// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountUncheckedCreateNestedManyWithoutRoleInputSchema } from './RolesOnAccountUncheckedCreateNestedManyWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInputSchema';

export const RoleUncheckedCreateInputSchema: z.ZodType<Prisma.RoleUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  description: z.string(),
  name: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  accounts: z.lazy(() => RolesOnAccountUncheckedCreateNestedManyWithoutRoleInputSchema).optional(),
  collaborations: z.lazy(() => RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInputSchema).optional()
}).strict();

export default RoleUncheckedCreateInputSchema;
