// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutOwnerInputSchema } from './AccountCreateWithoutOwnerInputSchema';
import { AccountUncheckedCreateWithoutOwnerInputSchema } from './AccountUncheckedCreateWithoutOwnerInputSchema';

export const AccountCreateOrConnectWithoutOwnerInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutOwnerInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutOwnerInputSchema;
