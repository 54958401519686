// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';

export const RolesOnAccountScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.RolesOnAccountScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => RolesOnAccountScalarWhereWithAggregatesInputSchema),z.lazy(() => RolesOnAccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => RolesOnAccountScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RolesOnAccountScalarWhereWithAggregatesInputSchema),z.lazy(() => RolesOnAccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  roleId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default RolesOnAccountScalarWhereWithAggregatesInputSchema;
