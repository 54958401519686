// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { AccountUpdateOneRequiredWithoutRolesNestedInputSchema } from './AccountUpdateOneRequiredWithoutRolesNestedInputSchema';

export const RolesOnAccountUpdateWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateWithoutRoleInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  account: z.lazy(() => AccountUpdateOneRequiredWithoutRolesNestedInputSchema).optional()
}).strict();

export default RolesOnAccountUpdateWithoutRoleInputSchema;
