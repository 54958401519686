// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { BoolFilterSchema } from './BoolFilterSchema';

export const CollaborationScalarWhereInputSchema: z.ZodType<Prisma.CollaborationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CollaborationScalarWhereInputSchema),z.lazy(() => CollaborationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationScalarWhereInputSchema),z.lazy(() => CollaborationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  showInSearch: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export default CollaborationScalarWhereInputSchema;
