// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { IspProductCountOrderByAggregateInputSchema } from './IspProductCountOrderByAggregateInputSchema';
import { IspProductMaxOrderByAggregateInputSchema } from './IspProductMaxOrderByAggregateInputSchema';
import { IspProductMinOrderByAggregateInputSchema } from './IspProductMinOrderByAggregateInputSchema';

export const IspProductOrderByWithAggregationInputSchema: z.ZodType<Prisma.IspProductOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  record: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  transactionReference: z.lazy(() => SortOrderSchema).optional(),
  therapeuticEquivalenceOrBiosimilar: z.lazy(() => SortOrderSchema).optional(),
  holder: z.lazy(() => SortOrderSchema).optional(),
  recordStatus: z.lazy(() => SortOrderSchema).optional(),
  registrationResolution: z.lazy(() => SortOrderSchema).optional(),
  registrationDate: z.lazy(() => SortOrderSchema).optional(),
  lastRenewal: z.lazy(() => SortOrderSchema).optional(),
  nextRenewalDate: z.lazy(() => SortOrderSchema).optional(),
  regime: z.lazy(() => SortOrderSchema).optional(),
  administrationRoute: z.lazy(() => SortOrderSchema).optional(),
  salesCondition: z.lazy(() => SortOrderSchema).optional(),
  establishmentType: z.lazy(() => SortOrderSchema).optional(),
  indication: z.lazy(() => SortOrderSchema).optional(),
  packagings: z.lazy(() => SortOrderSchema).optional(),
  companyFunctions: z.lazy(() => SortOrderSchema).optional(),
  formulas: z.lazy(() => SortOrderSchema).optional(),
  cenabast: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => IspProductCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => IspProductMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => IspProductMinOrderByAggregateInputSchema).optional()
}).strict();

export default IspProductOrderByWithAggregationInputSchema;
