// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationWhereInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereInputSchema'

export const CollaborationInvitationDeleteManyArgsSchema: z.ZodType<Prisma.CollaborationInvitationDeleteManyArgs> = z.object({
  where: CollaborationInvitationWhereInputSchema.optional(),
}).strict() ;

export default CollaborationInvitationDeleteManyArgsSchema;
