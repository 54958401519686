// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationIncludeSchema } from '../inputTypeSchemas/RolesOnCollaborationIncludeSchema'
import { RolesOnCollaborationCreateInputSchema } from '../inputTypeSchemas/RolesOnCollaborationCreateInputSchema'
import { RolesOnCollaborationUncheckedCreateInputSchema } from '../inputTypeSchemas/RolesOnCollaborationUncheckedCreateInputSchema'
import { CollaborationArgsSchema } from "../outputTypeSchemas/CollaborationArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const RolesOnCollaborationSelectSchema: z.ZodType<Prisma.RolesOnCollaborationSelect> = z.object({
  id: z.boolean().optional(),
  collaborationId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  collaboration: z.union([z.boolean(),z.lazy(() => CollaborationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export const RolesOnCollaborationCreateArgsSchema: z.ZodType<Prisma.RolesOnCollaborationCreateArgs> = z.object({
  select: RolesOnCollaborationSelectSchema.optional(),
  include: RolesOnCollaborationIncludeSchema.optional(),
  data: z.union([ RolesOnCollaborationCreateInputSchema,RolesOnCollaborationUncheckedCreateInputSchema ]),
}).strict() ;

export default RolesOnCollaborationCreateArgsSchema;
