// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const RolesOnCollaborationCreateManyCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateManyCollaborationInput> = z.object({
  id: z.string().optional(),
  roleId: z.string()
}).strict();

export default RolesOnCollaborationCreateManyCollaborationInputSchema;
