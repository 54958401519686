// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationScalarWhereInputSchema } from './RolesOnCollaborationScalarWhereInputSchema';
import { RolesOnCollaborationUpdateManyMutationInputSchema } from './RolesOnCollaborationUpdateManyMutationInputSchema';
import { RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationInputSchema';

export const RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RolesOnCollaborationUpdateManyMutationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateManyWithoutCollaborationInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema;
