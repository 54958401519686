// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';

export const SiteUncheckedCreateInputSchema: z.ZodType<Prisma.SiteUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  domain: z.string(),
  status: z.lazy(() => DomainActivationStatusSchema).optional(),
  accountId: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional()
}).strict();

export default SiteUncheckedCreateInputSchema;
