// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { JsonNullValueInputSchema } from './JsonNullValueInputSchema';
import { InputJsonValueSchema } from './InputJsonValueSchema';
import { NullableJsonNullValueInputSchema } from './NullableJsonNullValueInputSchema';

export const IspProductCreateManyInputSchema: z.ZodType<Prisma.IspProductCreateManyInput> = z.object({
  id: z.string().optional(),
  record: z.string(),
  name: z.string(),
  transactionReference: z.string(),
  therapeuticEquivalenceOrBiosimilar: z.string(),
  holder: z.string(),
  recordStatus: z.string(),
  registrationResolution: z.string(),
  registrationDate: z.string(),
  lastRenewal: z.string(),
  nextRenewalDate: z.string(),
  regime: z.string(),
  administrationRoute: z.string(),
  salesCondition: z.string(),
  establishmentType: z.string(),
  indication: z.string(),
  packagings: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  companyFunctions: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  formulas: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  cenabast: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
}).strict();

export default IspProductCreateManyInputSchema;
