// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithoutAccountInputSchema } from './RolesOnAccountUpdateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedUpdateWithoutAccountInputSchema } from './RolesOnAccountUncheckedUpdateWithoutAccountInputSchema';
import { RolesOnAccountCreateWithoutAccountInputSchema } from './RolesOnAccountCreateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedCreateWithoutAccountInputSchema } from './RolesOnAccountUncheckedCreateWithoutAccountInputSchema';

export const RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountUpsertWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RolesOnAccountUpdateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateWithoutAccountInputSchema) ]),
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default RolesOnAccountUpsertWithWhereUniqueWithoutAccountInputSchema;
