// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const NestedBoolNullableFilterSchema: z.ZodType<Prisma.NestedBoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export default NestedBoolNullableFilterSchema;
