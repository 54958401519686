// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutPushTokensInputSchema } from './AccountUpdateWithoutPushTokensInputSchema';
import { AccountUncheckedUpdateWithoutPushTokensInputSchema } from './AccountUncheckedUpdateWithoutPushTokensInputSchema';

export const AccountUpdateToOneWithWhereWithoutPushTokensInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutPushTokensInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutPushTokensInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutPushTokensInputSchema;
