// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { IntNullableFilterSchema } from './IntNullableFilterSchema';
import { BoolFilterSchema } from './BoolFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const ReportedContentWhereInputSchema: z.ZodType<Prisma.ReportedContentWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ReportedContentWhereInputSchema),z.lazy(() => ReportedContentWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ReportedContentWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ReportedContentWhereInputSchema),z.lazy(() => ReportedContentWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  reason: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  postId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  commentId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  wasChecked: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  reportedById: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  reportedBy: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
}).strict();

export default ReportedContentWhereInputSchema;
