// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithoutRoleInputSchema } from './RolesOnCollaborationUpdateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema';

export const RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpdateWithWhereUniqueWithoutRoleInputSchema;
