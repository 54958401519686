// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutCollaborationsInputSchema } from './AccountCreateWithoutCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutCollaborationsInputSchema } from './AccountUncheckedCreateWithoutCollaborationsInputSchema';
import { AccountCreateOrConnectWithoutCollaborationsInputSchema } from './AccountCreateOrConnectWithoutCollaborationsInputSchema';
import { AccountUpsertWithoutCollaborationsInputSchema } from './AccountUpsertWithoutCollaborationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutCollaborationsInputSchema } from './AccountUpdateToOneWithWhereWithoutCollaborationsInputSchema';
import { AccountUpdateWithoutCollaborationsInputSchema } from './AccountUpdateWithoutCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutCollaborationsInputSchema';

export const AccountUpdateOneRequiredWithoutCollaborationsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutCollaborationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutCollaborationsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutCollaborationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutCollaborationsInputSchema),z.lazy(() => AccountUpdateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutCollaborationsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutCollaborationsNestedInputSchema;
