// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentWhereInputSchema } from '../inputTypeSchemas/ReportedContentWhereInputSchema'

export const ReportedContentDeleteManyArgsSchema: z.ZodType<Prisma.ReportedContentDeleteManyArgs> = z.object({
  where: ReportedContentWhereInputSchema.optional(),
}).strict() ;

export default ReportedContentDeleteManyArgsSchema;
