// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { BoolFilterSchema } from './BoolFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { RolesOnCollaborationListRelationFilterSchema } from './RolesOnCollaborationListRelationFilterSchema';

export const CollaborationWhereInputSchema: z.ZodType<Prisma.CollaborationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CollaborationWhereInputSchema),z.lazy(() => CollaborationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationWhereInputSchema),z.lazy(() => CollaborationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  showInSearch: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  roles: z.lazy(() => RolesOnCollaborationListRelationFilterSchema).optional()
}).strict();

export default CollaborationWhereInputSchema;
