// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';

export const CollaborationInvitationCreateManyInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateManyInviteeInput> = z.object({
  id: z.string().optional(),
  inviteeEmail: z.string(),
  status: z.lazy(() => INVITATION_STATUSSchema).optional(),
  accountId: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional()
}).strict();

export default CollaborationInvitationCreateManyInviteeInputSchema;
