// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema: z.ZodType<Prisma.CollaborationAccountIdCollaboratorIdCompoundUniqueInput> = z.object({
  accountId: z.string(),
  collaboratorId: z.string()
}).strict();

export default CollaborationAccountIdCollaboratorIdCompoundUniqueInputSchema;
