// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateNestedManyWithoutRoleInputSchema } from './RolesOnAccountCreateNestedManyWithoutRoleInputSchema';
import { RolesOnCollaborationCreateNestedManyWithoutRoleInputSchema } from './RolesOnCollaborationCreateNestedManyWithoutRoleInputSchema';

export const RoleCreateInputSchema: z.ZodType<Prisma.RoleCreateInput> = z.object({
  id: z.string().optional(),
  description: z.string(),
  name: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  accounts: z.lazy(() => RolesOnAccountCreateNestedManyWithoutRoleInputSchema).optional(),
  collaborations: z.lazy(() => RolesOnCollaborationCreateNestedManyWithoutRoleInputSchema).optional()
}).strict();

export default RoleCreateInputSchema;
