// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ExamImagesWhereInputSchema } from './ExamImagesWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';

export const ExamImagesWhereUniqueInputSchema: z.ZodType<Prisma.ExamImagesWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => ExamImagesWhereInputSchema),z.lazy(() => ExamImagesWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ExamImagesWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ExamImagesWhereInputSchema),z.lazy(() => ExamImagesWhereInputSchema).array() ]).optional(),
  bodyArea: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bodyPart: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name_es: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  symptom: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict());

export default ExamImagesWhereUniqueInputSchema;
