// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// EVENT SCHEMA
/////////////////////////////////////////

export const EventSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  title: z.string(),
  subtitle: z.string(),
  content: z.string(),
  created: z.coerce.date(),
  deleted: z.coerce.date().nullable(),
  updated: z.coerce.date(),
})

export type Event = z.infer<typeof EventSchema>

export default EventSchema;
