// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsUpdateInputSchema } from '../inputTypeSchemas/ProductExamsUpdateInputSchema'
import { ProductExamsUncheckedUpdateInputSchema } from '../inputTypeSchemas/ProductExamsUncheckedUpdateInputSchema'
import { ProductExamsWhereUniqueInputSchema } from '../inputTypeSchemas/ProductExamsWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ProductExamsSelectSchema: z.ZodType<Prisma.ProductExamsSelect> = z.object({
  id: z.boolean().optional(),
  wooCommerceProductId: z.boolean().optional(),
  images: z.boolean().optional(),
  labs: z.boolean().optional(),
  accountId: z.boolean().optional(),
  doctorAccountId: z.boolean().optional(),
}).strict()

export const ProductExamsUpdateArgsSchema: z.ZodType<Prisma.ProductExamsUpdateArgs> = z.object({
  select: ProductExamsSelectSchema.optional(),
  data: z.union([ ProductExamsUpdateInputSchema,ProductExamsUncheckedUpdateInputSchema ]),
  where: ProductExamsWhereUniqueInputSchema,
}).strict() ;

export default ProductExamsUpdateArgsSchema;
