// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereInputSchema } from './CollaborationInvitationWhereInputSchema';

export const CollaborationInvitationListRelationFilterSchema: z.ZodType<Prisma.CollaborationInvitationListRelationFilter> = z.object({
  every: z.lazy(() => CollaborationInvitationWhereInputSchema).optional(),
  some: z.lazy(() => CollaborationInvitationWhereInputSchema).optional(),
  none: z.lazy(() => CollaborationInvitationWhereInputSchema).optional()
}).strict();

export default CollaborationInvitationListRelationFilterSchema;
