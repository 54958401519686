// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const ReportedContentSumOrderByAggregateInputSchema: z.ZodType<Prisma.ReportedContentSumOrderByAggregateInput> = z.object({
  postId: z.lazy(() => SortOrderSchema).optional(),
  commentId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default ReportedContentSumOrderByAggregateInputSchema;
