// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesWhereInputSchema } from '../inputTypeSchemas/ExamImagesWhereInputSchema'

export const ExamImagesDeleteManyArgsSchema: z.ZodType<Prisma.ExamImagesDeleteManyArgs> = z.object({
  where: ExamImagesWhereInputSchema.optional(),
}).strict() ;

export default ExamImagesDeleteManyArgsSchema;
