// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutEventsInputSchema } from './AccountCreateWithoutEventsInputSchema';
import { AccountUncheckedCreateWithoutEventsInputSchema } from './AccountUncheckedCreateWithoutEventsInputSchema';
import { AccountCreateOrConnectWithoutEventsInputSchema } from './AccountCreateOrConnectWithoutEventsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutEventsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutEventsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutEventsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutEventsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutEventsInputSchema;
