// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"

export const PushTokensSelectSchema: z.ZodType<Prisma.PushTokensSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  pushToken: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export default PushTokensSelectSchema;
