// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ColegioMedicoWhereInputSchema } from './ColegioMedicoWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';

export const ColegioMedicoWhereUniqueInputSchema: z.ZodType<Prisma.ColegioMedicoWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    icm: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    icm: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  icm: z.string().optional(),
  AND: z.union([ z.lazy(() => ColegioMedicoWhereInputSchema),z.lazy(() => ColegioMedicoWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ColegioMedicoWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ColegioMedicoWhereInputSchema),z.lazy(() => ColegioMedicoWhereInputSchema).array() ]).optional(),
  names: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastNames: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  afiliation: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict());

export default ColegioMedicoWhereUniqueInputSchema;
