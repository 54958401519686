// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoCreateInputSchema } from '../inputTypeSchemas/ColegioMedicoCreateInputSchema'
import { ColegioMedicoUncheckedCreateInputSchema } from '../inputTypeSchemas/ColegioMedicoUncheckedCreateInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ColegioMedicoSelectSchema: z.ZodType<Prisma.ColegioMedicoSelect> = z.object({
  id: z.boolean().optional(),
  icm: z.boolean().optional(),
  names: z.boolean().optional(),
  lastNames: z.boolean().optional(),
  afiliation: z.boolean().optional(),
  city: z.boolean().optional(),
}).strict()

export const ColegioMedicoCreateArgsSchema: z.ZodType<Prisma.ColegioMedicoCreateArgs> = z.object({
  select: ColegioMedicoSelectSchema.optional(),
  data: z.union([ ColegioMedicoCreateInputSchema,ColegioMedicoUncheckedCreateInputSchema ]),
}).strict() ;

export default ColegioMedicoCreateArgsSchema;
