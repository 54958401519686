// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereUniqueInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereUniqueInputSchema'
import { ColegioMedicoCreateInputSchema } from '../inputTypeSchemas/ColegioMedicoCreateInputSchema'
import { ColegioMedicoUncheckedCreateInputSchema } from '../inputTypeSchemas/ColegioMedicoUncheckedCreateInputSchema'
import { ColegioMedicoUpdateInputSchema } from '../inputTypeSchemas/ColegioMedicoUpdateInputSchema'
import { ColegioMedicoUncheckedUpdateInputSchema } from '../inputTypeSchemas/ColegioMedicoUncheckedUpdateInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ColegioMedicoSelectSchema: z.ZodType<Prisma.ColegioMedicoSelect> = z.object({
  id: z.boolean().optional(),
  icm: z.boolean().optional(),
  names: z.boolean().optional(),
  lastNames: z.boolean().optional(),
  afiliation: z.boolean().optional(),
  city: z.boolean().optional(),
}).strict()

export const ColegioMedicoUpsertArgsSchema: z.ZodType<Prisma.ColegioMedicoUpsertArgs> = z.object({
  select: ColegioMedicoSelectSchema.optional(),
  where: ColegioMedicoWhereUniqueInputSchema,
  create: z.union([ ColegioMedicoCreateInputSchema,ColegioMedicoUncheckedCreateInputSchema ]),
  update: z.union([ ColegioMedicoUpdateInputSchema,ColegioMedicoUncheckedUpdateInputSchema ]),
}).strict() ;

export default ColegioMedicoUpsertArgsSchema;
