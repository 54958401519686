// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleUpdateWithoutAccountsInputSchema } from './RoleUpdateWithoutAccountsInputSchema';
import { RoleUncheckedUpdateWithoutAccountsInputSchema } from './RoleUncheckedUpdateWithoutAccountsInputSchema';
import { RoleCreateWithoutAccountsInputSchema } from './RoleCreateWithoutAccountsInputSchema';
import { RoleUncheckedCreateWithoutAccountsInputSchema } from './RoleUncheckedCreateWithoutAccountsInputSchema';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';

export const RoleUpsertWithoutAccountsInputSchema: z.ZodType<Prisma.RoleUpsertWithoutAccountsInput> = z.object({
  update: z.union([ z.lazy(() => RoleUpdateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutAccountsInputSchema) ]),
  create: z.union([ z.lazy(() => RoleCreateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutAccountsInputSchema) ]),
  where: z.lazy(() => RoleWhereInputSchema).optional()
}).strict();

export default RoleUpsertWithoutAccountsInputSchema;
