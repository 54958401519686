// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { NestedEnumINVITATION_STATUSWithAggregatesFilterSchema } from './NestedEnumINVITATION_STATUSWithAggregatesFilterSchema';
import { NestedIntFilterSchema } from './NestedIntFilterSchema';
import { NestedEnumINVITATION_STATUSFilterSchema } from './NestedEnumINVITATION_STATUSFilterSchema';

export const EnumINVITATION_STATUSWithAggregatesFilterSchema: z.ZodType<Prisma.EnumINVITATION_STATUSWithAggregatesFilter> = z.object({
  equals: z.lazy(() => INVITATION_STATUSSchema).optional(),
  in: z.lazy(() => INVITATION_STATUSSchema).array().optional(),
  notIn: z.lazy(() => INVITATION_STATUSSchema).array().optional(),
  not: z.union([ z.lazy(() => INVITATION_STATUSSchema),z.lazy(() => NestedEnumINVITATION_STATUSWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumINVITATION_STATUSFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumINVITATION_STATUSFilterSchema).optional()
}).strict();

export default EnumINVITATION_STATUSWithAggregatesFilterSchema;
