// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountWhereInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereInputSchema'
import { RolesOnAccountOrderByWithRelationInputSchema } from '../inputTypeSchemas/RolesOnAccountOrderByWithRelationInputSchema'
import { RolesOnAccountWhereUniqueInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereUniqueInputSchema'

export const RolesOnAccountAggregateArgsSchema: z.ZodType<Prisma.RolesOnAccountAggregateArgs> = z.object({
  where: RolesOnAccountWhereInputSchema.optional(),
  orderBy: z.union([ RolesOnAccountOrderByWithRelationInputSchema.array(),RolesOnAccountOrderByWithRelationInputSchema ]).optional(),
  cursor: RolesOnAccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default RolesOnAccountAggregateArgsSchema;
