// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';

export const ColegioMedicoScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ColegioMedicoScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ColegioMedicoScalarWhereWithAggregatesInputSchema),z.lazy(() => ColegioMedicoScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ColegioMedicoScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ColegioMedicoScalarWhereWithAggregatesInputSchema),z.lazy(() => ColegioMedicoScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  icm: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  names: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastNames: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  afiliation: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default ColegioMedicoScalarWhereWithAggregatesInputSchema;
