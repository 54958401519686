// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabCreateInputSchema } from '../inputTypeSchemas/ExamLabCreateInputSchema'
import { ExamLabUncheckedCreateInputSchema } from '../inputTypeSchemas/ExamLabUncheckedCreateInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ExamLabSelectSchema: z.ZodType<Prisma.ExamLabSelect> = z.object({
  id: z.boolean().optional(),
  name_es: z.boolean().optional(),
  category: z.boolean().optional(),
}).strict()

export const ExamLabCreateArgsSchema: z.ZodType<Prisma.ExamLabCreateArgs> = z.object({
  select: ExamLabSelectSchema.optional(),
  data: z.union([ ExamLabCreateInputSchema,ExamLabUncheckedCreateInputSchema ]),
}).strict() ;

export default ExamLabCreateArgsSchema;
