// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';

export const NestedEnumINVITATION_STATUSFilterSchema: z.ZodType<Prisma.NestedEnumINVITATION_STATUSFilter> = z.object({
  equals: z.lazy(() => INVITATION_STATUSSchema).optional(),
  in: z.lazy(() => INVITATION_STATUSSchema).array().optional(),
  notIn: z.lazy(() => INVITATION_STATUSSchema).array().optional(),
  not: z.union([ z.lazy(() => INVITATION_STATUSSchema),z.lazy(() => NestedEnumINVITATION_STATUSFilterSchema) ]).optional(),
}).strict();

export default NestedEnumINVITATION_STATUSFilterSchema;
