// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// ROLES ON ACCOUNT SCHEMA
/////////////////////////////////////////

export const RolesOnAccountSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  roleId: z.string(),
})

export type RolesOnAccount = z.infer<typeof RolesOnAccountSchema>

export default RolesOnAccountSchema;
