// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentIncludeSchema } from '../inputTypeSchemas/ReportedContentIncludeSchema'
import { ReportedContentWhereUniqueInputSchema } from '../inputTypeSchemas/ReportedContentWhereUniqueInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ReportedContentSelectSchema: z.ZodType<Prisma.ReportedContentSelect> = z.object({
  id: z.boolean().optional(),
  reason: z.boolean().optional(),
  accountId: z.boolean().optional(),
  postId: z.boolean().optional(),
  commentId: z.boolean().optional(),
  wasChecked: z.boolean().optional(),
  reportedById: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  reportedBy: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const ReportedContentDeleteArgsSchema: z.ZodType<Prisma.ReportedContentDeleteArgs> = z.object({
  select: ReportedContentSelectSchema.optional(),
  include: ReportedContentIncludeSchema.optional(),
  where: ReportedContentWhereUniqueInputSchema,
}).strict() ;

export default ReportedContentDeleteArgsSchema;
