// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';

export const EnumDomainActivationStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumDomainActivationStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => DomainActivationStatusSchema).optional()
}).strict();

export default EnumDomainActivationStatusFieldUpdateOperationsInputSchema;
