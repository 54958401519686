// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteWhereUniqueInputSchema } from './SiteWhereUniqueInputSchema';
import { SiteUpdateWithoutAccountInputSchema } from './SiteUpdateWithoutAccountInputSchema';
import { SiteUncheckedUpdateWithoutAccountInputSchema } from './SiteUncheckedUpdateWithoutAccountInputSchema';

export const SiteUpdateWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.SiteUpdateWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => SiteWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SiteUpdateWithoutAccountInputSchema),z.lazy(() => SiteUncheckedUpdateWithoutAccountInputSchema) ]),
}).strict();

export default SiteUpdateWithWhereUniqueWithoutAccountInputSchema;
