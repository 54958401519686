// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';

export const RolesOnAccountUpdateManyMutationInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export default RolesOnAccountUpdateManyMutationInputSchema;
