// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SiteScalarWhereInputSchema } from './SiteScalarWhereInputSchema';
import { SiteUpdateManyMutationInputSchema } from './SiteUpdateManyMutationInputSchema';
import { SiteUncheckedUpdateManyWithoutAccountInputSchema } from './SiteUncheckedUpdateManyWithoutAccountInputSchema';

export const SiteUpdateManyWithWhereWithoutAccountInputSchema: z.ZodType<Prisma.SiteUpdateManyWithWhereWithoutAccountInput> = z.object({
  where: z.lazy(() => SiteScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SiteUpdateManyMutationInputSchema),z.lazy(() => SiteUncheckedUpdateManyWithoutAccountInputSchema) ]),
}).strict();

export default SiteUpdateManyWithWhereWithoutAccountInputSchema;
