// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountScalarWhereInputSchema } from './AccountScalarWhereInputSchema';
import { AccountUpdateManyMutationInputSchema } from './AccountUpdateManyMutationInputSchema';
import { AccountUncheckedUpdateManyWithoutOwnerInputSchema } from './AccountUncheckedUpdateManyWithoutOwnerInputSchema';

export const AccountUpdateManyWithWhereWithoutOwnerInputSchema: z.ZodType<Prisma.AccountUpdateManyWithWhereWithoutOwnerInput> = z.object({
  where: z.lazy(() => AccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateManyMutationInputSchema),z.lazy(() => AccountUncheckedUpdateManyWithoutOwnerInputSchema) ]),
}).strict();

export default AccountUpdateManyWithWhereWithoutOwnerInputSchema;
