// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutSitesInputSchema } from './AccountCreateWithoutSitesInputSchema';
import { AccountUncheckedCreateWithoutSitesInputSchema } from './AccountUncheckedCreateWithoutSitesInputSchema';

export const AccountCreateOrConnectWithoutSitesInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutSitesInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSitesInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutSitesInputSchema;
