// @ts-nocheck
import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { ACCOUNT_VISIBILITYSchema } from '../inputTypeSchemas/ACCOUNT_VISIBILITYSchema'
import { LANGSchema } from '../inputTypeSchemas/LANGSchema'

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  /**
   * ////////////////
   * ////////////////
   */
  visibility: ACCOUNT_VISIBILITYSchema,
  language: LANGSchema.nullable(),
  /**
   * //////////////
   * //////////////
   */
  id: z.string(),
  firebaseId: z.string().nullable(),
  wordpressId: z.number().int(),
  wooCommerceTermId: z.number().int(),
  stripeCustomerId: z.string(),
  slug: z.string(),
  email: z.string(),
  type: z.string().nullable(),
  current_company_name: z.string().nullable(),
  current_position_name: z.string().nullable(),
  description: z.string().nullable(),
  plan: z.string(),
  /**
   * //////////////////
   * //////////////////
   */
  logo: z.string().nullable(),
  profilePicture: z.string().nullable(),
  backgroundImage: z.string().nullable(),
  timeZone: z.string().nullable(),
  quickBio: z.string().nullable(),
  about: z.string().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  displayName: z.string().nullable(),
  title: z.string().nullable(),
  birthDate: z.string().nullable(),
  prescriptionLogo: z.string().nullable(),
  prescriptionSignature: z.string().nullable(),
  medicalSchoolNumber: z.string().nullable(),
  branches: z.string().nullable(),
  workers: z.string().nullable(),
  mainService: z.string().nullable(),
  otherServices: z.string().nullable(),
  color: z.string().nullable(),
  website: z.string().nullable(),
  categories: JsonValueSchema.nullable(),
  categoriesSlugs: JsonValueSchema.nullable(),
  defaultProductId: z.number().int().nullable(),
  rating: z.number().int().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  sex: z.string().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  mainMenuId: z.number().int().nullable(),
  landingPageId: z.number().int().nullable(),
  isHighlightedAccount: z.boolean().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  postal_code: z.string().nullable(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  region: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  nationalIdentityNumber: z.string().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  marketingConsent: z.boolean().nullable(),
  termsConsent: z.boolean().nullable(),
  ownerId: z.string().nullable(),
  /**
   * //////////////////
   * //////////////////
   */
  created: z.coerce.date(),
  deleted: z.coerce.date().nullable(),
  updated: z.coerce.date(),
})

export type Account = z.infer<typeof AccountSchema>

export default AccountSchema;
