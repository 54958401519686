// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateWithoutRoleInputSchema } from './RolesOnCollaborationCreateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema';
import { RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema } from './RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema';
import { RolesOnCollaborationCreateManyRoleInputEnvelopeSchema } from './RolesOnCollaborationCreateManyRoleInputEnvelopeSchema';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';

export const RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema).array(),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnCollaborationCreateManyRoleInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default RolesOnCollaborationUncheckedCreateNestedManyWithoutRoleInputSchema;
