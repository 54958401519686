// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// COLLABORATION SCHEMA
/////////////////////////////////////////

export const CollaborationSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  showInSearch: z.boolean(),
  collaboratorId: z.string(),
})

export type Collaboration = z.infer<typeof CollaborationSchema>

export default CollaborationSchema;
