// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUpdateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema';
import { AccountCreateWithoutInitiatedCollaborationsInputSchema } from './AccountCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutInitiatedCollaborationsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutInitiatedCollaborationsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutInitiatedCollaborationsInputSchema;
