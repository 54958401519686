// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesWhereUniqueInputSchema } from '../inputTypeSchemas/ExamImagesWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ExamImagesSelectSchema: z.ZodType<Prisma.ExamImagesSelect> = z.object({
  id: z.boolean().optional(),
  bodyArea: z.boolean().optional(),
  bodyPart: z.boolean().optional(),
  code: z.boolean().optional(),
  name_es: z.boolean().optional(),
  symptom: z.boolean().optional(),
  type: z.boolean().optional(),
}).strict()

export const ExamImagesFindUniqueArgsSchema: z.ZodType<Prisma.ExamImagesFindUniqueArgs> = z.object({
  select: ExamImagesSelectSchema.optional(),
  where: ExamImagesWhereUniqueInputSchema,
}).strict() ;

export default ExamImagesFindUniqueArgsSchema;
