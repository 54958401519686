// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabWhereInputSchema } from '../inputTypeSchemas/ExamLabWhereInputSchema'
import { ExamLabOrderByWithRelationInputSchema } from '../inputTypeSchemas/ExamLabOrderByWithRelationInputSchema'
import { ExamLabWhereUniqueInputSchema } from '../inputTypeSchemas/ExamLabWhereUniqueInputSchema'

export const ExamLabAggregateArgsSchema: z.ZodType<Prisma.ExamLabAggregateArgs> = z.object({
  where: ExamLabWhereInputSchema.optional(),
  orderBy: z.union([ ExamLabOrderByWithRelationInputSchema.array(),ExamLabOrderByWithRelationInputSchema ]).optional(),
  cursor: ExamLabWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ExamLabAggregateArgsSchema;
