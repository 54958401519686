// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensWhereInputSchema } from './PushTokensWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { AccountRelationFilterSchema } from './AccountRelationFilterSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const PushTokensWhereUniqueInputSchema: z.ZodType<Prisma.PushTokensWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    pushToken: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    pushToken: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  pushToken: z.string().optional(),
  AND: z.union([ z.lazy(() => PushTokensWhereInputSchema),z.lazy(() => PushTokensWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PushTokensWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PushTokensWhereInputSchema),z.lazy(() => PushTokensWhereInputSchema).array() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  account: z.union([ z.lazy(() => AccountRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional(),
}).strict());

export default PushTokensWhereUniqueInputSchema;
