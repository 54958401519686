// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { ExamImagesCountOrderByAggregateInputSchema } from './ExamImagesCountOrderByAggregateInputSchema';
import { ExamImagesMaxOrderByAggregateInputSchema } from './ExamImagesMaxOrderByAggregateInputSchema';
import { ExamImagesMinOrderByAggregateInputSchema } from './ExamImagesMinOrderByAggregateInputSchema';

export const ExamImagesOrderByWithAggregationInputSchema: z.ZodType<Prisma.ExamImagesOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bodyArea: z.lazy(() => SortOrderSchema).optional(),
  bodyPart: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name_es: z.lazy(() => SortOrderSchema).optional(),
  symptom: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ExamImagesCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ExamImagesMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ExamImagesMinOrderByAggregateInputSchema).optional()
}).strict();

export default ExamImagesOrderByWithAggregationInputSchema;
