// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutOwnerInputSchema } from './AccountCreateWithoutOwnerInputSchema';
import { AccountUncheckedCreateWithoutOwnerInputSchema } from './AccountUncheckedCreateWithoutOwnerInputSchema';
import { AccountCreateOrConnectWithoutOwnerInputSchema } from './AccountCreateOrConnectWithoutOwnerInputSchema';
import { AccountCreateManyOwnerInputEnvelopeSchema } from './AccountCreateManyOwnerInputEnvelopeSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedManyWithoutOwnerInputSchema: z.ZodType<Prisma.AccountCreateNestedManyWithoutOwnerInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnerInputSchema),z.lazy(() => AccountCreateWithoutOwnerInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutOwnerInputSchema),z.lazy(() => AccountCreateOrConnectWithoutOwnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyOwnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default AccountCreateNestedManyWithoutOwnerInputSchema;
