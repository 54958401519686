// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationWhereInputSchema } from '../inputTypeSchemas/CollaborationWhereInputSchema'

export const CollaborationDeleteManyArgsSchema: z.ZodType<Prisma.CollaborationDeleteManyArgs> = z.object({
  where: CollaborationWhereInputSchema.optional(),
}).strict() ;

export default CollaborationDeleteManyArgsSchema;
