// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { EnumDomainActivationStatusFilterSchema } from './EnumDomainActivationStatusFilterSchema';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';

export const SiteScalarWhereInputSchema: z.ZodType<Prisma.SiteScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SiteScalarWhereInputSchema),z.lazy(() => SiteScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SiteScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SiteScalarWhereInputSchema),z.lazy(() => SiteScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  domain: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumDomainActivationStatusFilterSchema),z.lazy(() => DomainActivationStatusSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default SiteScalarWhereInputSchema;
