// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpCreateInputSchema } from '../inputTypeSchemas/OtpCreateInputSchema'
import { OtpUncheckedCreateInputSchema } from '../inputTypeSchemas/OtpUncheckedCreateInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const OtpSelectSchema: z.ZodType<Prisma.OtpSelect> = z.object({
  id: z.boolean().optional(),
  email: z.boolean().optional(),
  password: z.boolean().optional(),
  exp: z.boolean().optional(),
}).strict()

export const OtpCreateArgsSchema: z.ZodType<Prisma.OtpCreateArgs> = z.object({
  select: OtpSelectSchema.optional(),
  data: z.union([ OtpCreateInputSchema,OtpUncheckedCreateInputSchema ]),
}).strict() ;

export default OtpCreateArgsSchema;
