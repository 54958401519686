// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { CollaborationCountOrderByAggregateInputSchema } from './CollaborationCountOrderByAggregateInputSchema';
import { CollaborationMaxOrderByAggregateInputSchema } from './CollaborationMaxOrderByAggregateInputSchema';
import { CollaborationMinOrderByAggregateInputSchema } from './CollaborationMinOrderByAggregateInputSchema';

export const CollaborationOrderByWithAggregationInputSchema: z.ZodType<Prisma.CollaborationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  showInSearch: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CollaborationCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CollaborationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CollaborationMinOrderByAggregateInputSchema).optional()
}).strict();

export default CollaborationOrderByWithAggregationInputSchema;
