// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesWhereInputSchema } from '../inputTypeSchemas/ExamImagesWhereInputSchema'
import { ExamImagesOrderByWithAggregationInputSchema } from '../inputTypeSchemas/ExamImagesOrderByWithAggregationInputSchema'
import { ExamImagesScalarFieldEnumSchema } from '../inputTypeSchemas/ExamImagesScalarFieldEnumSchema'
import { ExamImagesScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/ExamImagesScalarWhereWithAggregatesInputSchema'

export const ExamImagesGroupByArgsSchema: z.ZodType<Prisma.ExamImagesGroupByArgs> = z.object({
  where: ExamImagesWhereInputSchema.optional(),
  orderBy: z.union([ ExamImagesOrderByWithAggregationInputSchema.array(),ExamImagesOrderByWithAggregationInputSchema ]).optional(),
  by: ExamImagesScalarFieldEnumSchema.array(),
  having: ExamImagesScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ExamImagesGroupByArgsSchema;
