// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountUpdateManyMutationInputSchema } from '../inputTypeSchemas/RolesOnAccountUpdateManyMutationInputSchema'
import { RolesOnAccountUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/RolesOnAccountUncheckedUpdateManyInputSchema'
import { RolesOnAccountWhereInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereInputSchema'

export const RolesOnAccountUpdateManyArgsSchema: z.ZodType<Prisma.RolesOnAccountUpdateManyArgs> = z.object({
  data: z.union([ RolesOnAccountUpdateManyMutationInputSchema,RolesOnAccountUncheckedUpdateManyInputSchema ]),
  where: RolesOnAccountWhereInputSchema.optional(),
}).strict() ;

export default RolesOnAccountUpdateManyArgsSchema;
