// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationIncludeSchema } from '../inputTypeSchemas/CollaborationInvitationIncludeSchema'
import { CollaborationInvitationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereUniqueInputSchema'
import { CollaborationInvitationCreateInputSchema } from '../inputTypeSchemas/CollaborationInvitationCreateInputSchema'
import { CollaborationInvitationUncheckedCreateInputSchema } from '../inputTypeSchemas/CollaborationInvitationUncheckedCreateInputSchema'
import { CollaborationInvitationUpdateInputSchema } from '../inputTypeSchemas/CollaborationInvitationUpdateInputSchema'
import { CollaborationInvitationUncheckedUpdateInputSchema } from '../inputTypeSchemas/CollaborationInvitationUncheckedUpdateInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const CollaborationInvitationSelectSchema: z.ZodType<Prisma.CollaborationInvitationSelect> = z.object({
  id: z.boolean().optional(),
  inviteeId: z.boolean().optional(),
  inviteeEmail: z.boolean().optional(),
  status: z.boolean().optional(),
  accountId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  invitee: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const CollaborationInvitationUpsertArgsSchema: z.ZodType<Prisma.CollaborationInvitationUpsertArgs> = z.object({
  select: CollaborationInvitationSelectSchema.optional(),
  include: CollaborationInvitationIncludeSchema.optional(),
  where: CollaborationInvitationWhereUniqueInputSchema,
  create: z.union([ CollaborationInvitationCreateInputSchema,CollaborationInvitationUncheckedCreateInputSchema ]),
  update: z.union([ CollaborationInvitationUpdateInputSchema,CollaborationInvitationUncheckedUpdateInputSchema ]),
}).strict() ;

export default CollaborationInvitationUpsertArgsSchema;
