// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteWhereInputSchema } from '../inputTypeSchemas/SiteWhereInputSchema'
import { SiteOrderByWithRelationInputSchema } from '../inputTypeSchemas/SiteOrderByWithRelationInputSchema'
import { SiteWhereUniqueInputSchema } from '../inputTypeSchemas/SiteWhereUniqueInputSchema'

export const SiteAggregateArgsSchema: z.ZodType<Prisma.SiteAggregateArgs> = z.object({
  where: SiteWhereInputSchema.optional(),
  orderBy: z.union([ SiteOrderByWithRelationInputSchema.array(),SiteOrderByWithRelationInputSchema ]).optional(),
  cursor: SiteWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default SiteAggregateArgsSchema;
