// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationCreateWithoutInviteeInputSchema } from './CollaborationInvitationCreateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema';

export const CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateOrConnectWithoutInviteeInput> = z.object({
  where: z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema) ]),
}).strict();

export default CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema;
