// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationIncludeSchema } from '../inputTypeSchemas/CollaborationInvitationIncludeSchema'
import { CollaborationInvitationWhereInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereInputSchema'
import { CollaborationInvitationOrderByWithRelationInputSchema } from '../inputTypeSchemas/CollaborationInvitationOrderByWithRelationInputSchema'
import { CollaborationInvitationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereUniqueInputSchema'
import { CollaborationInvitationScalarFieldEnumSchema } from '../inputTypeSchemas/CollaborationInvitationScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const CollaborationInvitationSelectSchema: z.ZodType<Prisma.CollaborationInvitationSelect> = z.object({
  id: z.boolean().optional(),
  inviteeId: z.boolean().optional(),
  inviteeEmail: z.boolean().optional(),
  status: z.boolean().optional(),
  accountId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  invitee: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const CollaborationInvitationFindManyArgsSchema: z.ZodType<Prisma.CollaborationInvitationFindManyArgs> = z.object({
  select: CollaborationInvitationSelectSchema.optional(),
  include: CollaborationInvitationIncludeSchema.optional(),
  where: CollaborationInvitationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationInvitationOrderByWithRelationInputSchema.array(),CollaborationInvitationOrderByWithRelationInputSchema ]).optional(),
  cursor: CollaborationInvitationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CollaborationInvitationScalarFieldEnumSchema,CollaborationInvitationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default CollaborationInvitationFindManyArgsSchema;
