// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereInputSchema } from './RolesOnCollaborationWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { CollaborationRelationFilterSchema } from './CollaborationRelationFilterSchema';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';
import { RoleRelationFilterSchema } from './RoleRelationFilterSchema';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';

export const RolesOnCollaborationWhereUniqueInputSchema: z.ZodType<Prisma.RolesOnCollaborationWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => RolesOnCollaborationWhereInputSchema),z.lazy(() => RolesOnCollaborationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RolesOnCollaborationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RolesOnCollaborationWhereInputSchema),z.lazy(() => RolesOnCollaborationWhereInputSchema).array() ]).optional(),
  collaborationId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  roleId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboration: z.union([ z.lazy(() => CollaborationRelationFilterSchema),z.lazy(() => CollaborationWhereInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleRelationFilterSchema),z.lazy(() => RoleWhereInputSchema) ]).optional(),
}).strict());

export default RolesOnCollaborationWhereUniqueInputSchema;
