// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutEventsInputSchema } from './AccountUpdateWithoutEventsInputSchema';
import { AccountUncheckedUpdateWithoutEventsInputSchema } from './AccountUncheckedUpdateWithoutEventsInputSchema';

export const AccountUpdateToOneWithWhereWithoutEventsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutEventsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutEventsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutEventsInputSchema;
