// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabWhereUniqueInputSchema } from '../inputTypeSchemas/ExamLabWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ExamLabSelectSchema: z.ZodType<Prisma.ExamLabSelect> = z.object({
  id: z.boolean().optional(),
  name_es: z.boolean().optional(),
  category: z.boolean().optional(),
}).strict()

export const ExamLabDeleteArgsSchema: z.ZodType<Prisma.ExamLabDeleteArgs> = z.object({
  select: ExamLabSelectSchema.optional(),
  where: ExamLabWhereUniqueInputSchema,
}).strict() ;

export default ExamLabDeleteArgsSchema;
