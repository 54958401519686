// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentWhereInputSchema } from '../inputTypeSchemas/ReportedContentWhereInputSchema'
import { ReportedContentOrderByWithRelationInputSchema } from '../inputTypeSchemas/ReportedContentOrderByWithRelationInputSchema'
import { ReportedContentWhereUniqueInputSchema } from '../inputTypeSchemas/ReportedContentWhereUniqueInputSchema'

export const ReportedContentAggregateArgsSchema: z.ZodType<Prisma.ReportedContentAggregateArgs> = z.object({
  where: ReportedContentWhereInputSchema.optional(),
  orderBy: z.union([ ReportedContentOrderByWithRelationInputSchema.array(),ReportedContentOrderByWithRelationInputSchema ]).optional(),
  cursor: ReportedContentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ReportedContentAggregateArgsSchema;
