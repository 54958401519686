// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const RolesOnAccountUncheckedCreateWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountUncheckedCreateWithoutRoleInput> = z.object({
  id: z.string().optional(),
  accountId: z.string()
}).strict();

export default RolesOnAccountUncheckedCreateWithoutRoleInputSchema;
