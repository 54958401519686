// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationIncludeSchema } from '../inputTypeSchemas/CollaborationIncludeSchema'
import { CollaborationWhereInputSchema } from '../inputTypeSchemas/CollaborationWhereInputSchema'
import { CollaborationOrderByWithRelationInputSchema } from '../inputTypeSchemas/CollaborationOrderByWithRelationInputSchema'
import { CollaborationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationWhereUniqueInputSchema'
import { CollaborationScalarFieldEnumSchema } from '../inputTypeSchemas/CollaborationScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RolesOnCollaborationFindManyArgsSchema } from "../outputTypeSchemas/RolesOnCollaborationFindManyArgsSchema"
import { CollaborationCountOutputTypeArgsSchema } from "../outputTypeSchemas/CollaborationCountOutputTypeArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const CollaborationSelectSchema: z.ZodType<Prisma.CollaborationSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  showInSearch: z.boolean().optional(),
  collaboratorId: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  roles: z.union([z.boolean(),z.lazy(() => RolesOnCollaborationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CollaborationCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CollaborationFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CollaborationFindFirstOrThrowArgs> = z.object({
  select: CollaborationSelectSchema.optional(),
  include: CollaborationIncludeSchema.optional(),
  where: CollaborationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationOrderByWithRelationInputSchema.array(),CollaborationOrderByWithRelationInputSchema ]).optional(),
  cursor: CollaborationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CollaborationScalarFieldEnumSchema,CollaborationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default CollaborationFindFirstOrThrowArgsSchema;
