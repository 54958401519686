// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"

export const EventIncludeSchema: z.ZodType<Prisma.EventInclude> = z.object({
  organization: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export default EventIncludeSchema;
