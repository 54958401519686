// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutReportedInputSchema } from './AccountCreateWithoutReportedInputSchema';
import { AccountUncheckedCreateWithoutReportedInputSchema } from './AccountUncheckedCreateWithoutReportedInputSchema';

export const AccountCreateOrConnectWithoutReportedInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutReportedInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReportedInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutReportedInputSchema;
