// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { AccountOrderByWithRelationInputSchema } from './AccountOrderByWithRelationInputSchema';
import { EventOrderByRelevanceInputSchema } from './EventOrderByRelevanceInputSchema';

export const EventOrderByWithRelationInputSchema: z.ZodType<Prisma.EventOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  subtitle: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  organization: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => EventOrderByRelevanceInputSchema).optional()
}).strict();

export default EventOrderByWithRelationInputSchema;
