// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { BoolFieldUpdateOperationsInputSchema } from './BoolFieldUpdateOperationsInputSchema';
import { AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema } from './AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema';
import { AccountUpdateOneRequiredWithoutCollaborationsNestedInputSchema } from './AccountUpdateOneRequiredWithoutCollaborationsNestedInputSchema';

export const CollaborationUpdateWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationUpdateWithoutRolesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  showInSearch: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  account: z.lazy(() => AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema).optional(),
  collaborator: z.lazy(() => AccountUpdateOneRequiredWithoutCollaborationsNestedInputSchema).optional()
}).strict();

export default CollaborationUpdateWithoutRolesInputSchema;
