// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentWhereUniqueInputSchema } from './ReportedContentWhereUniqueInputSchema';
import { ReportedContentUpdateWithoutReportedByInputSchema } from './ReportedContentUpdateWithoutReportedByInputSchema';
import { ReportedContentUncheckedUpdateWithoutReportedByInputSchema } from './ReportedContentUncheckedUpdateWithoutReportedByInputSchema';
import { ReportedContentCreateWithoutReportedByInputSchema } from './ReportedContentCreateWithoutReportedByInputSchema';
import { ReportedContentUncheckedCreateWithoutReportedByInputSchema } from './ReportedContentUncheckedCreateWithoutReportedByInputSchema';

export const ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema: z.ZodType<Prisma.ReportedContentUpsertWithWhereUniqueWithoutReportedByInput> = z.object({
  where: z.lazy(() => ReportedContentWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ReportedContentUpdateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedUpdateWithoutReportedByInputSchema) ]),
  create: z.union([ z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema) ]),
}).strict();

export default ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema;
