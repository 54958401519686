// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutRolesInputSchema } from './AccountUpdateWithoutRolesInputSchema';
import { AccountUncheckedUpdateWithoutRolesInputSchema } from './AccountUncheckedUpdateWithoutRolesInputSchema';
import { AccountCreateWithoutRolesInputSchema } from './AccountCreateWithoutRolesInputSchema';
import { AccountUncheckedCreateWithoutRolesInputSchema } from './AccountUncheckedCreateWithoutRolesInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutRolesInputSchema: z.ZodType<Prisma.AccountUpsertWithoutRolesInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutRolesInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutRolesInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutRolesInputSchema;
