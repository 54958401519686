// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationCreateWithoutRolesInputSchema } from './CollaborationCreateWithoutRolesInputSchema';
import { CollaborationUncheckedCreateWithoutRolesInputSchema } from './CollaborationUncheckedCreateWithoutRolesInputSchema';

export const CollaborationCreateOrConnectWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationCreateOrConnectWithoutRolesInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutRolesInputSchema) ]),
}).strict();

export default CollaborationCreateOrConnectWithoutRolesInputSchema;
