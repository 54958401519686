// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensCreateWithoutAccountInputSchema } from './PushTokensCreateWithoutAccountInputSchema';
import { PushTokensUncheckedCreateWithoutAccountInputSchema } from './PushTokensUncheckedCreateWithoutAccountInputSchema';
import { PushTokensCreateOrConnectWithoutAccountInputSchema } from './PushTokensCreateOrConnectWithoutAccountInputSchema';
import { PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema } from './PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema';
import { PushTokensCreateManyAccountInputEnvelopeSchema } from './PushTokensCreateManyAccountInputEnvelopeSchema';
import { PushTokensWhereUniqueInputSchema } from './PushTokensWhereUniqueInputSchema';
import { PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema } from './PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema';
import { PushTokensUpdateManyWithWhereWithoutAccountInputSchema } from './PushTokensUpdateManyWithWhereWithoutAccountInputSchema';
import { PushTokensScalarWhereInputSchema } from './PushTokensScalarWhereInputSchema';

export const PushTokensUncheckedUpdateManyWithoutAccountNestedInputSchema: z.ZodType<Prisma.PushTokensUncheckedUpdateManyWithoutAccountNestedInput> = z.object({
  create: z.union([ z.lazy(() => PushTokensCreateWithoutAccountInputSchema),z.lazy(() => PushTokensCreateWithoutAccountInputSchema).array(),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PushTokensCreateOrConnectWithoutAccountInputSchema),z.lazy(() => PushTokensCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => PushTokensUpsertWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PushTokensCreateManyAccountInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PushTokensWhereUniqueInputSchema),z.lazy(() => PushTokensWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PushTokensWhereUniqueInputSchema),z.lazy(() => PushTokensWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PushTokensWhereUniqueInputSchema),z.lazy(() => PushTokensWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PushTokensWhereUniqueInputSchema),z.lazy(() => PushTokensWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PushTokensUpdateManyWithWhereWithoutAccountInputSchema),z.lazy(() => PushTokensUpdateManyWithWhereWithoutAccountInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PushTokensScalarWhereInputSchema),z.lazy(() => PushTokensScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default PushTokensUncheckedUpdateManyWithoutAccountNestedInputSchema;
