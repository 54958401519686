// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereInputSchema } from './RolesOnCollaborationWhereInputSchema';

export const RolesOnCollaborationListRelationFilterSchema: z.ZodType<Prisma.RolesOnCollaborationListRelationFilter> = z.object({
  every: z.lazy(() => RolesOnCollaborationWhereInputSchema).optional(),
  some: z.lazy(() => RolesOnCollaborationWhereInputSchema).optional(),
  none: z.lazy(() => RolesOnCollaborationWhereInputSchema).optional()
}).strict();

export default RolesOnCollaborationListRelationFilterSchema;
