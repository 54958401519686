// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventCreateManyOrganizationInputSchema } from './EventCreateManyOrganizationInputSchema';

export const EventCreateManyOrganizationInputEnvelopeSchema: z.ZodType<Prisma.EventCreateManyOrganizationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => EventCreateManyOrganizationInputSchema),z.lazy(() => EventCreateManyOrganizationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default EventCreateManyOrganizationInputEnvelopeSchema;
