// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateWithoutAccountInputSchema } from './RolesOnAccountCreateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedCreateWithoutAccountInputSchema } from './RolesOnAccountUncheckedCreateWithoutAccountInputSchema';
import { RolesOnAccountCreateOrConnectWithoutAccountInputSchema } from './RolesOnAccountCreateOrConnectWithoutAccountInputSchema';
import { RolesOnAccountCreateManyAccountInputEnvelopeSchema } from './RolesOnAccountCreateManyAccountInputEnvelopeSchema';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';

export const RolesOnAccountUncheckedCreateNestedManyWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountUncheckedCreateNestedManyWithoutAccountInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema).array(),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnAccountCreateOrConnectWithoutAccountInputSchema),z.lazy(() => RolesOnAccountCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnAccountCreateManyAccountInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default RolesOnAccountUncheckedCreateNestedManyWithoutAccountInputSchema;
