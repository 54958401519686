// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensScalarWhereInputSchema } from './PushTokensScalarWhereInputSchema';
import { PushTokensUpdateManyMutationInputSchema } from './PushTokensUpdateManyMutationInputSchema';
import { PushTokensUncheckedUpdateManyWithoutAccountInputSchema } from './PushTokensUncheckedUpdateManyWithoutAccountInputSchema';

export const PushTokensUpdateManyWithWhereWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensUpdateManyWithWhereWithoutAccountInput> = z.object({
  where: z.lazy(() => PushTokensScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PushTokensUpdateManyMutationInputSchema),z.lazy(() => PushTokensUncheckedUpdateManyWithoutAccountInputSchema) ]),
}).strict();

export default PushTokensUpdateManyWithWhereWithoutAccountInputSchema;
