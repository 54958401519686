// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationWhereInputSchema } from '../inputTypeSchemas/CollaborationWhereInputSchema'
import { CollaborationOrderByWithRelationInputSchema } from '../inputTypeSchemas/CollaborationOrderByWithRelationInputSchema'
import { CollaborationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationWhereUniqueInputSchema'

export const CollaborationAggregateArgsSchema: z.ZodType<Prisma.CollaborationAggregateArgs> = z.object({
  where: CollaborationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationOrderByWithRelationInputSchema.array(),CollaborationOrderByWithRelationInputSchema ]).optional(),
  cursor: CollaborationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default CollaborationAggregateArgsSchema;
