// @ts-nocheck
import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'

/////////////////////////////////////////
// ISP PRODUCT SCHEMA
/////////////////////////////////////////

export const IspProductSchema = z.object({
  id: z.string(),
  record: z.string(),
  name: z.string(),
  transactionReference: z.string(),
  therapeuticEquivalenceOrBiosimilar: z.string(),
  holder: z.string(),
  recordStatus: z.string(),
  registrationResolution: z.string(),
  registrationDate: z.string(),
  lastRenewal: z.string(),
  nextRenewalDate: z.string(),
  regime: z.string(),
  administrationRoute: z.string(),
  salesCondition: z.string(),
  establishmentType: z.string(),
  indication: z.string(),
  packagings: JsonValueSchema,
  companyFunctions: JsonValueSchema,
  formulas: JsonValueSchema,
  cenabast: JsonValueSchema.nullable(),
})

export type IspProduct = z.infer<typeof IspProductSchema>

export default IspProductSchema;
