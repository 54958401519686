// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { CollaborationInvitationCountOrderByAggregateInputSchema } from './CollaborationInvitationCountOrderByAggregateInputSchema';
import { CollaborationInvitationMaxOrderByAggregateInputSchema } from './CollaborationInvitationMaxOrderByAggregateInputSchema';
import { CollaborationInvitationMinOrderByAggregateInputSchema } from './CollaborationInvitationMinOrderByAggregateInputSchema';

export const CollaborationInvitationOrderByWithAggregationInputSchema: z.ZodType<Prisma.CollaborationInvitationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  inviteeId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  inviteeEmail: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CollaborationInvitationCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CollaborationInvitationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CollaborationInvitationMinOrderByAggregateInputSchema).optional()
}).strict();

export default CollaborationInvitationOrderByWithAggregationInputSchema;
