// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutEventsInputSchema } from './AccountCreateWithoutEventsInputSchema';
import { AccountUncheckedCreateWithoutEventsInputSchema } from './AccountUncheckedCreateWithoutEventsInputSchema';

export const AccountCreateOrConnectWithoutEventsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutEventsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutEventsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutEventsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutEventsInputSchema;
