// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountIncludeSchema } from '../inputTypeSchemas/RolesOnAccountIncludeSchema'
import { RolesOnAccountWhereUniqueInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereUniqueInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const RolesOnAccountSelectSchema: z.ZodType<Prisma.RolesOnAccountSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export const RolesOnAccountFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.RolesOnAccountFindUniqueOrThrowArgs> = z.object({
  select: RolesOnAccountSelectSchema.optional(),
  include: RolesOnAccountIncludeSchema.optional(),
  where: RolesOnAccountWhereUniqueInputSchema,
}).strict() ;

export default RolesOnAccountFindUniqueOrThrowArgsSchema;
