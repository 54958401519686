// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema';
import { AccountUpsertWithoutSentCollaborationInvitationsInputSchema } from './AccountUpsertWithoutSentCollaborationInvitationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInputSchema } from './AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInputSchema';
import { AccountUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUpdateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema';

export const AccountUpdateOneRequiredWithoutSentCollaborationInvitationsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutSentCollaborationInvitationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutSentCollaborationInvitationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUpdateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutSentCollaborationInvitationsNestedInputSchema;
