// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationWhereInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereInputSchema'
import { CollaborationInvitationOrderByWithRelationInputSchema } from '../inputTypeSchemas/CollaborationInvitationOrderByWithRelationInputSchema'
import { CollaborationInvitationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereUniqueInputSchema'

export const CollaborationInvitationAggregateArgsSchema: z.ZodType<Prisma.CollaborationInvitationAggregateArgs> = z.object({
  where: CollaborationInvitationWhereInputSchema.optional(),
  orderBy: z.union([ CollaborationInvitationOrderByWithRelationInputSchema.array(),CollaborationInvitationOrderByWithRelationInputSchema ]).optional(),
  cursor: CollaborationInvitationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default CollaborationInvitationAggregateArgsSchema;
