// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { ProductExamsOrderByRelevanceInputSchema } from './ProductExamsOrderByRelevanceInputSchema';

export const ProductExamsOrderByWithRelationInputSchema: z.ZodType<Prisma.ProductExamsOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  wooCommerceProductId: z.lazy(() => SortOrderSchema).optional(),
  images: z.lazy(() => SortOrderSchema).optional(),
  labs: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  doctorAccountId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _relevance: z.lazy(() => ProductExamsOrderByRelevanceInputSchema).optional()
}).strict();

export default ProductExamsOrderByWithRelationInputSchema;
