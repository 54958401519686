// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';

export const NestedEnumDomainActivationStatusFilterSchema: z.ZodType<Prisma.NestedEnumDomainActivationStatusFilter> = z.object({
  equals: z.lazy(() => DomainActivationStatusSchema).optional(),
  in: z.lazy(() => DomainActivationStatusSchema).array().optional(),
  notIn: z.lazy(() => DomainActivationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DomainActivationStatusSchema),z.lazy(() => NestedEnumDomainActivationStatusFilterSchema) ]).optional(),
}).strict();

export default NestedEnumDomainActivationStatusFilterSchema;
