// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountCreateWithoutAccountInputSchema } from './RolesOnAccountCreateWithoutAccountInputSchema';
import { RolesOnAccountUncheckedCreateWithoutAccountInputSchema } from './RolesOnAccountUncheckedCreateWithoutAccountInputSchema';

export const RolesOnAccountCreateOrConnectWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountCreateOrConnectWithoutAccountInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutAccountInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default RolesOnAccountCreateOrConnectWithoutAccountInputSchema;
