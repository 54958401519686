// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// EXAM IMAGES SCHEMA
/////////////////////////////////////////

export const ExamImagesSchema = z.object({
  id: z.string(),
  bodyArea: z.string(),
  bodyPart: z.string(),
  code: z.string(),
  name_es: z.string(),
  symptom: z.string(),
  type: z.string(),
})

export type ExamImages = z.infer<typeof ExamImagesSchema>

export default ExamImagesSchema;
