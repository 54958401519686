// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateWithoutRoleInputSchema } from './RolesOnAccountCreateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedCreateWithoutRoleInputSchema } from './RolesOnAccountUncheckedCreateWithoutRoleInputSchema';
import { RolesOnAccountCreateOrConnectWithoutRoleInputSchema } from './RolesOnAccountCreateOrConnectWithoutRoleInputSchema';
import { RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema } from './RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema';
import { RolesOnAccountCreateManyRoleInputEnvelopeSchema } from './RolesOnAccountCreateManyRoleInputEnvelopeSchema';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema } from './RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema';
import { RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema } from './RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema';
import { RolesOnAccountScalarWhereInputSchema } from './RolesOnAccountScalarWhereInputSchema';

export const RolesOnAccountUncheckedUpdateManyWithoutRoleNestedInputSchema: z.ZodType<Prisma.RolesOnAccountUncheckedUpdateManyWithoutRoleNestedInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema).array(),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnAccountCreateOrConnectWithoutRoleInputSchema),z.lazy(() => RolesOnAccountCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnAccountCreateManyRoleInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUpdateWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RolesOnAccountScalarWhereInputSchema),z.lazy(() => RolesOnAccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default RolesOnAccountUncheckedUpdateManyWithoutRoleNestedInputSchema;
