// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const ExamImagesCreateInputSchema: z.ZodType<Prisma.ExamImagesCreateInput> = z.object({
  id: z.string().optional(),
  bodyArea: z.string(),
  bodyPart: z.string(),
  code: z.string(),
  name_es: z.string(),
  symptom: z.string(),
  type: z.string()
}).strict();

export default ExamImagesCreateInputSchema;
