// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationCreateWithoutAccountInputSchema } from './CollaborationInvitationCreateWithoutAccountInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutAccountInputSchema } from './CollaborationInvitationUncheckedCreateWithoutAccountInputSchema';
import { CollaborationInvitationCreateOrConnectWithoutAccountInputSchema } from './CollaborationInvitationCreateOrConnectWithoutAccountInputSchema';
import { CollaborationInvitationCreateManyAccountInputEnvelopeSchema } from './CollaborationInvitationCreateManyAccountInputEnvelopeSchema';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';

export const CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationCreateWithoutAccountInputSchema).array(),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationInvitationCreateOrConnectWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationInvitationCreateManyAccountInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInputSchema;
