// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutSitesInputSchema } from './AccountCreateWithoutSitesInputSchema';
import { AccountUncheckedCreateWithoutSitesInputSchema } from './AccountUncheckedCreateWithoutSitesInputSchema';
import { AccountCreateOrConnectWithoutSitesInputSchema } from './AccountCreateOrConnectWithoutSitesInputSchema';
import { AccountUpsertWithoutSitesInputSchema } from './AccountUpsertWithoutSitesInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutSitesInputSchema } from './AccountUpdateToOneWithWhereWithoutSitesInputSchema';
import { AccountUpdateWithoutSitesInputSchema } from './AccountUpdateWithoutSitesInputSchema';
import { AccountUncheckedUpdateWithoutSitesInputSchema } from './AccountUncheckedUpdateWithoutSitesInputSchema';

export const AccountUpdateOneRequiredWithoutSitesNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutSitesNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSitesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutSitesInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutSitesInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutSitesInputSchema),z.lazy(() => AccountUpdateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSitesInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutSitesNestedInputSchema;
