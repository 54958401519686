// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';

export const EnumACCOUNT_VISIBILITYFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumACCOUNT_VISIBILITYFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ACCOUNT_VISIBILITYSchema).optional()
}).strict();

export default EnumACCOUNT_VISIBILITYFieldUpdateOperationsInputSchema;
