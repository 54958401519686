// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationScalarWhereInputSchema } from './CollaborationInvitationScalarWhereInputSchema';
import { CollaborationInvitationUpdateManyMutationInputSchema } from './CollaborationInvitationUpdateManyMutationInputSchema';
import { CollaborationInvitationUncheckedUpdateManyWithoutAccountInputSchema } from './CollaborationInvitationUncheckedUpdateManyWithoutAccountInputSchema';

export const CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateManyWithWhereWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationInvitationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CollaborationInvitationUpdateManyMutationInputSchema),z.lazy(() => CollaborationInvitationUncheckedUpdateManyWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationInvitationUpdateManyWithWhereWithoutAccountInputSchema;
