// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';
import { RoleCreateWithoutAccountsInputSchema } from './RoleCreateWithoutAccountsInputSchema';
import { RoleUncheckedCreateWithoutAccountsInputSchema } from './RoleUncheckedCreateWithoutAccountsInputSchema';

export const RoleCreateOrConnectWithoutAccountsInputSchema: z.ZodType<Prisma.RoleCreateOrConnectWithoutAccountsInput> = z.object({
  where: z.lazy(() => RoleWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RoleCreateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutAccountsInputSchema) ]),
}).strict();

export default RoleCreateOrConnectWithoutAccountsInputSchema;
