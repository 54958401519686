// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutSitesInputSchema } from './AccountCreateWithoutSitesInputSchema';
import { AccountUncheckedCreateWithoutSitesInputSchema } from './AccountUncheckedCreateWithoutSitesInputSchema';
import { AccountCreateOrConnectWithoutSitesInputSchema } from './AccountCreateOrConnectWithoutSitesInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutSitesInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutSitesInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutSitesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSitesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutSitesInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutSitesInputSchema;
