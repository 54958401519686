// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { ProductExamsCountOrderByAggregateInputSchema } from './ProductExamsCountOrderByAggregateInputSchema';
import { ProductExamsAvgOrderByAggregateInputSchema } from './ProductExamsAvgOrderByAggregateInputSchema';
import { ProductExamsMaxOrderByAggregateInputSchema } from './ProductExamsMaxOrderByAggregateInputSchema';
import { ProductExamsMinOrderByAggregateInputSchema } from './ProductExamsMinOrderByAggregateInputSchema';
import { ProductExamsSumOrderByAggregateInputSchema } from './ProductExamsSumOrderByAggregateInputSchema';

export const ProductExamsOrderByWithAggregationInputSchema: z.ZodType<Prisma.ProductExamsOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  wooCommerceProductId: z.lazy(() => SortOrderSchema).optional(),
  images: z.lazy(() => SortOrderSchema).optional(),
  labs: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  doctorAccountId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => ProductExamsCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ProductExamsAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ProductExamsMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ProductExamsMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ProductExamsSumOrderByAggregateInputSchema).optional()
}).strict();

export default ProductExamsOrderByWithAggregationInputSchema;
