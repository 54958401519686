// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountWhereInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereInputSchema'
import { RolesOnAccountOrderByWithAggregationInputSchema } from '../inputTypeSchemas/RolesOnAccountOrderByWithAggregationInputSchema'
import { RolesOnAccountScalarFieldEnumSchema } from '../inputTypeSchemas/RolesOnAccountScalarFieldEnumSchema'
import { RolesOnAccountScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/RolesOnAccountScalarWhereWithAggregatesInputSchema'

export const RolesOnAccountGroupByArgsSchema: z.ZodType<Prisma.RolesOnAccountGroupByArgs> = z.object({
  where: RolesOnAccountWhereInputSchema.optional(),
  orderBy: z.union([ RolesOnAccountOrderByWithAggregationInputSchema.array(),RolesOnAccountOrderByWithAggregationInputSchema ]).optional(),
  by: RolesOnAccountScalarFieldEnumSchema.array(),
  having: RolesOnAccountScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default RolesOnAccountGroupByArgsSchema;
