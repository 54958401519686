// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutInitiatedCollaborationsInputSchema } from './AccountCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema } from './AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutInitiatedCollaborationsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema;
