// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutOwnedAccountsInputSchema } from './AccountCreateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedCreateWithoutOwnedAccountsInputSchema } from './AccountUncheckedCreateWithoutOwnedAccountsInputSchema';
import { AccountCreateOrConnectWithoutOwnedAccountsInputSchema } from './AccountCreateOrConnectWithoutOwnedAccountsInputSchema';
import { AccountUpsertWithoutOwnedAccountsInputSchema } from './AccountUpsertWithoutOwnedAccountsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutOwnedAccountsInputSchema } from './AccountUpdateToOneWithWhereWithoutOwnedAccountsInputSchema';
import { AccountUpdateWithoutOwnedAccountsInputSchema } from './AccountUpdateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedUpdateWithoutOwnedAccountsInputSchema } from './AccountUncheckedUpdateWithoutOwnedAccountsInputSchema';

export const AccountUpdateOneWithoutOwnedAccountsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneWithoutOwnedAccountsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnedAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutOwnedAccountsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutOwnedAccountsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUpdateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutOwnedAccountsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneWithoutOwnedAccountsNestedInputSchema;
