// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"

export const CollaborationInvitationIncludeSchema: z.ZodType<Prisma.CollaborationInvitationInclude> = z.object({
  invitee: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export default CollaborationInvitationIncludeSchema;
