// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const RolesOnCollaborationCountOrderByAggregateInputSchema: z.ZodType<Prisma.RolesOnCollaborationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  collaborationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default RolesOnCollaborationCountOrderByAggregateInputSchema;
