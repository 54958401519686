// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateWithoutRoleInputSchema } from './RolesOnAccountCreateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedCreateWithoutRoleInputSchema } from './RolesOnAccountUncheckedCreateWithoutRoleInputSchema';
import { RolesOnAccountCreateOrConnectWithoutRoleInputSchema } from './RolesOnAccountCreateOrConnectWithoutRoleInputSchema';
import { RolesOnAccountCreateManyRoleInputEnvelopeSchema } from './RolesOnAccountCreateManyRoleInputEnvelopeSchema';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';

export const RolesOnAccountCreateNestedManyWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountCreateNestedManyWithoutRoleInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema).array(),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnAccountCreateOrConnectWithoutRoleInputSchema),z.lazy(() => RolesOnAccountCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnAccountCreateManyRoleInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RolesOnAccountWhereUniqueInputSchema),z.lazy(() => RolesOnAccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default RolesOnAccountCreateNestedManyWithoutRoleInputSchema;
