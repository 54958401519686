// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema } from './CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema';
import { RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema } from './RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema';

export const RolesOnCollaborationUpdateInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboration: z.lazy(() => CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema).optional()
}).strict();

export default RolesOnCollaborationUpdateInputSchema;
