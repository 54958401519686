// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutOwnerInputSchema } from './AccountCreateWithoutOwnerInputSchema';
import { AccountUncheckedCreateWithoutOwnerInputSchema } from './AccountUncheckedCreateWithoutOwnerInputSchema';
import { AccountCreateOrConnectWithoutOwnerInputSchema } from './AccountCreateOrConnectWithoutOwnerInputSchema';
import { AccountUpsertWithWhereUniqueWithoutOwnerInputSchema } from './AccountUpsertWithWhereUniqueWithoutOwnerInputSchema';
import { AccountCreateManyOwnerInputEnvelopeSchema } from './AccountCreateManyOwnerInputEnvelopeSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateWithWhereUniqueWithoutOwnerInputSchema } from './AccountUpdateWithWhereUniqueWithoutOwnerInputSchema';
import { AccountUpdateManyWithWhereWithoutOwnerInputSchema } from './AccountUpdateManyWithWhereWithoutOwnerInputSchema';
import { AccountScalarWhereInputSchema } from './AccountScalarWhereInputSchema';

export const AccountUncheckedUpdateManyWithoutOwnerNestedInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutOwnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnerInputSchema),z.lazy(() => AccountCreateWithoutOwnerInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutOwnerInputSchema),z.lazy(() => AccountCreateOrConnectWithoutOwnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutOwnerInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutOwnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyOwnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutOwnerInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutOwnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutOwnerInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutOwnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default AccountUncheckedUpdateManyWithoutOwnerNestedInputSchema;
