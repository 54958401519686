// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsUpdateManyMutationInputSchema } from '../inputTypeSchemas/ProductExamsUpdateManyMutationInputSchema'
import { ProductExamsUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/ProductExamsUncheckedUpdateManyInputSchema'
import { ProductExamsWhereInputSchema } from '../inputTypeSchemas/ProductExamsWhereInputSchema'

export const ProductExamsUpdateManyArgsSchema: z.ZodType<Prisma.ProductExamsUpdateManyArgs> = z.object({
  data: z.union([ ProductExamsUpdateManyMutationInputSchema,ProductExamsUncheckedUpdateManyInputSchema ]),
  where: ProductExamsWhereInputSchema.optional(),
}).strict() ;

export default ProductExamsUpdateManyArgsSchema;
