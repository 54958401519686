// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationCreateWithoutInviteeInputSchema } from './CollaborationInvitationCreateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema';
import { CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema } from './CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema';
import { CollaborationInvitationCreateManyInviteeInputEnvelopeSchema } from './CollaborationInvitationCreateManyInviteeInputEnvelopeSchema';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';

export const CollaborationInvitationCreateNestedManyWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateNestedManyWithoutInviteeInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationCreateWithoutInviteeInputSchema).array(),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutInviteeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationCreateOrConnectWithoutInviteeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationInvitationCreateManyInviteeInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),z.lazy(() => CollaborationInvitationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default CollaborationInvitationCreateNestedManyWithoutInviteeInputSchema;
