// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensCreateManyInputSchema } from '../inputTypeSchemas/PushTokensCreateManyInputSchema'

export const PushTokensCreateManyArgsSchema: z.ZodType<Prisma.PushTokensCreateManyArgs> = z.object({
  data: z.union([ PushTokensCreateManyInputSchema,PushTokensCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default PushTokensCreateManyArgsSchema;
