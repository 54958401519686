// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { RolesOnAccountOrderByRelationAggregateInputSchema } from './RolesOnAccountOrderByRelationAggregateInputSchema';
import { RolesOnCollaborationOrderByRelationAggregateInputSchema } from './RolesOnCollaborationOrderByRelationAggregateInputSchema';
import { RoleOrderByRelevanceInputSchema } from './RoleOrderByRelevanceInputSchema';

export const RoleOrderByWithRelationInputSchema: z.ZodType<Prisma.RoleOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  accounts: z.lazy(() => RolesOnAccountOrderByRelationAggregateInputSchema).optional(),
  collaborations: z.lazy(() => RolesOnCollaborationOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => RoleOrderByRelevanceInputSchema).optional()
}).strict();

export default RoleOrderByWithRelationInputSchema;
