// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithoutRoleInputSchema } from './RolesOnCollaborationUpdateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema';
import { RolesOnCollaborationCreateWithoutRoleInputSchema } from './RolesOnCollaborationCreateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema';

export const RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateWithoutRoleInputSchema) ]),
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpsertWithWhereUniqueWithoutRoleInputSchema;
