// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabCreateManyInputSchema } from '../inputTypeSchemas/ExamLabCreateManyInputSchema'

export const ExamLabCreateManyArgsSchema: z.ZodType<Prisma.ExamLabCreateManyArgs> = z.object({
  data: z.union([ ExamLabCreateManyInputSchema,ExamLabCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default ExamLabCreateManyArgsSchema;
