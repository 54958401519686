// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { JsonNullValueInputSchema } from './JsonNullValueInputSchema';
import { InputJsonValueSchema } from './InputJsonValueSchema';

export const ProductExamsCreateInputSchema: z.ZodType<Prisma.ProductExamsCreateInput> = z.object({
  id: z.string().optional(),
  wooCommerceProductId: z.number().int(),
  images: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  labs: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  accountId: z.string(),
  doctorAccountId: z.string().optional().nullable()
}).strict();

export default ProductExamsCreateInputSchema;
