// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// ROLE SCHEMA
/////////////////////////////////////////

export const RoleSchema = z.object({
  id: z.string(),
  description: z.string(),
  name: z.string(),
  /**
   * /////////////////////////////////////
   * /////////////////////////////////////
   */
  created: z.coerce.date(),
  deleted: z.coerce.date().nullable(),
  updated: z.coerce.date(),
})

export type Role = z.infer<typeof RoleSchema>

export default RoleSchema;
