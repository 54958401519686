// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoUpdateManyMutationInputSchema } from '../inputTypeSchemas/ColegioMedicoUpdateManyMutationInputSchema'
import { ColegioMedicoUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/ColegioMedicoUncheckedUpdateManyInputSchema'
import { ColegioMedicoWhereInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereInputSchema'

export const ColegioMedicoUpdateManyArgsSchema: z.ZodType<Prisma.ColegioMedicoUpdateManyArgs> = z.object({
  data: z.union([ ColegioMedicoUpdateManyMutationInputSchema,ColegioMedicoUncheckedUpdateManyInputSchema ]),
  where: ColegioMedicoWhereInputSchema.optional(),
}).strict() ;

export default ColegioMedicoUpdateManyArgsSchema;
