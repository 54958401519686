// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ProductExamsOrderByRelevanceFieldEnumSchema } from './ProductExamsOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const ProductExamsOrderByRelevanceInputSchema: z.ZodType<Prisma.ProductExamsOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ProductExamsOrderByRelevanceFieldEnumSchema),z.lazy(() => ProductExamsOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default ProductExamsOrderByRelevanceInputSchema;
