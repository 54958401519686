// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateWithoutCollaborationsInputSchema } from './RoleCreateWithoutCollaborationsInputSchema';
import { RoleUncheckedCreateWithoutCollaborationsInputSchema } from './RoleUncheckedCreateWithoutCollaborationsInputSchema';
import { RoleCreateOrConnectWithoutCollaborationsInputSchema } from './RoleCreateOrConnectWithoutCollaborationsInputSchema';
import { RoleUpsertWithoutCollaborationsInputSchema } from './RoleUpsertWithoutCollaborationsInputSchema';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';
import { RoleUpdateToOneWithWhereWithoutCollaborationsInputSchema } from './RoleUpdateToOneWithWhereWithoutCollaborationsInputSchema';
import { RoleUpdateWithoutCollaborationsInputSchema } from './RoleUpdateWithoutCollaborationsInputSchema';
import { RoleUncheckedUpdateWithoutCollaborationsInputSchema } from './RoleUncheckedUpdateWithoutCollaborationsInputSchema';

export const RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema: z.ZodType<Prisma.RoleUpdateOneRequiredWithoutCollaborationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutCollaborationsInputSchema).optional(),
  upsert: z.lazy(() => RoleUpsertWithoutCollaborationsInputSchema).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => RoleUpdateToOneWithWhereWithoutCollaborationsInputSchema),z.lazy(() => RoleUpdateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutCollaborationsInputSchema) ]).optional(),
}).strict();

export default RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema;
