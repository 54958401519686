// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema';
import { RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema } from './RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';

export const RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema).array(),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default RolesOnCollaborationUncheckedCreateNestedManyWithoutCollaborationInputSchema;
