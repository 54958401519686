// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { SiteCountOrderByAggregateInputSchema } from './SiteCountOrderByAggregateInputSchema';
import { SiteMaxOrderByAggregateInputSchema } from './SiteMaxOrderByAggregateInputSchema';
import { SiteMinOrderByAggregateInputSchema } from './SiteMinOrderByAggregateInputSchema';

export const SiteOrderByWithAggregationInputSchema: z.ZodType<Prisma.SiteOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  domain: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SiteCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SiteMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SiteMinOrderByAggregateInputSchema).optional()
}).strict();

export default SiteOrderByWithAggregationInputSchema;
