// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteCreateManyInputSchema } from '../inputTypeSchemas/SiteCreateManyInputSchema'

export const SiteCreateManyArgsSchema: z.ZodType<Prisma.SiteCreateManyArgs> = z.object({
  data: z.union([ SiteCreateManyInputSchema,SiteCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default SiteCreateManyArgsSchema;
