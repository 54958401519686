// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensUpdateManyMutationInputSchema } from '../inputTypeSchemas/PushTokensUpdateManyMutationInputSchema'
import { PushTokensUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/PushTokensUncheckedUpdateManyInputSchema'
import { PushTokensWhereInputSchema } from '../inputTypeSchemas/PushTokensWhereInputSchema'

export const PushTokensUpdateManyArgsSchema: z.ZodType<Prisma.PushTokensUpdateManyArgs> = z.object({
  data: z.union([ PushTokensUpdateManyMutationInputSchema,PushTokensUncheckedUpdateManyInputSchema ]),
  where: PushTokensWhereInputSchema.optional(),
}).strict() ;

export default PushTokensUpdateManyArgsSchema;
