// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereUniqueInputSchema } from './RolesOnAccountWhereUniqueInputSchema';
import { RolesOnAccountUpdateWithoutRoleInputSchema } from './RolesOnAccountUpdateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedUpdateWithoutRoleInputSchema } from './RolesOnAccountUncheckedUpdateWithoutRoleInputSchema';
import { RolesOnAccountCreateWithoutRoleInputSchema } from './RolesOnAccountCreateWithoutRoleInputSchema';
import { RolesOnAccountUncheckedCreateWithoutRoleInputSchema } from './RolesOnAccountUncheckedCreateWithoutRoleInputSchema';

export const RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountUpsertWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnAccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RolesOnAccountUpdateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateWithoutRoleInputSchema) ]),
  create: z.union([ z.lazy(() => RolesOnAccountCreateWithoutRoleInputSchema),z.lazy(() => RolesOnAccountUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnAccountUpsertWithWhereUniqueWithoutRoleInputSchema;
