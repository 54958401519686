// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationCreateManyInputSchema } from '../inputTypeSchemas/CollaborationCreateManyInputSchema'

export const CollaborationCreateManyArgsSchema: z.ZodType<Prisma.CollaborationCreateManyArgs> = z.object({
  data: z.union([ CollaborationCreateManyInputSchema,CollaborationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default CollaborationCreateManyArgsSchema;
