// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationScalarWhereInputSchema } from './CollaborationScalarWhereInputSchema';
import { CollaborationUpdateManyMutationInputSchema } from './CollaborationUpdateManyMutationInputSchema';
import { CollaborationUncheckedUpdateManyWithoutAccountInputSchema } from './CollaborationUncheckedUpdateManyWithoutAccountInputSchema';

export const CollaborationUpdateManyWithWhereWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationUpdateManyWithWhereWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CollaborationUpdateManyMutationInputSchema),z.lazy(() => CollaborationUncheckedUpdateManyWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationUpdateManyWithWhereWithoutAccountInputSchema;
