// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { OtpCountOrderByAggregateInputSchema } from './OtpCountOrderByAggregateInputSchema';
import { OtpMaxOrderByAggregateInputSchema } from './OtpMaxOrderByAggregateInputSchema';
import { OtpMinOrderByAggregateInputSchema } from './OtpMinOrderByAggregateInputSchema';

export const OtpOrderByWithAggregationInputSchema: z.ZodType<Prisma.OtpOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  exp: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => OtpCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => OtpMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => OtpMinOrderByAggregateInputSchema).optional()
}).strict();

export default OtpOrderByWithAggregationInputSchema;
