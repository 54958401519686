// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountIncludeSchema } from '../inputTypeSchemas/RolesOnAccountIncludeSchema'
import { RolesOnAccountWhereInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereInputSchema'
import { RolesOnAccountOrderByWithRelationInputSchema } from '../inputTypeSchemas/RolesOnAccountOrderByWithRelationInputSchema'
import { RolesOnAccountWhereUniqueInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereUniqueInputSchema'
import { RolesOnAccountScalarFieldEnumSchema } from '../inputTypeSchemas/RolesOnAccountScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const RolesOnAccountSelectSchema: z.ZodType<Prisma.RolesOnAccountSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export const RolesOnAccountFindFirstOrThrowArgsSchema: z.ZodType<Prisma.RolesOnAccountFindFirstOrThrowArgs> = z.object({
  select: RolesOnAccountSelectSchema.optional(),
  include: RolesOnAccountIncludeSchema.optional(),
  where: RolesOnAccountWhereInputSchema.optional(),
  orderBy: z.union([ RolesOnAccountOrderByWithRelationInputSchema.array(),RolesOnAccountOrderByWithRelationInputSchema ]).optional(),
  cursor: RolesOnAccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RolesOnAccountScalarFieldEnumSchema,RolesOnAccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default RolesOnAccountFindFirstOrThrowArgsSchema;
