// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const ColegioMedicoCreateInputSchema: z.ZodType<Prisma.ColegioMedicoCreateInput> = z.object({
  id: z.string().optional(),
  icm: z.string(),
  names: z.string(),
  lastNames: z.string(),
  afiliation: z.string(),
  city: z.string()
}).strict();

export default ColegioMedicoCreateInputSchema;
