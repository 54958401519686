// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';

export const CollaborationInvitationUncheckedCreateWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationUncheckedCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  inviteeId: z.string().optional().nullable(),
  inviteeEmail: z.string(),
  status: z.lazy(() => INVITATION_STATUSSchema).optional(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional()
}).strict();

export default CollaborationInvitationUncheckedCreateWithoutAccountInputSchema;
