// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithoutAccountInputSchema } from './CollaborationUpdateWithoutAccountInputSchema';
import { CollaborationUncheckedUpdateWithoutAccountInputSchema } from './CollaborationUncheckedUpdateWithoutAccountInputSchema';
import { CollaborationCreateWithoutAccountInputSchema } from './CollaborationCreateWithoutAccountInputSchema';
import { CollaborationUncheckedCreateWithoutAccountInputSchema } from './CollaborationUncheckedCreateWithoutAccountInputSchema';

export const CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationUpsertWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CollaborationUpdateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutAccountInputSchema) ]),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema;
