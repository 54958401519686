// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { IspProductOrderByRelevanceFieldEnumSchema } from './IspProductOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const IspProductOrderByRelevanceInputSchema: z.ZodType<Prisma.IspProductOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => IspProductOrderByRelevanceFieldEnumSchema),z.lazy(() => IspProductOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default IspProductOrderByRelevanceInputSchema;
