// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { LANGSchema } from './LANGSchema';

export const NullableEnumLANGFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumLANGFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => LANGSchema).optional().nullable()
}).strict();

export default NullableEnumLANGFieldUpdateOperationsInputSchema;
