// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateWithoutAccountsInputSchema } from './RoleCreateWithoutAccountsInputSchema';
import { RoleUncheckedCreateWithoutAccountsInputSchema } from './RoleUncheckedCreateWithoutAccountsInputSchema';
import { RoleCreateOrConnectWithoutAccountsInputSchema } from './RoleCreateOrConnectWithoutAccountsInputSchema';
import { RoleUpsertWithoutAccountsInputSchema } from './RoleUpsertWithoutAccountsInputSchema';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';
import { RoleUpdateToOneWithWhereWithoutAccountsInputSchema } from './RoleUpdateToOneWithWhereWithoutAccountsInputSchema';
import { RoleUpdateWithoutAccountsInputSchema } from './RoleUpdateWithoutAccountsInputSchema';
import { RoleUncheckedUpdateWithoutAccountsInputSchema } from './RoleUncheckedUpdateWithoutAccountsInputSchema';

export const RoleUpdateOneRequiredWithoutAccountsNestedInputSchema: z.ZodType<Prisma.RoleUpdateOneRequiredWithoutAccountsNestedInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutAccountsInputSchema).optional(),
  upsert: z.lazy(() => RoleUpsertWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => RoleUpdateToOneWithWhereWithoutAccountsInputSchema),z.lazy(() => RoleUpdateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutAccountsInputSchema) ]).optional(),
}).strict();

export default RoleUpdateOneRequiredWithoutAccountsNestedInputSchema;
