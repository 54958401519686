// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutRolesInputSchema } from './AccountCreateWithoutRolesInputSchema';
import { AccountUncheckedCreateWithoutRolesInputSchema } from './AccountUncheckedCreateWithoutRolesInputSchema';
import { AccountCreateOrConnectWithoutRolesInputSchema } from './AccountCreateOrConnectWithoutRolesInputSchema';
import { AccountUpsertWithoutRolesInputSchema } from './AccountUpsertWithoutRolesInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutRolesInputSchema } from './AccountUpdateToOneWithWhereWithoutRolesInputSchema';
import { AccountUpdateWithoutRolesInputSchema } from './AccountUpdateWithoutRolesInputSchema';
import { AccountUncheckedUpdateWithoutRolesInputSchema } from './AccountUncheckedUpdateWithoutRolesInputSchema';

export const AccountUpdateOneRequiredWithoutRolesNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutRolesNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedCreateWithoutRolesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutRolesInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutRolesInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutRolesInputSchema),z.lazy(() => AccountUpdateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutRolesInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutRolesNestedInputSchema;
