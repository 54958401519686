// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateNestedManyWithoutRoleInputSchema } from './RolesOnAccountCreateNestedManyWithoutRoleInputSchema';

export const RoleCreateWithoutCollaborationsInputSchema: z.ZodType<Prisma.RoleCreateWithoutCollaborationsInput> = z.object({
  id: z.string().optional(),
  description: z.string(),
  name: z.string(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  accounts: z.lazy(() => RolesOnAccountCreateNestedManyWithoutRoleInputSchema).optional()
}).strict();

export default RoleCreateWithoutCollaborationsInputSchema;
