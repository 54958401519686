// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutRolesInputSchema } from './CollaborationCreateWithoutRolesInputSchema';
import { CollaborationUncheckedCreateWithoutRolesInputSchema } from './CollaborationUncheckedCreateWithoutRolesInputSchema';
import { CollaborationCreateOrConnectWithoutRolesInputSchema } from './CollaborationCreateOrConnectWithoutRolesInputSchema';
import { CollaborationUpsertWithoutRolesInputSchema } from './CollaborationUpsertWithoutRolesInputSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateToOneWithWhereWithoutRolesInputSchema } from './CollaborationUpdateToOneWithWhereWithoutRolesInputSchema';
import { CollaborationUpdateWithoutRolesInputSchema } from './CollaborationUpdateWithoutRolesInputSchema';
import { CollaborationUncheckedUpdateWithoutRolesInputSchema } from './CollaborationUncheckedUpdateWithoutRolesInputSchema';

export const CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema: z.ZodType<Prisma.CollaborationUpdateOneRequiredWithoutRolesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutRolesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CollaborationCreateOrConnectWithoutRolesInputSchema).optional(),
  upsert: z.lazy(() => CollaborationUpsertWithoutRolesInputSchema).optional(),
  connect: z.lazy(() => CollaborationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CollaborationUpdateToOneWithWhereWithoutRolesInputSchema),z.lazy(() => CollaborationUpdateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutRolesInputSchema) ]).optional(),
}).strict();

export default CollaborationUpdateOneRequiredWithoutRolesNestedInputSchema;
