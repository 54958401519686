// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationCreateWithoutAccountInputSchema } from './CollaborationInvitationCreateWithoutAccountInputSchema';
import { CollaborationInvitationUncheckedCreateWithoutAccountInputSchema } from './CollaborationInvitationUncheckedCreateWithoutAccountInputSchema';

export const CollaborationInvitationCreateOrConnectWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateOrConnectWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CollaborationInvitationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationInvitationCreateOrConnectWithoutAccountInputSchema;
