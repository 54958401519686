// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const ReportedContentUncheckedCreateInputSchema: z.ZodType<Prisma.ReportedContentUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  reason: z.string(),
  accountId: z.string().optional().nullable(),
  postId: z.number().int().optional().nullable(),
  commentId: z.number().int().optional().nullable(),
  wasChecked: z.boolean().optional(),
  reportedById: z.string(),
  created: z.coerce.date().optional(),
  updated: z.coerce.date().optional()
}).strict();

export default ReportedContentUncheckedCreateInputSchema;
