// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const OtpCreateInputSchema: z.ZodType<Prisma.OtpCreateInput> = z.object({
  id: z.string().optional(),
  email: z.string(),
  password: z.string(),
  exp: z.coerce.date()
}).strict();

export default OtpCreateInputSchema;
