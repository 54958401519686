// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabWhereInputSchema } from '../inputTypeSchemas/ExamLabWhereInputSchema'

export const ExamLabDeleteManyArgsSchema: z.ZodType<Prisma.ExamLabDeleteManyArgs> = z.object({
  where: ExamLabWhereInputSchema.optional(),
}).strict() ;

export default ExamLabDeleteManyArgsSchema;
