// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductWhereInputSchema } from '../inputTypeSchemas/IspProductWhereInputSchema'
import { IspProductOrderByWithRelationInputSchema } from '../inputTypeSchemas/IspProductOrderByWithRelationInputSchema'
import { IspProductWhereUniqueInputSchema } from '../inputTypeSchemas/IspProductWhereUniqueInputSchema'

export const IspProductAggregateArgsSchema: z.ZodType<Prisma.IspProductAggregateArgs> = z.object({
  where: IspProductWhereInputSchema.optional(),
  orderBy: z.union([ IspProductOrderByWithRelationInputSchema.array(),IspProductOrderByWithRelationInputSchema ]).optional(),
  cursor: IspProductWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default IspProductAggregateArgsSchema;
