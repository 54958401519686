// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithoutCollaborationInputSchema } from './RolesOnCollaborationUpdateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema';

export const RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedUpdateWithoutCollaborationInputSchema) ]),
}).strict();

export default RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema;
