// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';

export const ExamImagesScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ExamImagesScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ExamImagesScalarWhereWithAggregatesInputSchema),z.lazy(() => ExamImagesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ExamImagesScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ExamImagesScalarWhereWithAggregatesInputSchema),z.lazy(() => ExamImagesScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bodyArea: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bodyPart: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name_es: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  symptom: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default ExamImagesScalarWhereWithAggregatesInputSchema;
