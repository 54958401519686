// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationSelectSchema } from '../inputTypeSchemas/RolesOnCollaborationSelectSchema';
import { RolesOnCollaborationIncludeSchema } from '../inputTypeSchemas/RolesOnCollaborationIncludeSchema';

export const RolesOnCollaborationArgsSchema: z.ZodType<Prisma.RolesOnCollaborationDefaultArgs> = z.object({
  select: z.lazy(() => RolesOnCollaborationSelectSchema).optional(),
  include: z.lazy(() => RolesOnCollaborationIncludeSchema).optional(),
}).strict();

export default RolesOnCollaborationArgsSchema;
