// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteUpdateManyMutationInputSchema } from '../inputTypeSchemas/SiteUpdateManyMutationInputSchema'
import { SiteUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/SiteUncheckedUpdateManyInputSchema'
import { SiteWhereInputSchema } from '../inputTypeSchemas/SiteWhereInputSchema'

export const SiteUpdateManyArgsSchema: z.ZodType<Prisma.SiteUpdateManyArgs> = z.object({
  data: z.union([ SiteUpdateManyMutationInputSchema,SiteUncheckedUpdateManyInputSchema ]),
  where: SiteWhereInputSchema.optional(),
}).strict() ;

export default SiteUpdateManyArgsSchema;
