// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUpdateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema';

export const AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutSentCollaborationInvitationsInputSchema;
