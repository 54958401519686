// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutReceivedCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutReceivedCollaborationInvitationsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutReceivedCollaborationInvitationsInputSchema;
