// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductCreateManyInputSchema } from '../inputTypeSchemas/IspProductCreateManyInputSchema'

export const IspProductCreateManyArgsSchema: z.ZodType<Prisma.IspProductCreateManyArgs> = z.object({
  data: z.union([ IspProductCreateManyInputSchema,IspProductCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default IspProductCreateManyArgsSchema;
