// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateWithoutCollaborationsInputSchema } from './RoleCreateWithoutCollaborationsInputSchema';
import { RoleUncheckedCreateWithoutCollaborationsInputSchema } from './RoleUncheckedCreateWithoutCollaborationsInputSchema';
import { RoleCreateOrConnectWithoutCollaborationsInputSchema } from './RoleCreateOrConnectWithoutCollaborationsInputSchema';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';

export const RoleCreateNestedOneWithoutCollaborationsInputSchema: z.ZodType<Prisma.RoleCreateNestedOneWithoutCollaborationsInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutCollaborationsInputSchema).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional()
}).strict();

export default RoleCreateNestedOneWithoutCollaborationsInputSchema;
