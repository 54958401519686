// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensWhereInputSchema } from './PushTokensWhereInputSchema';

export const PushTokensListRelationFilterSchema: z.ZodType<Prisma.PushTokensListRelationFilter> = z.object({
  every: z.lazy(() => PushTokensWhereInputSchema).optional(),
  some: z.lazy(() => PushTokensWhereInputSchema).optional(),
  none: z.lazy(() => PushTokensWhereInputSchema).optional()
}).strict();

export default PushTokensListRelationFilterSchema;
