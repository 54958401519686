// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsWhereUniqueInputSchema } from '../inputTypeSchemas/ProductExamsWhereUniqueInputSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ProductExamsSelectSchema: z.ZodType<Prisma.ProductExamsSelect> = z.object({
  id: z.boolean().optional(),
  wooCommerceProductId: z.boolean().optional(),
  images: z.boolean().optional(),
  labs: z.boolean().optional(),
  accountId: z.boolean().optional(),
  doctorAccountId: z.boolean().optional(),
}).strict()

export const ProductExamsFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ProductExamsFindUniqueOrThrowArgs> = z.object({
  select: ProductExamsSelectSchema.optional(),
  where: ProductExamsWhereUniqueInputSchema,
}).strict() ;

export default ProductExamsFindUniqueOrThrowArgsSchema;
