// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { AccountOrderByWithRelationInputSchema } from './AccountOrderByWithRelationInputSchema';
import { RoleOrderByWithRelationInputSchema } from './RoleOrderByWithRelationInputSchema';
import { RolesOnAccountOrderByRelevanceInputSchema } from './RolesOnAccountOrderByRelevanceInputSchema';

export const RolesOnAccountOrderByWithRelationInputSchema: z.ZodType<Prisma.RolesOnAccountOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  account: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  role: z.lazy(() => RoleOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => RolesOnAccountOrderByRelevanceInputSchema).optional()
}).strict();

export default RolesOnAccountOrderByWithRelationInputSchema;
