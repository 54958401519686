// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentIncludeSchema } from '../inputTypeSchemas/ReportedContentIncludeSchema'
import { ReportedContentWhereInputSchema } from '../inputTypeSchemas/ReportedContentWhereInputSchema'
import { ReportedContentOrderByWithRelationInputSchema } from '../inputTypeSchemas/ReportedContentOrderByWithRelationInputSchema'
import { ReportedContentWhereUniqueInputSchema } from '../inputTypeSchemas/ReportedContentWhereUniqueInputSchema'
import { ReportedContentScalarFieldEnumSchema } from '../inputTypeSchemas/ReportedContentScalarFieldEnumSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ReportedContentSelectSchema: z.ZodType<Prisma.ReportedContentSelect> = z.object({
  id: z.boolean().optional(),
  reason: z.boolean().optional(),
  accountId: z.boolean().optional(),
  postId: z.boolean().optional(),
  commentId: z.boolean().optional(),
  wasChecked: z.boolean().optional(),
  reportedById: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  reportedBy: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const ReportedContentFindFirstArgsSchema: z.ZodType<Prisma.ReportedContentFindFirstArgs> = z.object({
  select: ReportedContentSelectSchema.optional(),
  include: ReportedContentIncludeSchema.optional(),
  where: ReportedContentWhereInputSchema.optional(),
  orderBy: z.union([ ReportedContentOrderByWithRelationInputSchema.array(),ReportedContentOrderByWithRelationInputSchema ]).optional(),
  cursor: ReportedContentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ReportedContentScalarFieldEnumSchema,ReportedContentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default ReportedContentFindFirstArgsSchema;
