// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const ExamImagesMinOrderByAggregateInputSchema: z.ZodType<Prisma.ExamImagesMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bodyArea: z.lazy(() => SortOrderSchema).optional(),
  bodyPart: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name_es: z.lazy(() => SortOrderSchema).optional(),
  symptom: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default ExamImagesMinOrderByAggregateInputSchema;
