// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentWhereUniqueInputSchema } from './ReportedContentWhereUniqueInputSchema';
import { ReportedContentUpdateWithoutReportedByInputSchema } from './ReportedContentUpdateWithoutReportedByInputSchema';
import { ReportedContentUncheckedUpdateWithoutReportedByInputSchema } from './ReportedContentUncheckedUpdateWithoutReportedByInputSchema';

export const ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema: z.ZodType<Prisma.ReportedContentUpdateWithWhereUniqueWithoutReportedByInput> = z.object({
  where: z.lazy(() => ReportedContentWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ReportedContentUpdateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedUpdateWithoutReportedByInputSchema) ]),
}).strict();

export default ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema;
