// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutOwnedAccountsInputSchema } from './AccountUpdateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedUpdateWithoutOwnedAccountsInputSchema } from './AccountUncheckedUpdateWithoutOwnedAccountsInputSchema';
import { AccountCreateWithoutOwnedAccountsInputSchema } from './AccountCreateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedCreateWithoutOwnedAccountsInputSchema } from './AccountUncheckedCreateWithoutOwnedAccountsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutOwnedAccountsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutOwnedAccountsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutOwnedAccountsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnedAccountsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutOwnedAccountsInputSchema;
