// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';
import { NestedEnumACCOUNT_VISIBILITYWithAggregatesFilterSchema } from './NestedEnumACCOUNT_VISIBILITYWithAggregatesFilterSchema';
import { NestedIntFilterSchema } from './NestedIntFilterSchema';
import { NestedEnumACCOUNT_VISIBILITYFilterSchema } from './NestedEnumACCOUNT_VISIBILITYFilterSchema';

export const EnumACCOUNT_VISIBILITYWithAggregatesFilterSchema: z.ZodType<Prisma.EnumACCOUNT_VISIBILITYWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ACCOUNT_VISIBILITYSchema).optional(),
  in: z.lazy(() => ACCOUNT_VISIBILITYSchema).array().optional(),
  notIn: z.lazy(() => ACCOUNT_VISIBILITYSchema).array().optional(),
  not: z.union([ z.lazy(() => ACCOUNT_VISIBILITYSchema),z.lazy(() => NestedEnumACCOUNT_VISIBILITYWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumACCOUNT_VISIBILITYFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumACCOUNT_VISIBILITYFilterSchema).optional()
}).strict();

export default EnumACCOUNT_VISIBILITYWithAggregatesFilterSchema;
