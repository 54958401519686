// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema';

export const AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutSentCollaborationInvitationsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutSentCollaborationInvitationsInputSchema;
