// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsWhereInputSchema } from '../inputTypeSchemas/ProductExamsWhereInputSchema'
import { ProductExamsOrderByWithRelationInputSchema } from '../inputTypeSchemas/ProductExamsOrderByWithRelationInputSchema'
import { ProductExamsWhereUniqueInputSchema } from '../inputTypeSchemas/ProductExamsWhereUniqueInputSchema'
import { ProductExamsScalarFieldEnumSchema } from '../inputTypeSchemas/ProductExamsScalarFieldEnumSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ProductExamsSelectSchema: z.ZodType<Prisma.ProductExamsSelect> = z.object({
  id: z.boolean().optional(),
  wooCommerceProductId: z.boolean().optional(),
  images: z.boolean().optional(),
  labs: z.boolean().optional(),
  accountId: z.boolean().optional(),
  doctorAccountId: z.boolean().optional(),
}).strict()

export const ProductExamsFindFirstOrThrowArgsSchema: z.ZodType<Prisma.ProductExamsFindFirstOrThrowArgs> = z.object({
  select: ProductExamsSelectSchema.optional(),
  where: ProductExamsWhereInputSchema.optional(),
  orderBy: z.union([ ProductExamsOrderByWithRelationInputSchema.array(),ProductExamsOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductExamsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ProductExamsScalarFieldEnumSchema,ProductExamsScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default ProductExamsFindFirstOrThrowArgsSchema;
