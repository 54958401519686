// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// EXAM LAB SCHEMA
/////////////////////////////////////////

export const ExamLabSchema = z.object({
  id: z.string(),
  name_es: z.string(),
  category: z.string(),
})

export type ExamLab = z.infer<typeof ExamLabSchema>

export default ExamLabSchema;
