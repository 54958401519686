// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountFindManyArgsSchema } from "../outputTypeSchemas/RolesOnAccountFindManyArgsSchema"
import { RolesOnCollaborationFindManyArgsSchema } from "../outputTypeSchemas/RolesOnCollaborationFindManyArgsSchema"
import { RoleCountOutputTypeArgsSchema } from "../outputTypeSchemas/RoleCountOutputTypeArgsSchema"

export const RoleIncludeSchema: z.ZodType<Prisma.RoleInclude> = z.object({
  accounts: z.union([z.boolean(),z.lazy(() => RolesOnAccountFindManyArgsSchema)]).optional(),
  collaborations: z.union([z.boolean(),z.lazy(() => RolesOnCollaborationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => RoleCountOutputTypeArgsSchema)]).optional(),
}).strict()

export default RoleIncludeSchema;
