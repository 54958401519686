// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"

export const RolesOnAccountIncludeSchema: z.ZodType<Prisma.RolesOnAccountInclude> = z.object({
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export default RolesOnAccountIncludeSchema;
