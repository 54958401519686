// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventScalarWhereInputSchema } from './EventScalarWhereInputSchema';
import { EventUpdateManyMutationInputSchema } from './EventUpdateManyMutationInputSchema';
import { EventUncheckedUpdateManyWithoutOrganizationInputSchema } from './EventUncheckedUpdateManyWithoutOrganizationInputSchema';

export const EventUpdateManyWithWhereWithoutOrganizationInputSchema: z.ZodType<Prisma.EventUpdateManyWithWhereWithoutOrganizationInput> = z.object({
  where: z.lazy(() => EventScalarWhereInputSchema),
  data: z.union([ z.lazy(() => EventUpdateManyMutationInputSchema),z.lazy(() => EventUncheckedUpdateManyWithoutOrganizationInputSchema) ]),
}).strict();

export default EventUpdateManyWithWhereWithoutOrganizationInputSchema;
