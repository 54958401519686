// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema';

export const AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInputSchema;
