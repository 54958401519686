// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpWhereInputSchema } from '../inputTypeSchemas/OtpWhereInputSchema'
import { OtpOrderByWithRelationInputSchema } from '../inputTypeSchemas/OtpOrderByWithRelationInputSchema'
import { OtpWhereUniqueInputSchema } from '../inputTypeSchemas/OtpWhereUniqueInputSchema'

export const OtpAggregateArgsSchema: z.ZodType<Prisma.OtpAggregateArgs> = z.object({
  where: OtpWhereInputSchema.optional(),
  orderBy: z.union([ OtpOrderByWithRelationInputSchema.array(),OtpOrderByWithRelationInputSchema ]).optional(),
  cursor: OtpWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default OtpAggregateArgsSchema;
