// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpWhereInputSchema } from '../inputTypeSchemas/OtpWhereInputSchema'
import { OtpOrderByWithAggregationInputSchema } from '../inputTypeSchemas/OtpOrderByWithAggregationInputSchema'
import { OtpScalarFieldEnumSchema } from '../inputTypeSchemas/OtpScalarFieldEnumSchema'
import { OtpScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/OtpScalarWhereWithAggregatesInputSchema'

export const OtpGroupByArgsSchema: z.ZodType<Prisma.OtpGroupByArgs> = z.object({
  where: OtpWhereInputSchema.optional(),
  orderBy: z.union([ OtpOrderByWithAggregationInputSchema.array(),OtpOrderByWithAggregationInputSchema ]).optional(),
  by: OtpScalarFieldEnumSchema.array(),
  having: OtpScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default OtpGroupByArgsSchema;
