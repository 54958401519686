// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutPushTokensInputSchema } from './AccountCreateWithoutPushTokensInputSchema';
import { AccountUncheckedCreateWithoutPushTokensInputSchema } from './AccountUncheckedCreateWithoutPushTokensInputSchema';

export const AccountCreateOrConnectWithoutPushTokensInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutPushTokensInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutPushTokensInputSchema),z.lazy(() => AccountUncheckedCreateWithoutPushTokensInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutPushTokensInputSchema;
