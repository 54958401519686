// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUpsertWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUpsertWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema';

export const AccountUpdateOneWithoutReceivedCollaborationInvitationsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneWithoutReceivedCollaborationInvitationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutReceivedCollaborationInvitationsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => AccountWhereInputSchema) ]).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUpdateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutReceivedCollaborationInvitationsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneWithoutReceivedCollaborationInvitationsNestedInputSchema;
