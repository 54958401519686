// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { SortOrderInputSchema } from './SortOrderInputSchema';
import { AccountOrderByWithRelationInputSchema } from './AccountOrderByWithRelationInputSchema';
import { ReportedContentOrderByRelevanceInputSchema } from './ReportedContentOrderByRelevanceInputSchema';

export const ReportedContentOrderByWithRelationInputSchema: z.ZodType<Prisma.ReportedContentOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  postId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  commentId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  wasChecked: z.lazy(() => SortOrderSchema).optional(),
  reportedById: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  updated: z.lazy(() => SortOrderSchema).optional(),
  reportedBy: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => ReportedContentOrderByRelevanceInputSchema).optional()
}).strict();

export default ReportedContentOrderByWithRelationInputSchema;
