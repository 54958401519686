// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ProductExamsWhereInputSchema } from './ProductExamsWhereInputSchema';
import { JsonFilterSchema } from './JsonFilterSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';

export const ProductExamsWhereUniqueInputSchema: z.ZodType<Prisma.ProductExamsWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    wooCommerceProductId: z.number().int()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    wooCommerceProductId: z.number().int(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  wooCommerceProductId: z.number().int().optional(),
  AND: z.union([ z.lazy(() => ProductExamsWhereInputSchema),z.lazy(() => ProductExamsWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductExamsWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductExamsWhereInputSchema),z.lazy(() => ProductExamsWhereInputSchema).array() ]).optional(),
  images: z.lazy(() => JsonFilterSchema).optional(),
  labs: z.lazy(() => JsonFilterSchema).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  doctorAccountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict());

export default ProductExamsWhereUniqueInputSchema;
