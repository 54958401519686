// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutCollaborationsInputSchema } from './AccountUpdateWithoutCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutCollaborationsInputSchema';

export const AccountUpdateToOneWithWhereWithoutCollaborationsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutCollaborationsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutCollaborationsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutCollaborationsInputSchema;
