// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { StringNullableWithAggregatesFilterSchema } from './StringNullableWithAggregatesFilterSchema';
import { EnumINVITATION_STATUSWithAggregatesFilterSchema } from './EnumINVITATION_STATUSWithAggregatesFilterSchema';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { DateTimeWithAggregatesFilterSchema } from './DateTimeWithAggregatesFilterSchema';
import { DateTimeNullableWithAggregatesFilterSchema } from './DateTimeNullableWithAggregatesFilterSchema';

export const CollaborationInvitationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CollaborationInvitationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CollaborationInvitationScalarWhereWithAggregatesInputSchema),z.lazy(() => CollaborationInvitationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationInvitationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationInvitationScalarWhereWithAggregatesInputSchema),z.lazy(() => CollaborationInvitationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  inviteeId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  inviteeEmail: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumINVITATION_STATUSWithAggregatesFilterSchema),z.lazy(() => INVITATION_STATUSSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default CollaborationInvitationScalarWhereWithAggregatesInputSchema;
