// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutCollaboratorInputSchema } from './CollaborationCreateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedCreateWithoutCollaboratorInputSchema } from './CollaborationUncheckedCreateWithoutCollaboratorInputSchema';
import { CollaborationCreateOrConnectWithoutCollaboratorInputSchema } from './CollaborationCreateOrConnectWithoutCollaboratorInputSchema';
import { CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema } from './CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema';
import { CollaborationCreateManyCollaboratorInputEnvelopeSchema } from './CollaborationCreateManyCollaboratorInputEnvelopeSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema } from './CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema';
import { CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema } from './CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema';
import { CollaborationScalarWhereInputSchema } from './CollaborationScalarWhereInputSchema';

export const CollaborationUpdateManyWithoutCollaboratorNestedInputSchema: z.ZodType<Prisma.CollaborationUpdateManyWithoutCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CollaborationCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationCreateManyCollaboratorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUpdateWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CollaborationScalarWhereInputSchema),z.lazy(() => CollaborationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default CollaborationUpdateManyWithoutCollaboratorNestedInputSchema;
