// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsCreateManyInputSchema } from '../inputTypeSchemas/ProductExamsCreateManyInputSchema'

export const ProductExamsCreateManyArgsSchema: z.ZodType<Prisma.ProductExamsCreateManyArgs> = z.object({
  data: z.union([ ProductExamsCreateManyInputSchema,ProductExamsCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default ProductExamsCreateManyArgsSchema;
