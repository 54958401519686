// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationCreateWithoutRoleInputSchema } from './RolesOnCollaborationCreateWithoutRoleInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema';

export const RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateOrConnectWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutRoleInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnCollaborationCreateOrConnectWithoutRoleInputSchema;
