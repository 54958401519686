// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesWhereInputSchema } from '../inputTypeSchemas/ExamImagesWhereInputSchema'
import { ExamImagesOrderByWithRelationInputSchema } from '../inputTypeSchemas/ExamImagesOrderByWithRelationInputSchema'
import { ExamImagesWhereUniqueInputSchema } from '../inputTypeSchemas/ExamImagesWhereUniqueInputSchema'

export const ExamImagesAggregateArgsSchema: z.ZodType<Prisma.ExamImagesAggregateArgs> = z.object({
  where: ExamImagesWhereInputSchema.optional(),
  orderBy: z.union([ ExamImagesOrderByWithRelationInputSchema.array(),ExamImagesOrderByWithRelationInputSchema ]).optional(),
  cursor: ExamImagesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ExamImagesAggregateArgsSchema;
