// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';

export const ExamLabScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ExamLabScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ExamLabScalarWhereWithAggregatesInputSchema),z.lazy(() => ExamLabScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ExamLabScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ExamLabScalarWhereWithAggregatesInputSchema),z.lazy(() => ExamLabScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name_es: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  category: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default ExamLabScalarWhereWithAggregatesInputSchema;
