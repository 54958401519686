// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutAccountInputSchema } from './CollaborationCreateWithoutAccountInputSchema';
import { CollaborationUncheckedCreateWithoutAccountInputSchema } from './CollaborationUncheckedCreateWithoutAccountInputSchema';
import { CollaborationCreateOrConnectWithoutAccountInputSchema } from './CollaborationCreateOrConnectWithoutAccountInputSchema';
import { CollaborationCreateManyAccountInputEnvelopeSchema } from './CollaborationCreateManyAccountInputEnvelopeSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';

export const CollaborationCreateNestedManyWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationCreateNestedManyWithoutAccountInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationCreateWithoutAccountInputSchema).array(),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationCreateOrConnectWithoutAccountInputSchema),z.lazy(() => CollaborationCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationCreateManyAccountInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default CollaborationCreateNestedManyWithoutAccountInputSchema;
