// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateNestedOneWithoutRolesInputSchema } from './CollaborationCreateNestedOneWithoutRolesInputSchema';
import { RoleCreateNestedOneWithoutCollaborationsInputSchema } from './RoleCreateNestedOneWithoutCollaborationsInputSchema';

export const RolesOnCollaborationCreateInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateInput> = z.object({
  id: z.string().optional(),
  collaboration: z.lazy(() => CollaborationCreateNestedOneWithoutRolesInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutCollaborationsInputSchema)
}).strict();

export default RolesOnCollaborationCreateInputSchema;
