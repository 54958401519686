// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationCreateWithoutAccountInputSchema } from './CollaborationCreateWithoutAccountInputSchema';
import { CollaborationUncheckedCreateWithoutAccountInputSchema } from './CollaborationUncheckedCreateWithoutAccountInputSchema';

export const CollaborationCreateOrConnectWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationCreateOrConnectWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationCreateOrConnectWithoutAccountInputSchema;
