// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationIncludeSchema } from '../inputTypeSchemas/CollaborationIncludeSchema'
import { CollaborationUpdateInputSchema } from '../inputTypeSchemas/CollaborationUpdateInputSchema'
import { CollaborationUncheckedUpdateInputSchema } from '../inputTypeSchemas/CollaborationUncheckedUpdateInputSchema'
import { CollaborationWhereUniqueInputSchema } from '../inputTypeSchemas/CollaborationWhereUniqueInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RolesOnCollaborationFindManyArgsSchema } from "../outputTypeSchemas/RolesOnCollaborationFindManyArgsSchema"
import { CollaborationCountOutputTypeArgsSchema } from "../outputTypeSchemas/CollaborationCountOutputTypeArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const CollaborationSelectSchema: z.ZodType<Prisma.CollaborationSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  showInSearch: z.boolean().optional(),
  collaboratorId: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  roles: z.union([z.boolean(),z.lazy(() => RolesOnCollaborationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CollaborationCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CollaborationUpdateArgsSchema: z.ZodType<Prisma.CollaborationUpdateArgs> = z.object({
  select: CollaborationSelectSchema.optional(),
  include: CollaborationIncludeSchema.optional(),
  data: z.union([ CollaborationUpdateInputSchema,CollaborationUncheckedUpdateInputSchema ]),
  where: CollaborationWhereUniqueInputSchema,
}).strict() ;

export default CollaborationUpdateArgsSchema;
