// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutInitiatedCollaborationsInputSchema } from './AccountCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema';

export const AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutInitiatedCollaborationsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema;
