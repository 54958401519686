// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';

export const EnumINVITATION_STATUSFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumINVITATION_STATUSFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => INVITATION_STATUSSchema).optional()
}).strict();

export default EnumINVITATION_STATUSFieldUpdateOperationsInputSchema;
