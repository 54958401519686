// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationCreateManyInputSchema } from '../inputTypeSchemas/RolesOnCollaborationCreateManyInputSchema'

export const RolesOnCollaborationCreateManyArgsSchema: z.ZodType<Prisma.RolesOnCollaborationCreateManyArgs> = z.object({
  data: z.union([ RolesOnCollaborationCreateManyInputSchema,RolesOnCollaborationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default RolesOnCollaborationCreateManyArgsSchema;
