// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteSelectSchema } from '../inputTypeSchemas/SiteSelectSchema';
import { SiteIncludeSchema } from '../inputTypeSchemas/SiteIncludeSchema';

export const SiteArgsSchema: z.ZodType<Prisma.SiteDefaultArgs> = z.object({
  select: z.lazy(() => SiteSelectSchema).optional(),
  include: z.lazy(() => SiteIncludeSchema).optional(),
}).strict();

export default SiteArgsSchema;
