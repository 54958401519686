// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationCreateWithoutCollaboratorInputSchema } from './CollaborationCreateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedCreateWithoutCollaboratorInputSchema } from './CollaborationUncheckedCreateWithoutCollaboratorInputSchema';

export const CollaborationCreateOrConnectWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationCreateOrConnectWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export default CollaborationCreateOrConnectWithoutCollaboratorInputSchema;
