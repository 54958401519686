// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutReportedInputSchema } from './AccountCreateNestedOneWithoutReportedInputSchema';

export const ReportedContentCreateInputSchema: z.ZodType<Prisma.ReportedContentCreateInput> = z.object({
  id: z.string().optional(),
  reason: z.string(),
  accountId: z.string().optional().nullable(),
  postId: z.number().int().optional().nullable(),
  commentId: z.number().int().optional().nullable(),
  wasChecked: z.boolean().optional(),
  created: z.coerce.date().optional(),
  updated: z.coerce.date().optional(),
  reportedBy: z.lazy(() => AccountCreateNestedOneWithoutReportedInputSchema)
}).strict();

export default ReportedContentCreateInputSchema;
