// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';
import { RoleUpdateWithoutAccountsInputSchema } from './RoleUpdateWithoutAccountsInputSchema';
import { RoleUncheckedUpdateWithoutAccountsInputSchema } from './RoleUncheckedUpdateWithoutAccountsInputSchema';

export const RoleUpdateToOneWithWhereWithoutAccountsInputSchema: z.ZodType<Prisma.RoleUpdateToOneWithWhereWithoutAccountsInput> = z.object({
  where: z.lazy(() => RoleWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => RoleUpdateWithoutAccountsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutAccountsInputSchema) ]),
}).strict();

export default RoleUpdateToOneWithWhereWithoutAccountsInputSchema;
