// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { AccountOrderByWithRelationInputSchema } from './AccountOrderByWithRelationInputSchema';
import { RolesOnCollaborationOrderByRelationAggregateInputSchema } from './RolesOnCollaborationOrderByRelationAggregateInputSchema';
import { CollaborationOrderByRelevanceInputSchema } from './CollaborationOrderByRelevanceInputSchema';

export const CollaborationOrderByWithRelationInputSchema: z.ZodType<Prisma.CollaborationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  showInSearch: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  account: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  collaborator: z.lazy(() => AccountOrderByWithRelationInputSchema).optional(),
  roles: z.lazy(() => RolesOnCollaborationOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => CollaborationOrderByRelevanceInputSchema).optional()
}).strict();

export default CollaborationOrderByWithRelationInputSchema;
