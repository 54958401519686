// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { OtpOrderByRelevanceFieldEnumSchema } from './OtpOrderByRelevanceFieldEnumSchema';
import { SortOrderSchema } from './SortOrderSchema';

export const OtpOrderByRelevanceInputSchema: z.ZodType<Prisma.OtpOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => OtpOrderByRelevanceFieldEnumSchema),z.lazy(() => OtpOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export default OtpOrderByRelevanceInputSchema;
