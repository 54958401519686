// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const CollaborationCreateManyInputSchema: z.ZodType<Prisma.CollaborationCreateManyInput> = z.object({
  id: z.string().optional(),
  accountId: z.string(),
  showInSearch: z.boolean().optional(),
  collaboratorId: z.string()
}).strict();

export default CollaborationCreateManyInputSchema;
