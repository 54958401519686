// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationWhereInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereInputSchema'

export const RolesOnCollaborationDeleteManyArgsSchema: z.ZodType<Prisma.RolesOnCollaborationDeleteManyArgs> = z.object({
  where: RolesOnCollaborationWhereInputSchema.optional(),
}).strict() ;

export default RolesOnCollaborationDeleteManyArgsSchema;
