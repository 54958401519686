// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationUpdateManyMutationInputSchema } from '../inputTypeSchemas/CollaborationUpdateManyMutationInputSchema'
import { CollaborationUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/CollaborationUncheckedUpdateManyInputSchema'
import { CollaborationWhereInputSchema } from '../inputTypeSchemas/CollaborationWhereInputSchema'

export const CollaborationUpdateManyArgsSchema: z.ZodType<Prisma.CollaborationUpdateManyArgs> = z.object({
  data: z.union([ CollaborationUpdateManyMutationInputSchema,CollaborationUncheckedUpdateManyInputSchema ]),
  where: CollaborationWhereInputSchema.optional(),
}).strict() ;

export default CollaborationUpdateManyArgsSchema;
