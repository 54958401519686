// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { INVITATION_STATUSSchema } from './INVITATION_STATUSSchema';
import { AccountCreateNestedOneWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateNestedOneWithoutSentCollaborationInvitationsInputSchema';

export const CollaborationInvitationCreateWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationCreateWithoutInviteeInput> = z.object({
  id: z.string().optional(),
  inviteeEmail: z.string(),
  status: z.lazy(() => INVITATION_STATUSSchema).optional(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutSentCollaborationInvitationsInputSchema)
}).strict();

export default CollaborationInvitationCreateWithoutInviteeInputSchema;
