// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationUpdateManyMutationInputSchema } from '../inputTypeSchemas/RolesOnCollaborationUpdateManyMutationInputSchema'
import { RolesOnCollaborationUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/RolesOnCollaborationUncheckedUpdateManyInputSchema'
import { RolesOnCollaborationWhereInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereInputSchema'

export const RolesOnCollaborationUpdateManyArgsSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateManyArgs> = z.object({
  data: z.union([ RolesOnCollaborationUpdateManyMutationInputSchema,RolesOnCollaborationUncheckedUpdateManyInputSchema ]),
  where: RolesOnCollaborationWhereInputSchema.optional(),
}).strict() ;

export default RolesOnCollaborationUpdateManyArgsSchema;
