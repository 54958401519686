// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { EnumACCOUNT_VISIBILITYFilterSchema } from './EnumACCOUNT_VISIBILITYFilterSchema';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';
import { StringFilterSchema } from './StringFilterSchema';
import { EnumLANGNullableFilterSchema } from './EnumLANGNullableFilterSchema';
import { LANGSchema } from './LANGSchema';
import { JsonNullableFilterSchema } from './JsonNullableFilterSchema';
import { IntNullableFilterSchema } from './IntNullableFilterSchema';
import { BoolNullableFilterSchema } from './BoolNullableFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';
import { RolesOnAccountListRelationFilterSchema } from './RolesOnAccountListRelationFilterSchema';
import { CollaborationListRelationFilterSchema } from './CollaborationListRelationFilterSchema';
import { CollaborationInvitationListRelationFilterSchema } from './CollaborationInvitationListRelationFilterSchema';
import { EventListRelationFilterSchema } from './EventListRelationFilterSchema';
import { SiteListRelationFilterSchema } from './SiteListRelationFilterSchema';
import { AccountListRelationFilterSchema } from './AccountListRelationFilterSchema';
import { AccountNullableRelationFilterSchema } from './AccountNullableRelationFilterSchema';
import { PushTokensListRelationFilterSchema } from './PushTokensListRelationFilterSchema';
import { ReportedContentListRelationFilterSchema } from './ReportedContentListRelationFilterSchema';

export const AccountWhereUniqueInputSchema: z.ZodType<Prisma.AccountWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string()
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wordpressId: z.number().int(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    wooCommerceTermId: z.number().int(),
  }),
  z.object({
    id: z.string(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    id: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
    slug: z.string(),
  }),
  z.object({
    id: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    wooCommerceTermId: z.number().int(),
  }),
  z.object({
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    wordpressId: z.number().int(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    stripeCustomerId: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    slug: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
    email: z.string(),
  }),
  z.object({
    wooCommerceTermId: z.number().int(),
  }),
  z.object({
    stripeCustomerId: z.string(),
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    stripeCustomerId: z.string(),
    slug: z.string(),
  }),
  z.object({
    stripeCustomerId: z.string(),
    email: z.string(),
  }),
  z.object({
    stripeCustomerId: z.string(),
  }),
  z.object({
    slug: z.string(),
    email: z.string(),
  }),
  z.object({
    slug: z.string(),
  }),
  z.object({
    email: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  wordpressId: z.number().int().optional(),
  wooCommerceTermId: z.number().int().optional(),
  stripeCustomerId: z.string().optional(),
  slug: z.string().optional(),
  email: z.string().optional(),
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  firebaseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  visibility: z.union([ z.lazy(() => EnumACCOUNT_VISIBILITYFilterSchema),z.lazy(() => ACCOUNT_VISIBILITYSchema) ]).optional(),
  type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  current_company_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  current_position_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  plan: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  language: z.union([ z.lazy(() => EnumLANGNullableFilterSchema),z.lazy(() => LANGSchema) ]).optional().nullable(),
  logo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  profilePicture: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  backgroundImage: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  timeZone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  quickBio: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  about: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  displayName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  birthDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  prescriptionLogo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  prescriptionSignature: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  medicalSchoolNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  branches: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workers: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  mainService: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  otherServices: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  color: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  website: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  categories: z.lazy(() => JsonNullableFilterSchema).optional(),
  categoriesSlugs: z.lazy(() => JsonNullableFilterSchema).optional(),
  defaultProductId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  rating: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  sex: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  mainMenuId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  landingPageId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  isHighlightedAccount: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  postal_code: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  city: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address1: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address2: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  region: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phoneNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  nationalIdentityNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  marketingConsent: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  termsConsent: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  ownerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  roles: z.lazy(() => RolesOnAccountListRelationFilterSchema).optional(),
  initiatedCollaborations: z.lazy(() => CollaborationListRelationFilterSchema).optional(),
  collaborations: z.lazy(() => CollaborationListRelationFilterSchema).optional(),
  receivedCollaborationInvitations: z.lazy(() => CollaborationInvitationListRelationFilterSchema).optional(),
  sentCollaborationInvitations: z.lazy(() => CollaborationInvitationListRelationFilterSchema).optional(),
  events: z.lazy(() => EventListRelationFilterSchema).optional(),
  sites: z.lazy(() => SiteListRelationFilterSchema).optional(),
  ownedAccounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  owner: z.union([ z.lazy(() => AccountNullableRelationFilterSchema),z.lazy(() => AccountWhereInputSchema) ]).optional().nullable(),
  pushTokens: z.lazy(() => PushTokensListRelationFilterSchema).optional(),
  reported: z.lazy(() => ReportedContentListRelationFilterSchema).optional()
}).strict());

export default AccountWhereUniqueInputSchema;
