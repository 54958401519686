// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountWhereInputSchema } from './RolesOnAccountWhereInputSchema';

export const RolesOnAccountListRelationFilterSchema: z.ZodType<Prisma.RolesOnAccountListRelationFilter> = z.object({
  every: z.lazy(() => RolesOnAccountWhereInputSchema).optional(),
  some: z.lazy(() => RolesOnAccountWhereInputSchema).optional(),
  none: z.lazy(() => RolesOnAccountWhereInputSchema).optional()
}).strict();

export default RolesOnAccountListRelationFilterSchema;
