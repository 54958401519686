// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutOwnedAccountsInputSchema } from './AccountUpdateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedUpdateWithoutOwnedAccountsInputSchema } from './AccountUncheckedUpdateWithoutOwnedAccountsInputSchema';

export const AccountUpdateToOneWithWhereWithoutOwnedAccountsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutOwnedAccountsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutOwnedAccountsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutOwnedAccountsInputSchema;
