// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleCreateNestedOneWithoutCollaborationsInputSchema } from './RoleCreateNestedOneWithoutCollaborationsInputSchema';

export const RolesOnCollaborationCreateWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateWithoutCollaborationInput> = z.object({
  id: z.string().optional(),
  role: z.lazy(() => RoleCreateNestedOneWithoutCollaborationsInputSchema)
}).strict();

export default RolesOnCollaborationCreateWithoutCollaborationInputSchema;
