// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensIncludeSchema } from '../inputTypeSchemas/PushTokensIncludeSchema'
import { PushTokensWhereUniqueInputSchema } from '../inputTypeSchemas/PushTokensWhereUniqueInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const PushTokensSelectSchema: z.ZodType<Prisma.PushTokensSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  pushToken: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const PushTokensFindUniqueArgsSchema: z.ZodType<Prisma.PushTokensFindUniqueArgs> = z.object({
  select: PushTokensSelectSchema.optional(),
  include: PushTokensIncludeSchema.optional(),
  where: PushTokensWhereUniqueInputSchema,
}).strict() ;

export default PushTokensFindUniqueArgsSchema;
