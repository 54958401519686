// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { BoolFieldUpdateOperationsInputSchema } from './BoolFieldUpdateOperationsInputSchema';
import { AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema } from './AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema';
import { RolesOnCollaborationUpdateManyWithoutCollaborationNestedInputSchema } from './RolesOnCollaborationUpdateManyWithoutCollaborationNestedInputSchema';

export const CollaborationUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  showInSearch: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  account: z.lazy(() => AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema).optional(),
  roles: z.lazy(() => RolesOnCollaborationUpdateManyWithoutCollaborationNestedInputSchema).optional()
}).strict();

export default CollaborationUpdateWithoutCollaboratorInputSchema;
