// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductUpdateManyMutationInputSchema } from '../inputTypeSchemas/IspProductUpdateManyMutationInputSchema'
import { IspProductUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/IspProductUncheckedUpdateManyInputSchema'
import { IspProductWhereInputSchema } from '../inputTypeSchemas/IspProductWhereInputSchema'

export const IspProductUpdateManyArgsSchema: z.ZodType<Prisma.IspProductUpdateManyArgs> = z.object({
  data: z.union([ IspProductUpdateManyMutationInputSchema,IspProductUncheckedUpdateManyInputSchema ]),
  where: IspProductWhereInputSchema.optional(),
}).strict() ;

export default IspProductUpdateManyArgsSchema;
