// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationUpdateWithoutInviteeInputSchema } from './CollaborationInvitationUpdateWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema';

export const CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInput> = z.object({
  where: z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CollaborationInvitationUpdateWithoutInviteeInputSchema),z.lazy(() => CollaborationInvitationUncheckedUpdateWithoutInviteeInputSchema) ]),
}).strict();

export default CollaborationInvitationUpdateWithWhereUniqueWithoutInviteeInputSchema;
