// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentCreateManyReportedByInputSchema } from './ReportedContentCreateManyReportedByInputSchema';

export const ReportedContentCreateManyReportedByInputEnvelopeSchema: z.ZodType<Prisma.ReportedContentCreateManyReportedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ReportedContentCreateManyReportedByInputSchema),z.lazy(() => ReportedContentCreateManyReportedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default ReportedContentCreateManyReportedByInputEnvelopeSchema;
