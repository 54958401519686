// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';
import { IntFilterSchema } from './IntFilterSchema';
import { EnumACCOUNT_VISIBILITYFilterSchema } from './EnumACCOUNT_VISIBILITYFilterSchema';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';
import { EnumLANGNullableFilterSchema } from './EnumLANGNullableFilterSchema';
import { LANGSchema } from './LANGSchema';
import { JsonNullableFilterSchema } from './JsonNullableFilterSchema';
import { IntNullableFilterSchema } from './IntNullableFilterSchema';
import { BoolNullableFilterSchema } from './BoolNullableFilterSchema';
import { DateTimeFilterSchema } from './DateTimeFilterSchema';
import { DateTimeNullableFilterSchema } from './DateTimeNullableFilterSchema';

export const AccountScalarWhereInputSchema: z.ZodType<Prisma.AccountScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firebaseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  wordpressId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  wooCommerceTermId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  stripeCustomerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  slug: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  visibility: z.union([ z.lazy(() => EnumACCOUNT_VISIBILITYFilterSchema),z.lazy(() => ACCOUNT_VISIBILITYSchema) ]).optional(),
  type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  current_company_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  current_position_name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  plan: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  language: z.union([ z.lazy(() => EnumLANGNullableFilterSchema),z.lazy(() => LANGSchema) ]).optional().nullable(),
  logo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  profilePicture: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  backgroundImage: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  timeZone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  quickBio: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  about: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  displayName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  birthDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  prescriptionLogo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  prescriptionSignature: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  medicalSchoolNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  branches: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workers: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  mainService: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  otherServices: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  color: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  website: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  categories: z.lazy(() => JsonNullableFilterSchema).optional(),
  categoriesSlugs: z.lazy(() => JsonNullableFilterSchema).optional(),
  defaultProductId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  rating: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  sex: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  mainMenuId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  landingPageId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  isHighlightedAccount: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  postal_code: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  city: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address1: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address2: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  region: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phoneNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  nationalIdentityNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  marketingConsent: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  termsConsent: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  ownerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  created: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default AccountScalarWhereInputSchema;
