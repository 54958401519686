// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { ColegioMedicoOrderByRelevanceInputSchema } from './ColegioMedicoOrderByRelevanceInputSchema';

export const ColegioMedicoOrderByWithRelationInputSchema: z.ZodType<Prisma.ColegioMedicoOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  icm: z.lazy(() => SortOrderSchema).optional(),
  names: z.lazy(() => SortOrderSchema).optional(),
  lastNames: z.lazy(() => SortOrderSchema).optional(),
  afiliation: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  _relevance: z.lazy(() => ColegioMedicoOrderByRelevanceInputSchema).optional()
}).strict();

export default ColegioMedicoOrderByWithRelationInputSchema;
