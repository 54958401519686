// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUpdateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema';
import { AccountCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';

export const AccountUpsertWithoutSentCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountUpsertWithoutSentCollaborationInvitationsInput> = z.object({
  update: z.union([ z.lazy(() => AccountUpdateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutSentCollaborationInvitationsInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutSentCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutSentCollaborationInvitationsInputSchema) ]),
  where: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export default AccountUpsertWithoutSentCollaborationInvitationsInputSchema;
