// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountCreateManyRoleInputSchema } from './RolesOnAccountCreateManyRoleInputSchema';

export const RolesOnAccountCreateManyRoleInputEnvelopeSchema: z.ZodType<Prisma.RolesOnAccountCreateManyRoleInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RolesOnAccountCreateManyRoleInputSchema),z.lazy(() => RolesOnAccountCreateManyRoleInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default RolesOnAccountCreateManyRoleInputEnvelopeSchema;
