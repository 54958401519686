// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// REPORTED CONTENT SCHEMA
/////////////////////////////////////////

export const ReportedContentSchema = z.object({
  id: z.string(),
  reason: z.string(),
  accountId: z.string().nullable(),
  postId: z.number().int().nullable(),
  commentId: z.number().int().nullable(),
  wasChecked: z.boolean(),
  reportedById: z.string(),
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export type ReportedContent = z.infer<typeof ReportedContentSchema>

export default ReportedContentSchema;
