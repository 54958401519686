// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema';
import { RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema } from './RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema';
import { RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema } from './RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema } from './RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema';
import { RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema } from './RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema';
import { RolesOnCollaborationScalarWhereInputSchema } from './RolesOnCollaborationScalarWhereInputSchema';

export const RolesOnCollaborationUpdateManyWithoutCollaborationNestedInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateManyWithoutCollaborationNestedInput> = z.object({
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema).array(),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUpsertWithWhereUniqueWithoutCollaborationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUpdateWithWhereUniqueWithoutCollaborationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUpdateManyWithWhereWithoutCollaborationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RolesOnCollaborationScalarWhereInputSchema),z.lazy(() => RolesOnCollaborationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default RolesOnCollaborationUpdateManyWithoutCollaborationNestedInputSchema;
