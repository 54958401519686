// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { EnumDomainActivationStatusWithAggregatesFilterSchema } from './EnumDomainActivationStatusWithAggregatesFilterSchema';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';
import { DateTimeWithAggregatesFilterSchema } from './DateTimeWithAggregatesFilterSchema';
import { DateTimeNullableWithAggregatesFilterSchema } from './DateTimeNullableWithAggregatesFilterSchema';

export const SiteScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SiteScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SiteScalarWhereWithAggregatesInputSchema),z.lazy(() => SiteScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SiteScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SiteScalarWhereWithAggregatesInputSchema),z.lazy(() => SiteScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  domain: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumDomainActivationStatusWithAggregatesFilterSchema),z.lazy(() => DomainActivationStatusSchema) ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  created: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deleted: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  updated: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export default SiteScalarWhereWithAggregatesInputSchema;
