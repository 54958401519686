// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensWhereUniqueInputSchema } from './PushTokensWhereUniqueInputSchema';
import { PushTokensCreateWithoutAccountInputSchema } from './PushTokensCreateWithoutAccountInputSchema';
import { PushTokensUncheckedCreateWithoutAccountInputSchema } from './PushTokensUncheckedCreateWithoutAccountInputSchema';

export const PushTokensCreateOrConnectWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensCreateOrConnectWithoutAccountInput> = z.object({
  where: z.lazy(() => PushTokensWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PushTokensCreateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema) ]),
}).strict();

export default PushTokensCreateOrConnectWithoutAccountInputSchema;
