// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensCreateManyAccountInputSchema } from './PushTokensCreateManyAccountInputSchema';

export const PushTokensCreateManyAccountInputEnvelopeSchema: z.ZodType<Prisma.PushTokensCreateManyAccountInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PushTokensCreateManyAccountInputSchema),z.lazy(() => PushTokensCreateManyAccountInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default PushTokensCreateManyAccountInputEnvelopeSchema;
