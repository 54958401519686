// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';
import { RoleUpdateWithoutCollaborationsInputSchema } from './RoleUpdateWithoutCollaborationsInputSchema';
import { RoleUncheckedUpdateWithoutCollaborationsInputSchema } from './RoleUncheckedUpdateWithoutCollaborationsInputSchema';

export const RoleUpdateToOneWithWhereWithoutCollaborationsInputSchema: z.ZodType<Prisma.RoleUpdateToOneWithWhereWithoutCollaborationsInput> = z.object({
  where: z.lazy(() => RoleWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => RoleUpdateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutCollaborationsInputSchema) ]),
}).strict();

export default RoleUpdateToOneWithWhereWithoutCollaborationsInputSchema;
