// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensCreateWithoutAccountInputSchema } from './PushTokensCreateWithoutAccountInputSchema';
import { PushTokensUncheckedCreateWithoutAccountInputSchema } from './PushTokensUncheckedCreateWithoutAccountInputSchema';
import { PushTokensCreateOrConnectWithoutAccountInputSchema } from './PushTokensCreateOrConnectWithoutAccountInputSchema';
import { PushTokensCreateManyAccountInputEnvelopeSchema } from './PushTokensCreateManyAccountInputEnvelopeSchema';
import { PushTokensWhereUniqueInputSchema } from './PushTokensWhereUniqueInputSchema';

export const PushTokensUncheckedCreateNestedManyWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensUncheckedCreateNestedManyWithoutAccountInput> = z.object({
  create: z.union([ z.lazy(() => PushTokensCreateWithoutAccountInputSchema),z.lazy(() => PushTokensCreateWithoutAccountInputSchema).array(),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PushTokensCreateOrConnectWithoutAccountInputSchema),z.lazy(() => PushTokensCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PushTokensCreateManyAccountInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PushTokensWhereUniqueInputSchema),z.lazy(() => PushTokensWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default PushTokensUncheckedCreateNestedManyWithoutAccountInputSchema;
