// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpWhereInputSchema } from '../inputTypeSchemas/OtpWhereInputSchema'
import { OtpOrderByWithRelationInputSchema } from '../inputTypeSchemas/OtpOrderByWithRelationInputSchema'
import { OtpWhereUniqueInputSchema } from '../inputTypeSchemas/OtpWhereUniqueInputSchema'
import { OtpScalarFieldEnumSchema } from '../inputTypeSchemas/OtpScalarFieldEnumSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const OtpSelectSchema: z.ZodType<Prisma.OtpSelect> = z.object({
  id: z.boolean().optional(),
  email: z.boolean().optional(),
  password: z.boolean().optional(),
  exp: z.boolean().optional(),
}).strict()

export const OtpFindFirstArgsSchema: z.ZodType<Prisma.OtpFindFirstArgs> = z.object({
  select: OtpSelectSchema.optional(),
  where: OtpWhereInputSchema.optional(),
  orderBy: z.union([ OtpOrderByWithRelationInputSchema.array(),OtpOrderByWithRelationInputSchema ]).optional(),
  cursor: OtpWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ OtpScalarFieldEnumSchema,OtpScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default OtpFindFirstArgsSchema;
