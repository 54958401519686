// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentCreateWithoutReportedByInputSchema } from './ReportedContentCreateWithoutReportedByInputSchema';
import { ReportedContentUncheckedCreateWithoutReportedByInputSchema } from './ReportedContentUncheckedCreateWithoutReportedByInputSchema';
import { ReportedContentCreateOrConnectWithoutReportedByInputSchema } from './ReportedContentCreateOrConnectWithoutReportedByInputSchema';
import { ReportedContentCreateManyReportedByInputEnvelopeSchema } from './ReportedContentCreateManyReportedByInputEnvelopeSchema';
import { ReportedContentWhereUniqueInputSchema } from './ReportedContentWhereUniqueInputSchema';

export const ReportedContentUncheckedCreateNestedManyWithoutReportedByInputSchema: z.ZodType<Prisma.ReportedContentUncheckedCreateNestedManyWithoutReportedByInput> = z.object({
  create: z.union([ z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema).array(),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ReportedContentCreateOrConnectWithoutReportedByInputSchema),z.lazy(() => ReportedContentCreateOrConnectWithoutReportedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ReportedContentCreateManyReportedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ReportedContentWhereUniqueInputSchema),z.lazy(() => ReportedContentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default ReportedContentUncheckedCreateNestedManyWithoutReportedByInputSchema;
