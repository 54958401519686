// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';

export const CollaborationRelationFilterSchema: z.ZodType<Prisma.CollaborationRelationFilter> = z.object({
  is: z.lazy(() => CollaborationWhereInputSchema).optional(),
  isNot: z.lazy(() => CollaborationWhereInputSchema).optional()
}).strict();

export default CollaborationRelationFilterSchema;
