// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountSelectSchema } from '../inputTypeSchemas/RolesOnAccountSelectSchema';
import { RolesOnAccountIncludeSchema } from '../inputTypeSchemas/RolesOnAccountIncludeSchema';

export const RolesOnAccountArgsSchema: z.ZodType<Prisma.RolesOnAccountDefaultArgs> = z.object({
  select: z.lazy(() => RolesOnAccountSelectSchema).optional(),
  include: z.lazy(() => RolesOnAccountIncludeSchema).optional(),
}).strict();

export default RolesOnAccountArgsSchema;
