// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const AccountMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firebaseId: z.lazy(() => SortOrderSchema).optional(),
  wordpressId: z.lazy(() => SortOrderSchema).optional(),
  wooCommerceTermId: z.lazy(() => SortOrderSchema).optional(),
  stripeCustomerId: z.lazy(() => SortOrderSchema).optional(),
  slug: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  visibility: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  current_company_name: z.lazy(() => SortOrderSchema).optional(),
  current_position_name: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  plan: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  logo: z.lazy(() => SortOrderSchema).optional(),
  profilePicture: z.lazy(() => SortOrderSchema).optional(),
  backgroundImage: z.lazy(() => SortOrderSchema).optional(),
  timeZone: z.lazy(() => SortOrderSchema).optional(),
  quickBio: z.lazy(() => SortOrderSchema).optional(),
  about: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  birthDate: z.lazy(() => SortOrderSchema).optional(),
  prescriptionLogo: z.lazy(() => SortOrderSchema).optional(),
  prescriptionSignature: z.lazy(() => SortOrderSchema).optional(),
  medicalSchoolNumber: z.lazy(() => SortOrderSchema).optional(),
  branches: z.lazy(() => SortOrderSchema).optional(),
  workers: z.lazy(() => SortOrderSchema).optional(),
  mainService: z.lazy(() => SortOrderSchema).optional(),
  otherServices: z.lazy(() => SortOrderSchema).optional(),
  color: z.lazy(() => SortOrderSchema).optional(),
  website: z.lazy(() => SortOrderSchema).optional(),
  defaultProductId: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  sex: z.lazy(() => SortOrderSchema).optional(),
  mainMenuId: z.lazy(() => SortOrderSchema).optional(),
  landingPageId: z.lazy(() => SortOrderSchema).optional(),
  isHighlightedAccount: z.lazy(() => SortOrderSchema).optional(),
  postal_code: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  address1: z.lazy(() => SortOrderSchema).optional(),
  address2: z.lazy(() => SortOrderSchema).optional(),
  region: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  nationalIdentityNumber: z.lazy(() => SortOrderSchema).optional(),
  marketingConsent: z.lazy(() => SortOrderSchema).optional(),
  termsConsent: z.lazy(() => SortOrderSchema).optional(),
  ownerId: z.lazy(() => SortOrderSchema).optional(),
  created: z.lazy(() => SortOrderSchema).optional(),
  deleted: z.lazy(() => SortOrderSchema).optional(),
  updated: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default AccountMaxOrderByAggregateInputSchema;
