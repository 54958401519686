// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutInitiatedCollaborationsInputSchema } from './AccountCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema';
import { AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema } from './AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema';
import { AccountUpsertWithoutInitiatedCollaborationsInputSchema } from './AccountUpsertWithoutInitiatedCollaborationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInputSchema } from './AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInputSchema';
import { AccountUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUpdateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema';

export const AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema: z.ZodType<Prisma.AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutInitiatedCollaborationsInputSchema).optional(),
  upsert: z.lazy(() => AccountUpsertWithoutInitiatedCollaborationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUpdateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema) ]).optional(),
}).strict();

export default AccountUpdateOneRequiredWithoutInitiatedCollaborationsNestedInputSchema;
