// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { ExamImagesOrderByRelevanceInputSchema } from './ExamImagesOrderByRelevanceInputSchema';

export const ExamImagesOrderByWithRelationInputSchema: z.ZodType<Prisma.ExamImagesOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bodyArea: z.lazy(() => SortOrderSchema).optional(),
  bodyPart: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name_es: z.lazy(() => SortOrderSchema).optional(),
  symptom: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  _relevance: z.lazy(() => ExamImagesOrderByRelevanceInputSchema).optional()
}).strict();

export default ExamImagesOrderByWithRelationInputSchema;
