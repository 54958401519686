// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentCreateWithoutReportedByInputSchema } from './ReportedContentCreateWithoutReportedByInputSchema';
import { ReportedContentUncheckedCreateWithoutReportedByInputSchema } from './ReportedContentUncheckedCreateWithoutReportedByInputSchema';
import { ReportedContentCreateOrConnectWithoutReportedByInputSchema } from './ReportedContentCreateOrConnectWithoutReportedByInputSchema';
import { ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema } from './ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema';
import { ReportedContentCreateManyReportedByInputEnvelopeSchema } from './ReportedContentCreateManyReportedByInputEnvelopeSchema';
import { ReportedContentWhereUniqueInputSchema } from './ReportedContentWhereUniqueInputSchema';
import { ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema } from './ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema';
import { ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema } from './ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema';
import { ReportedContentScalarWhereInputSchema } from './ReportedContentScalarWhereInputSchema';

export const ReportedContentUncheckedUpdateManyWithoutReportedByNestedInputSchema: z.ZodType<Prisma.ReportedContentUncheckedUpdateManyWithoutReportedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema).array(),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ReportedContentCreateOrConnectWithoutReportedByInputSchema),z.lazy(() => ReportedContentCreateOrConnectWithoutReportedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema),z.lazy(() => ReportedContentUpsertWithWhereUniqueWithoutReportedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ReportedContentCreateManyReportedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ReportedContentWhereUniqueInputSchema),z.lazy(() => ReportedContentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ReportedContentWhereUniqueInputSchema),z.lazy(() => ReportedContentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ReportedContentWhereUniqueInputSchema),z.lazy(() => ReportedContentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ReportedContentWhereUniqueInputSchema),z.lazy(() => ReportedContentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema),z.lazy(() => ReportedContentUpdateWithWhereUniqueWithoutReportedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema),z.lazy(() => ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ReportedContentScalarWhereInputSchema),z.lazy(() => ReportedContentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default ReportedContentUncheckedUpdateManyWithoutReportedByNestedInputSchema;
