// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFilterSchema } from './StringFilterSchema';
import { IntFilterSchema } from './IntFilterSchema';
import { JsonFilterSchema } from './JsonFilterSchema';
import { StringNullableFilterSchema } from './StringNullableFilterSchema';

export const ProductExamsWhereInputSchema: z.ZodType<Prisma.ProductExamsWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ProductExamsWhereInputSchema),z.lazy(() => ProductExamsWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductExamsWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductExamsWhereInputSchema),z.lazy(() => ProductExamsWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  wooCommerceProductId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  images: z.lazy(() => JsonFilterSchema).optional(),
  labs: z.lazy(() => JsonFilterSchema).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  doctorAccountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export default ProductExamsWhereInputSchema;
