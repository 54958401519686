// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountWhereInputSchema } from '../inputTypeSchemas/RolesOnAccountWhereInputSchema'

export const RolesOnAccountDeleteManyArgsSchema: z.ZodType<Prisma.RolesOnAccountDeleteManyArgs> = z.object({
  where: RolesOnAccountWhereInputSchema.optional(),
}).strict() ;

export default RolesOnAccountDeleteManyArgsSchema;
