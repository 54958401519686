// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationArgsSchema } from "../outputTypeSchemas/CollaborationArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"

export const RolesOnCollaborationSelectSchema: z.ZodType<Prisma.RolesOnCollaborationSelect> = z.object({
  id: z.boolean().optional(),
  collaborationId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  collaboration: z.union([z.boolean(),z.lazy(() => CollaborationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export default RolesOnCollaborationSelectSchema;
