// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationCreateWithoutAccountInputSchema } from './CollaborationCreateWithoutAccountInputSchema';
import { CollaborationUncheckedCreateWithoutAccountInputSchema } from './CollaborationUncheckedCreateWithoutAccountInputSchema';
import { CollaborationCreateOrConnectWithoutAccountInputSchema } from './CollaborationCreateOrConnectWithoutAccountInputSchema';
import { CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema } from './CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema';
import { CollaborationCreateManyAccountInputEnvelopeSchema } from './CollaborationCreateManyAccountInputEnvelopeSchema';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema } from './CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema';
import { CollaborationUpdateManyWithWhereWithoutAccountInputSchema } from './CollaborationUpdateManyWithWhereWithoutAccountInputSchema';
import { CollaborationScalarWhereInputSchema } from './CollaborationScalarWhereInputSchema';

export const CollaborationUncheckedUpdateManyWithoutAccountNestedInputSchema: z.ZodType<Prisma.CollaborationUncheckedUpdateManyWithoutAccountNestedInput> = z.object({
  create: z.union([ z.lazy(() => CollaborationCreateWithoutAccountInputSchema),z.lazy(() => CollaborationCreateWithoutAccountInputSchema).array(),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutAccountInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CollaborationCreateOrConnectWithoutAccountInputSchema),z.lazy(() => CollaborationCreateOrConnectWithoutAccountInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => CollaborationUpsertWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CollaborationCreateManyAccountInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CollaborationWhereUniqueInputSchema),z.lazy(() => CollaborationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema),z.lazy(() => CollaborationUpdateWithWhereUniqueWithoutAccountInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CollaborationUpdateManyWithWhereWithoutAccountInputSchema),z.lazy(() => CollaborationUpdateManyWithWhereWithoutAccountInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CollaborationScalarWhereInputSchema),z.lazy(() => CollaborationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default CollaborationUncheckedUpdateManyWithoutAccountNestedInputSchema;
