// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountFindManyArgsSchema } from "../outputTypeSchemas/RolesOnAccountFindManyArgsSchema"
import { RolesOnCollaborationFindManyArgsSchema } from "../outputTypeSchemas/RolesOnCollaborationFindManyArgsSchema"
import { RoleCountOutputTypeArgsSchema } from "../outputTypeSchemas/RoleCountOutputTypeArgsSchema"

export const RoleSelectSchema: z.ZodType<Prisma.RoleSelect> = z.object({
  id: z.boolean().optional(),
  description: z.boolean().optional(),
  name: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  accounts: z.union([z.boolean(),z.lazy(() => RolesOnAccountFindManyArgsSchema)]).optional(),
  collaborations: z.union([z.boolean(),z.lazy(() => RolesOnCollaborationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => RoleCountOutputTypeArgsSchema)]).optional(),
}).strict()

export default RoleSelectSchema;
