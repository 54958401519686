// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereInputSchema'
import { ColegioMedicoOrderByWithRelationInputSchema } from '../inputTypeSchemas/ColegioMedicoOrderByWithRelationInputSchema'
import { ColegioMedicoWhereUniqueInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereUniqueInputSchema'

export const ColegioMedicoAggregateArgsSchema: z.ZodType<Prisma.ColegioMedicoAggregateArgs> = z.object({
  where: ColegioMedicoWhereInputSchema.optional(),
  orderBy: z.union([ ColegioMedicoOrderByWithRelationInputSchema.array(),ColegioMedicoOrderByWithRelationInputSchema ]).optional(),
  cursor: ColegioMedicoWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ColegioMedicoAggregateArgsSchema;
