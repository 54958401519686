// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutReceivedCollaborationInvitationsInputSchema;
