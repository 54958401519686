// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventCreateWithoutOrganizationInputSchema } from './EventCreateWithoutOrganizationInputSchema';
import { EventUncheckedCreateWithoutOrganizationInputSchema } from './EventUncheckedCreateWithoutOrganizationInputSchema';
import { EventCreateOrConnectWithoutOrganizationInputSchema } from './EventCreateOrConnectWithoutOrganizationInputSchema';
import { EventCreateManyOrganizationInputEnvelopeSchema } from './EventCreateManyOrganizationInputEnvelopeSchema';
import { EventWhereUniqueInputSchema } from './EventWhereUniqueInputSchema';

export const EventCreateNestedManyWithoutOrganizationInputSchema: z.ZodType<Prisma.EventCreateNestedManyWithoutOrganizationInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutOrganizationInputSchema),z.lazy(() => EventCreateWithoutOrganizationInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutOrganizationInputSchema),z.lazy(() => EventCreateOrConnectWithoutOrganizationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyOrganizationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export default EventCreateNestedManyWithoutOrganizationInputSchema;
