// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpUpdateManyMutationInputSchema } from '../inputTypeSchemas/OtpUpdateManyMutationInputSchema'
import { OtpUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/OtpUncheckedUpdateManyInputSchema'
import { OtpWhereInputSchema } from '../inputTypeSchemas/OtpWhereInputSchema'

export const OtpUpdateManyArgsSchema: z.ZodType<Prisma.OtpUpdateManyArgs> = z.object({
  data: z.union([ OtpUpdateManyMutationInputSchema,OtpUncheckedUpdateManyInputSchema ]),
  where: OtpWhereInputSchema.optional(),
}).strict() ;

export default OtpUpdateManyArgsSchema;
