// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// ROLES ON COLLABORATION SCHEMA
/////////////////////////////////////////

export const RolesOnCollaborationSchema = z.object({
  id: z.string(),
  collaborationId: z.string(),
  roleId: z.string(),
})

export type RolesOnCollaboration = z.infer<typeof RolesOnCollaborationSchema>

export default RolesOnCollaborationSchema;
