// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentWhereUniqueInputSchema } from './ReportedContentWhereUniqueInputSchema';
import { ReportedContentCreateWithoutReportedByInputSchema } from './ReportedContentCreateWithoutReportedByInputSchema';
import { ReportedContentUncheckedCreateWithoutReportedByInputSchema } from './ReportedContentUncheckedCreateWithoutReportedByInputSchema';

export const ReportedContentCreateOrConnectWithoutReportedByInputSchema: z.ZodType<Prisma.ReportedContentCreateOrConnectWithoutReportedByInput> = z.object({
  where: z.lazy(() => ReportedContentWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ReportedContentCreateWithoutReportedByInputSchema),z.lazy(() => ReportedContentUncheckedCreateWithoutReportedByInputSchema) ]),
}).strict();

export default ReportedContentCreateOrConnectWithoutReportedByInputSchema;
