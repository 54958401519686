// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationInvitationUpdateManyMutationInputSchema } from '../inputTypeSchemas/CollaborationInvitationUpdateManyMutationInputSchema'
import { CollaborationInvitationUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/CollaborationInvitationUncheckedUpdateManyInputSchema'
import { CollaborationInvitationWhereInputSchema } from '../inputTypeSchemas/CollaborationInvitationWhereInputSchema'

export const CollaborationInvitationUpdateManyArgsSchema: z.ZodType<Prisma.CollaborationInvitationUpdateManyArgs> = z.object({
  data: z.union([ CollaborationInvitationUpdateManyMutationInputSchema,CollaborationInvitationUncheckedUpdateManyInputSchema ]),
  where: CollaborationInvitationWhereInputSchema.optional(),
}).strict() ;

export default CollaborationInvitationUpdateManyArgsSchema;
