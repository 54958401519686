// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { PushTokensIncludeSchema } from '../inputTypeSchemas/PushTokensIncludeSchema'
import { PushTokensCreateInputSchema } from '../inputTypeSchemas/PushTokensCreateInputSchema'
import { PushTokensUncheckedCreateInputSchema } from '../inputTypeSchemas/PushTokensUncheckedCreateInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const PushTokensSelectSchema: z.ZodType<Prisma.PushTokensSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  pushToken: z.boolean().optional(),
  created: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const PushTokensCreateArgsSchema: z.ZodType<Prisma.PushTokensCreateArgs> = z.object({
  select: PushTokensSelectSchema.optional(),
  include: PushTokensIncludeSchema.optional(),
  data: z.union([ PushTokensCreateInputSchema,PushTokensUncheckedCreateInputSchema ]),
}).strict() ;

export default PushTokensCreateArgsSchema;
