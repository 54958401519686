// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteWhereInputSchema } from '../inputTypeSchemas/SiteWhereInputSchema'
import { SiteOrderByWithAggregationInputSchema } from '../inputTypeSchemas/SiteOrderByWithAggregationInputSchema'
import { SiteScalarFieldEnumSchema } from '../inputTypeSchemas/SiteScalarFieldEnumSchema'
import { SiteScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/SiteScalarWhereWithAggregatesInputSchema'

export const SiteGroupByArgsSchema: z.ZodType<Prisma.SiteGroupByArgs> = z.object({
  where: SiteWhereInputSchema.optional(),
  orderBy: z.union([ SiteOrderByWithAggregationInputSchema.array(),SiteOrderByWithAggregationInputSchema ]).optional(),
  by: SiteScalarFieldEnumSchema.array(),
  having: SiteScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default SiteGroupByArgsSchema;
