// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { RolesOnCollaborationCountOrderByAggregateInputSchema } from './RolesOnCollaborationCountOrderByAggregateInputSchema';
import { RolesOnCollaborationMaxOrderByAggregateInputSchema } from './RolesOnCollaborationMaxOrderByAggregateInputSchema';
import { RolesOnCollaborationMinOrderByAggregateInputSchema } from './RolesOnCollaborationMinOrderByAggregateInputSchema';

export const RolesOnCollaborationOrderByWithAggregationInputSchema: z.ZodType<Prisma.RolesOnCollaborationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  collaborationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => RolesOnCollaborationCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => RolesOnCollaborationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => RolesOnCollaborationMinOrderByAggregateInputSchema).optional()
}).strict();

export default RolesOnCollaborationOrderByWithAggregationInputSchema;
