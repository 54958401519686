// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ExamLabWhereInputSchema } from './ExamLabWhereInputSchema';
import { StringFilterSchema } from './StringFilterSchema';

export const ExamLabWhereUniqueInputSchema: z.ZodType<Prisma.ExamLabWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => ExamLabWhereInputSchema),z.lazy(() => ExamLabWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ExamLabWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ExamLabWhereInputSchema),z.lazy(() => ExamLabWhereInputSchema).array() ]).optional(),
  name_es: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  category: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict());

export default ExamLabWhereUniqueInputSchema;
