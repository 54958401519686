// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const RolesOnCollaborationCreateManyRoleInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateManyRoleInput> = z.object({
  id: z.string().optional(),
  collaborationId: z.string()
}).strict();

export default RolesOnCollaborationCreateManyRoleInputSchema;
