// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleUpdateWithoutCollaborationsInputSchema } from './RoleUpdateWithoutCollaborationsInputSchema';
import { RoleUncheckedUpdateWithoutCollaborationsInputSchema } from './RoleUncheckedUpdateWithoutCollaborationsInputSchema';
import { RoleCreateWithoutCollaborationsInputSchema } from './RoleCreateWithoutCollaborationsInputSchema';
import { RoleUncheckedCreateWithoutCollaborationsInputSchema } from './RoleUncheckedCreateWithoutCollaborationsInputSchema';
import { RoleWhereInputSchema } from './RoleWhereInputSchema';

export const RoleUpsertWithoutCollaborationsInputSchema: z.ZodType<Prisma.RoleUpsertWithoutCollaborationsInput> = z.object({
  update: z.union([ z.lazy(() => RoleUpdateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutCollaborationsInputSchema) ]),
  create: z.union([ z.lazy(() => RoleCreateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutCollaborationsInputSchema) ]),
  where: z.lazy(() => RoleWhereInputSchema).optional()
}).strict();

export default RoleUpsertWithoutCollaborationsInputSchema;
