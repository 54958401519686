// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabUpdateManyMutationInputSchema } from '../inputTypeSchemas/ExamLabUpdateManyMutationInputSchema'
import { ExamLabUncheckedUpdateManyInputSchema } from '../inputTypeSchemas/ExamLabUncheckedUpdateManyInputSchema'
import { ExamLabWhereInputSchema } from '../inputTypeSchemas/ExamLabWhereInputSchema'

export const ExamLabUpdateManyArgsSchema: z.ZodType<Prisma.ExamLabUpdateManyArgs> = z.object({
  data: z.union([ ExamLabUpdateManyMutationInputSchema,ExamLabUncheckedUpdateManyInputSchema ]),
  where: ExamLabWhereInputSchema.optional(),
}).strict() ;

export default ExamLabUpdateManyArgsSchema;
