// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationWhereInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereInputSchema'
import { RolesOnCollaborationOrderByWithAggregationInputSchema } from '../inputTypeSchemas/RolesOnCollaborationOrderByWithAggregationInputSchema'
import { RolesOnCollaborationScalarFieldEnumSchema } from '../inputTypeSchemas/RolesOnCollaborationScalarFieldEnumSchema'
import { RolesOnCollaborationScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/RolesOnCollaborationScalarWhereWithAggregatesInputSchema'

export const RolesOnCollaborationGroupByArgsSchema: z.ZodType<Prisma.RolesOnCollaborationGroupByArgs> = z.object({
  where: RolesOnCollaborationWhereInputSchema.optional(),
  orderBy: z.union([ RolesOnCollaborationOrderByWithAggregationInputSchema.array(),RolesOnCollaborationOrderByWithAggregationInputSchema ]).optional(),
  by: RolesOnCollaborationScalarFieldEnumSchema.array(),
  having: RolesOnCollaborationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default RolesOnCollaborationGroupByArgsSchema;
