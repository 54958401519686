// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereUniqueInputSchema } from './CollaborationWhereUniqueInputSchema';
import { CollaborationUpdateWithoutCollaboratorInputSchema } from './CollaborationUpdateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedUpdateWithoutCollaboratorInputSchema } from './CollaborationUncheckedUpdateWithoutCollaboratorInputSchema';
import { CollaborationCreateWithoutCollaboratorInputSchema } from './CollaborationCreateWithoutCollaboratorInputSchema';
import { CollaborationUncheckedCreateWithoutCollaboratorInputSchema } from './CollaborationUncheckedCreateWithoutCollaboratorInputSchema';

export const CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUpsertWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CollaborationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CollaborationUpdateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutCollaboratorInputSchema) ]),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutCollaboratorInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export default CollaborationUpsertWithWhereUniqueWithoutCollaboratorInputSchema;
