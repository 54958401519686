// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountScalarWhereInputSchema } from './RolesOnAccountScalarWhereInputSchema';
import { RolesOnAccountUpdateManyMutationInputSchema } from './RolesOnAccountUpdateManyMutationInputSchema';
import { RolesOnAccountUncheckedUpdateManyWithoutRoleInputSchema } from './RolesOnAccountUncheckedUpdateManyWithoutRoleInputSchema';

export const RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateManyWithWhereWithoutRoleInput> = z.object({
  where: z.lazy(() => RolesOnAccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RolesOnAccountUpdateManyMutationInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateManyWithoutRoleInputSchema) ]),
}).strict();

export default RolesOnAccountUpdateManyWithWhereWithoutRoleInputSchema;
