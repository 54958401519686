// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';

export const PushTokensOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PushTokensOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export default PushTokensOrderByRelationAggregateInputSchema;
