// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"

export const CollaborationInvitationSelectSchema: z.ZodType<Prisma.CollaborationInvitationSelect> = z.object({
  id: z.boolean().optional(),
  inviteeId: z.boolean().optional(),
  inviteeEmail: z.boolean().optional(),
  status: z.boolean().optional(),
  accountId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  invitee: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export default CollaborationInvitationSelectSchema;
