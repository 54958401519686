// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { LANGSchema } from './LANGSchema';
import { NestedIntNullableFilterSchema } from './NestedIntNullableFilterSchema';
import { NestedEnumLANGNullableFilterSchema } from './NestedEnumLANGNullableFilterSchema';

export const NestedEnumLANGNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumLANGNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => LANGSchema).optional().nullable(),
  in: z.lazy(() => LANGSchema).array().optional().nullable(),
  notIn: z.lazy(() => LANGSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => LANGSchema),z.lazy(() => NestedEnumLANGNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumLANGNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumLANGNullableFilterSchema).optional()
}).strict();

export default NestedEnumLANGNullableWithAggregatesFilterSchema;
