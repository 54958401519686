// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductWhereInputSchema } from '../inputTypeSchemas/IspProductWhereInputSchema'

export const IspProductDeleteManyArgsSchema: z.ZodType<Prisma.IspProductDeleteManyArgs> = z.object({
  where: IspProductWhereInputSchema.optional(),
}).strict() ;

export default IspProductDeleteManyArgsSchema;
