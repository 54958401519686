// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteWhereInputSchema } from '../inputTypeSchemas/SiteWhereInputSchema'

export const SiteDeleteManyArgsSchema: z.ZodType<Prisma.SiteDeleteManyArgs> = z.object({
  where: SiteWhereInputSchema.optional(),
}).strict() ;

export default SiteDeleteManyArgsSchema;
