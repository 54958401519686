// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RoleWhereUniqueInputSchema } from './RoleWhereUniqueInputSchema';
import { RoleCreateWithoutCollaborationsInputSchema } from './RoleCreateWithoutCollaborationsInputSchema';
import { RoleUncheckedCreateWithoutCollaborationsInputSchema } from './RoleUncheckedCreateWithoutCollaborationsInputSchema';

export const RoleCreateOrConnectWithoutCollaborationsInputSchema: z.ZodType<Prisma.RoleCreateOrConnectWithoutCollaborationsInput> = z.object({
  where: z.lazy(() => RoleWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RoleCreateWithoutCollaborationsInputSchema),z.lazy(() => RoleUncheckedCreateWithoutCollaborationsInputSchema) ]),
}).strict();

export default RoleCreateOrConnectWithoutCollaborationsInputSchema;
