// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutCollaborationsInputSchema } from './AccountCreateNestedOneWithoutCollaborationsInputSchema';
import { RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema';

export const CollaborationCreateWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationCreateWithoutAccountInput> = z.object({
  id: z.string().optional(),
  showInSearch: z.boolean().optional(),
  collaborator: z.lazy(() => AccountCreateNestedOneWithoutCollaborationsInputSchema),
  roles: z.lazy(() => RolesOnCollaborationCreateNestedManyWithoutCollaborationInputSchema).optional()
}).strict();

export default CollaborationCreateWithoutAccountInputSchema;
