// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';
import { CollaborationUpdateWithoutRolesInputSchema } from './CollaborationUpdateWithoutRolesInputSchema';
import { CollaborationUncheckedUpdateWithoutRolesInputSchema } from './CollaborationUncheckedUpdateWithoutRolesInputSchema';

export const CollaborationUpdateToOneWithWhereWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationUpdateToOneWithWhereWithoutRolesInput> = z.object({
  where: z.lazy(() => CollaborationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CollaborationUpdateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutRolesInputSchema) ]),
}).strict();

export default CollaborationUpdateToOneWithWhereWithoutRolesInputSchema;
