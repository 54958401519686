// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnCollaborationIncludeSchema } from '../inputTypeSchemas/RolesOnCollaborationIncludeSchema'
import { RolesOnCollaborationWhereUniqueInputSchema } from '../inputTypeSchemas/RolesOnCollaborationWhereUniqueInputSchema'
import { CollaborationArgsSchema } from "../outputTypeSchemas/CollaborationArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const RolesOnCollaborationSelectSchema: z.ZodType<Prisma.RolesOnCollaborationSelect> = z.object({
  id: z.boolean().optional(),
  collaborationId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  collaboration: z.union([z.boolean(),z.lazy(() => CollaborationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export const RolesOnCollaborationFindUniqueArgsSchema: z.ZodType<Prisma.RolesOnCollaborationFindUniqueArgs> = z.object({
  select: RolesOnCollaborationSelectSchema.optional(),
  include: RolesOnCollaborationIncludeSchema.optional(),
  where: RolesOnCollaborationWhereUniqueInputSchema,
}).strict() ;

export default RolesOnCollaborationFindUniqueArgsSchema;
