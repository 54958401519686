// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamLabWhereInputSchema } from '../inputTypeSchemas/ExamLabWhereInputSchema'
import { ExamLabOrderByWithAggregationInputSchema } from '../inputTypeSchemas/ExamLabOrderByWithAggregationInputSchema'
import { ExamLabScalarFieldEnumSchema } from '../inputTypeSchemas/ExamLabScalarFieldEnumSchema'
import { ExamLabScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/ExamLabScalarWhereWithAggregatesInputSchema'

export const ExamLabGroupByArgsSchema: z.ZodType<Prisma.ExamLabGroupByArgs> = z.object({
  where: ExamLabWhereInputSchema.optional(),
  orderBy: z.union([ ExamLabOrderByWithAggregationInputSchema.array(),ExamLabOrderByWithAggregationInputSchema ]).optional(),
  by: ExamLabScalarFieldEnumSchema.array(),
  having: ExamLabScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ExamLabGroupByArgsSchema;
