// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountCreateWithoutReceivedCollaborationInvitationsInputSchema';
import { AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema } from './AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema';

export const AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutReceivedCollaborationInvitationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReceivedCollaborationInvitationsInputSchema) ]),
}).strict();

export default AccountCreateOrConnectWithoutReceivedCollaborationInvitationsInputSchema;
