// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { ExamLabCountOrderByAggregateInputSchema } from './ExamLabCountOrderByAggregateInputSchema';
import { ExamLabMaxOrderByAggregateInputSchema } from './ExamLabMaxOrderByAggregateInputSchema';
import { ExamLabMinOrderByAggregateInputSchema } from './ExamLabMinOrderByAggregateInputSchema';

export const ExamLabOrderByWithAggregationInputSchema: z.ZodType<Prisma.ExamLabOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name_es: z.lazy(() => SortOrderSchema).optional(),
  category: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ExamLabCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ExamLabMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ExamLabMinOrderByAggregateInputSchema).optional()
}).strict();

export default ExamLabOrderByWithAggregationInputSchema;
