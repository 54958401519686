// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';

export const CollaborationListRelationFilterSchema: z.ZodType<Prisma.CollaborationListRelationFilter> = z.object({
  every: z.lazy(() => CollaborationWhereInputSchema).optional(),
  some: z.lazy(() => CollaborationWhereInputSchema).optional(),
  none: z.lazy(() => CollaborationWhereInputSchema).optional()
}).strict();

export default CollaborationListRelationFilterSchema;
