// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ExamImagesWhereInputSchema } from '../inputTypeSchemas/ExamImagesWhereInputSchema'
import { ExamImagesOrderByWithRelationInputSchema } from '../inputTypeSchemas/ExamImagesOrderByWithRelationInputSchema'
import { ExamImagesWhereUniqueInputSchema } from '../inputTypeSchemas/ExamImagesWhereUniqueInputSchema'
import { ExamImagesScalarFieldEnumSchema } from '../inputTypeSchemas/ExamImagesScalarFieldEnumSchema'
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const ExamImagesSelectSchema: z.ZodType<Prisma.ExamImagesSelect> = z.object({
  id: z.boolean().optional(),
  bodyArea: z.boolean().optional(),
  bodyPart: z.boolean().optional(),
  code: z.boolean().optional(),
  name_es: z.boolean().optional(),
  symptom: z.boolean().optional(),
  type: z.boolean().optional(),
}).strict()

export const ExamImagesFindManyArgsSchema: z.ZodType<Prisma.ExamImagesFindManyArgs> = z.object({
  select: ExamImagesSelectSchema.optional(),
  where: ExamImagesWhereInputSchema.optional(),
  orderBy: z.union([ ExamImagesOrderByWithRelationInputSchema.array(),ExamImagesOrderByWithRelationInputSchema ]).optional(),
  cursor: ExamImagesWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ExamImagesScalarFieldEnumSchema,ExamImagesScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export default ExamImagesFindManyArgsSchema;
