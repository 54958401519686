// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringFieldUpdateOperationsInputSchema } from './StringFieldUpdateOperationsInputSchema';
import { RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema } from './RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema';

export const RolesOnCollaborationUpdateWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationUpdateWithoutCollaborationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.lazy(() => RoleUpdateOneRequiredWithoutCollaborationsNestedInputSchema).optional()
}).strict();

export default RolesOnCollaborationUpdateWithoutCollaborationInputSchema;
