// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountCreateManyInputSchema } from '../inputTypeSchemas/RolesOnAccountCreateManyInputSchema'

export const RolesOnAccountCreateManyArgsSchema: z.ZodType<Prisma.RolesOnAccountCreateManyArgs> = z.object({
  data: z.union([ RolesOnAccountCreateManyInputSchema,RolesOnAccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default RolesOnAccountCreateManyArgsSchema;
