// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { DomainActivationStatusSchema } from './DomainActivationStatusSchema';
import { NestedEnumDomainActivationStatusWithAggregatesFilterSchema } from './NestedEnumDomainActivationStatusWithAggregatesFilterSchema';
import { NestedIntFilterSchema } from './NestedIntFilterSchema';
import { NestedEnumDomainActivationStatusFilterSchema } from './NestedEnumDomainActivationStatusFilterSchema';

export const EnumDomainActivationStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumDomainActivationStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => DomainActivationStatusSchema).optional(),
  in: z.lazy(() => DomainActivationStatusSchema).array().optional(),
  notIn: z.lazy(() => DomainActivationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DomainActivationStatusSchema),z.lazy(() => NestedEnumDomainActivationStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumDomainActivationStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumDomainActivationStatusFilterSchema).optional()
}).strict();

export default EnumDomainActivationStatusWithAggregatesFilterSchema;
