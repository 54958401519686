// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsWhereInputSchema } from '../inputTypeSchemas/ProductExamsWhereInputSchema'
import { ProductExamsOrderByWithRelationInputSchema } from '../inputTypeSchemas/ProductExamsOrderByWithRelationInputSchema'
import { ProductExamsWhereUniqueInputSchema } from '../inputTypeSchemas/ProductExamsWhereUniqueInputSchema'

export const ProductExamsAggregateArgsSchema: z.ZodType<Prisma.ProductExamsAggregateArgs> = z.object({
  where: ProductExamsWhereInputSchema.optional(),
  orderBy: z.union([ ProductExamsOrderByWithRelationInputSchema.array(),ProductExamsOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductExamsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ProductExamsAggregateArgsSchema;
