// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { JsonWithAggregatesFilterSchema } from './JsonWithAggregatesFilterSchema';
import { JsonNullableWithAggregatesFilterSchema } from './JsonNullableWithAggregatesFilterSchema';

export const IspProductScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.IspProductScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => IspProductScalarWhereWithAggregatesInputSchema),z.lazy(() => IspProductScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => IspProductScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => IspProductScalarWhereWithAggregatesInputSchema),z.lazy(() => IspProductScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  record: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  transactionReference: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  therapeuticEquivalenceOrBiosimilar: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  holder: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  recordStatus: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  registrationResolution: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  registrationDate: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastRenewal: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  nextRenewalDate: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  regime: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  administrationRoute: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  salesCondition: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  establishmentType: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  indication: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  packagings: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  companyFunctions: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  formulas: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  cenabast: z.lazy(() => JsonNullableWithAggregatesFilterSchema).optional()
}).strict();

export default IspProductScalarWhereWithAggregatesInputSchema;
