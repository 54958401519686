// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ColegioMedicoWhereInputSchema } from '../inputTypeSchemas/ColegioMedicoWhereInputSchema'

export const ColegioMedicoDeleteManyArgsSchema: z.ZodType<Prisma.ColegioMedicoDeleteManyArgs> = z.object({
  where: ColegioMedicoWhereInputSchema.optional(),
}).strict() ;

export default ColegioMedicoDeleteManyArgsSchema;
