// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';

export const RolesOnAccountCreateManyInputSchema: z.ZodType<Prisma.RolesOnAccountCreateManyInput> = z.object({
  id: z.string().optional(),
  accountId: z.string(),
  roleId: z.string()
}).strict();

export default RolesOnAccountCreateManyInputSchema;
