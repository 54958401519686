// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ReportedContentScalarWhereInputSchema } from './ReportedContentScalarWhereInputSchema';
import { ReportedContentUpdateManyMutationInputSchema } from './ReportedContentUpdateManyMutationInputSchema';
import { ReportedContentUncheckedUpdateManyWithoutReportedByInputSchema } from './ReportedContentUncheckedUpdateManyWithoutReportedByInputSchema';

export const ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema: z.ZodType<Prisma.ReportedContentUpdateManyWithWhereWithoutReportedByInput> = z.object({
  where: z.lazy(() => ReportedContentScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ReportedContentUpdateManyMutationInputSchema),z.lazy(() => ReportedContentUncheckedUpdateManyWithoutReportedByInputSchema) ]),
}).strict();

export default ReportedContentUpdateManyWithWhereWithoutReportedByInputSchema;
