// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { OtpCreateManyInputSchema } from '../inputTypeSchemas/OtpCreateManyInputSchema'

export const OtpCreateManyArgsSchema: z.ZodType<Prisma.OtpCreateManyArgs> = z.object({
  data: z.union([ OtpCreateManyInputSchema,OtpCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export default OtpCreateManyArgsSchema;
