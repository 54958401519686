// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutReportedInputSchema } from './AccountCreateWithoutReportedInputSchema';
import { AccountUncheckedCreateWithoutReportedInputSchema } from './AccountUncheckedCreateWithoutReportedInputSchema';
import { AccountCreateOrConnectWithoutReportedInputSchema } from './AccountCreateOrConnectWithoutReportedInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutReportedInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutReportedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutReportedInputSchema),z.lazy(() => AccountUncheckedCreateWithoutReportedInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutReportedInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutReportedInputSchema;
