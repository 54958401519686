// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { EventCreateWithoutOrganizationInputSchema } from './EventCreateWithoutOrganizationInputSchema';
import { EventUncheckedCreateWithoutOrganizationInputSchema } from './EventUncheckedCreateWithoutOrganizationInputSchema';
import { EventCreateOrConnectWithoutOrganizationInputSchema } from './EventCreateOrConnectWithoutOrganizationInputSchema';
import { EventUpsertWithWhereUniqueWithoutOrganizationInputSchema } from './EventUpsertWithWhereUniqueWithoutOrganizationInputSchema';
import { EventCreateManyOrganizationInputEnvelopeSchema } from './EventCreateManyOrganizationInputEnvelopeSchema';
import { EventWhereUniqueInputSchema } from './EventWhereUniqueInputSchema';
import { EventUpdateWithWhereUniqueWithoutOrganizationInputSchema } from './EventUpdateWithWhereUniqueWithoutOrganizationInputSchema';
import { EventUpdateManyWithWhereWithoutOrganizationInputSchema } from './EventUpdateManyWithWhereWithoutOrganizationInputSchema';
import { EventScalarWhereInputSchema } from './EventScalarWhereInputSchema';

export const EventUpdateManyWithoutOrganizationNestedInputSchema: z.ZodType<Prisma.EventUpdateManyWithoutOrganizationNestedInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutOrganizationInputSchema),z.lazy(() => EventCreateWithoutOrganizationInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema),z.lazy(() => EventUncheckedCreateWithoutOrganizationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutOrganizationInputSchema),z.lazy(() => EventCreateOrConnectWithoutOrganizationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => EventUpsertWithWhereUniqueWithoutOrganizationInputSchema),z.lazy(() => EventUpsertWithWhereUniqueWithoutOrganizationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyOrganizationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => EventUpdateWithWhereUniqueWithoutOrganizationInputSchema),z.lazy(() => EventUpdateWithWhereUniqueWithoutOrganizationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => EventUpdateManyWithWhereWithoutOrganizationInputSchema),z.lazy(() => EventUpdateManyWithWhereWithoutOrganizationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => EventScalarWhereInputSchema),z.lazy(() => EventScalarWhereInputSchema).array() ]).optional(),
}).strict();

export default EventUpdateManyWithoutOrganizationNestedInputSchema;
