// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RolesOnAccountFindManyArgsSchema } from "../outputTypeSchemas/RolesOnAccountFindManyArgsSchema"
import { CollaborationFindManyArgsSchema } from "../outputTypeSchemas/CollaborationFindManyArgsSchema"
import { CollaborationInvitationFindManyArgsSchema } from "../outputTypeSchemas/CollaborationInvitationFindManyArgsSchema"
import { EventFindManyArgsSchema } from "../outputTypeSchemas/EventFindManyArgsSchema"
import { SiteFindManyArgsSchema } from "../outputTypeSchemas/SiteFindManyArgsSchema"
import { AccountFindManyArgsSchema } from "../outputTypeSchemas/AccountFindManyArgsSchema"
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { PushTokensFindManyArgsSchema } from "../outputTypeSchemas/PushTokensFindManyArgsSchema"
import { ReportedContentFindManyArgsSchema } from "../outputTypeSchemas/ReportedContentFindManyArgsSchema"
import { AccountCountOutputTypeArgsSchema } from "../outputTypeSchemas/AccountCountOutputTypeArgsSchema"

export const AccountIncludeSchema: z.ZodType<Prisma.AccountInclude> = z.object({
  roles: z.union([z.boolean(),z.lazy(() => RolesOnAccountFindManyArgsSchema)]).optional(),
  initiatedCollaborations: z.union([z.boolean(),z.lazy(() => CollaborationFindManyArgsSchema)]).optional(),
  collaborations: z.union([z.boolean(),z.lazy(() => CollaborationFindManyArgsSchema)]).optional(),
  receivedCollaborationInvitations: z.union([z.boolean(),z.lazy(() => CollaborationInvitationFindManyArgsSchema)]).optional(),
  sentCollaborationInvitations: z.union([z.boolean(),z.lazy(() => CollaborationInvitationFindManyArgsSchema)]).optional(),
  events: z.union([z.boolean(),z.lazy(() => EventFindManyArgsSchema)]).optional(),
  sites: z.union([z.boolean(),z.lazy(() => SiteFindManyArgsSchema)]).optional(),
  ownedAccounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  owner: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  pushTokens: z.union([z.boolean(),z.lazy(() => PushTokensFindManyArgsSchema)]).optional(),
  reported: z.union([z.boolean(),z.lazy(() => ReportedContentFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => AccountCountOutputTypeArgsSchema)]).optional(),
}).strict()

export default AccountIncludeSchema;
