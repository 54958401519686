// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateWithoutOwnerInputSchema } from './AccountUpdateWithoutOwnerInputSchema';
import { AccountUncheckedUpdateWithoutOwnerInputSchema } from './AccountUncheckedUpdateWithoutOwnerInputSchema';
import { AccountCreateWithoutOwnerInputSchema } from './AccountCreateWithoutOwnerInputSchema';
import { AccountUncheckedCreateWithoutOwnerInputSchema } from './AccountUncheckedCreateWithoutOwnerInputSchema';

export const AccountUpsertWithWhereUniqueWithoutOwnerInputSchema: z.ZodType<Prisma.AccountUpsertWithWhereUniqueWithoutOwnerInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AccountUpdateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutOwnerInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnerInputSchema) ]),
}).strict();

export default AccountUpsertWithWhereUniqueWithoutOwnerInputSchema;
