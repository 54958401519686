// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { ColegioMedicoCountOrderByAggregateInputSchema } from './ColegioMedicoCountOrderByAggregateInputSchema';
import { ColegioMedicoMaxOrderByAggregateInputSchema } from './ColegioMedicoMaxOrderByAggregateInputSchema';
import { ColegioMedicoMinOrderByAggregateInputSchema } from './ColegioMedicoMinOrderByAggregateInputSchema';

export const ColegioMedicoOrderByWithAggregationInputSchema: z.ZodType<Prisma.ColegioMedicoOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  icm: z.lazy(() => SortOrderSchema).optional(),
  names: z.lazy(() => SortOrderSchema).optional(),
  lastNames: z.lazy(() => SortOrderSchema).optional(),
  afiliation: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ColegioMedicoCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ColegioMedicoMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ColegioMedicoMinOrderByAggregateInputSchema).optional()
}).strict();

export default ColegioMedicoOrderByWithAggregationInputSchema;
