// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema } from './AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema';
import { AccountCreateNestedOneWithoutCollaborationsInputSchema } from './AccountCreateNestedOneWithoutCollaborationsInputSchema';

export const CollaborationCreateWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationCreateWithoutRolesInput> = z.object({
  id: z.string().optional(),
  showInSearch: z.boolean().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutInitiatedCollaborationsInputSchema),
  collaborator: z.lazy(() => AccountCreateNestedOneWithoutCollaborationsInputSchema)
}).strict();

export default CollaborationCreateWithoutRolesInputSchema;
