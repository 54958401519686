// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';

export const AccountCountOutputTypeSelectSchema: z.ZodType<Prisma.AccountCountOutputTypeSelect> = z.object({
  roles: z.boolean().optional(),
  initiatedCollaborations: z.boolean().optional(),
  collaborations: z.boolean().optional(),
  receivedCollaborationInvitations: z.boolean().optional(),
  sentCollaborationInvitations: z.boolean().optional(),
  events: z.boolean().optional(),
  sites: z.boolean().optional(),
  ownedAccounts: z.boolean().optional(),
  pushTokens: z.boolean().optional(),
  reported: z.boolean().optional(),
}).strict();

export default AccountCountOutputTypeSelectSchema;
