// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnAccountScalarWhereInputSchema } from './RolesOnAccountScalarWhereInputSchema';
import { RolesOnAccountUpdateManyMutationInputSchema } from './RolesOnAccountUpdateManyMutationInputSchema';
import { RolesOnAccountUncheckedUpdateManyWithoutAccountInputSchema } from './RolesOnAccountUncheckedUpdateManyWithoutAccountInputSchema';

export const RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema: z.ZodType<Prisma.RolesOnAccountUpdateManyWithWhereWithoutAccountInput> = z.object({
  where: z.lazy(() => RolesOnAccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RolesOnAccountUpdateManyMutationInputSchema),z.lazy(() => RolesOnAccountUncheckedUpdateManyWithoutAccountInputSchema) ]),
}).strict();

export default RolesOnAccountUpdateManyWithWhereWithoutAccountInputSchema;
