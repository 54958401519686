// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
import { RoleArgsSchema } from "../outputTypeSchemas/RoleArgsSchema"

export const RolesOnAccountSelectSchema: z.ZodType<Prisma.RolesOnAccountSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
}).strict()

export default RolesOnAccountSelectSchema;
