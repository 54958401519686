// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationCreateManyAccountInputSchema } from './CollaborationInvitationCreateManyAccountInputSchema';

export const CollaborationInvitationCreateManyAccountInputEnvelopeSchema: z.ZodType<Prisma.CollaborationInvitationCreateManyAccountInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CollaborationInvitationCreateManyAccountInputSchema),z.lazy(() => CollaborationInvitationCreateManyAccountInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default CollaborationInvitationCreateManyAccountInputEnvelopeSchema;
