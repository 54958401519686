// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { StringWithAggregatesFilterSchema } from './StringWithAggregatesFilterSchema';
import { BoolWithAggregatesFilterSchema } from './BoolWithAggregatesFilterSchema';

export const CollaborationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CollaborationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CollaborationScalarWhereWithAggregatesInputSchema),z.lazy(() => CollaborationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CollaborationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CollaborationScalarWhereWithAggregatesInputSchema),z.lazy(() => CollaborationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  showInSearch: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export default CollaborationScalarWhereWithAggregatesInputSchema;
