// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationScalarWhereInputSchema } from './CollaborationScalarWhereInputSchema';
import { CollaborationUpdateManyMutationInputSchema } from './CollaborationUpdateManyMutationInputSchema';
import { CollaborationUncheckedUpdateManyWithoutCollaboratorInputSchema } from './CollaborationUncheckedUpdateManyWithoutCollaboratorInputSchema';

export const CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema: z.ZodType<Prisma.CollaborationUpdateManyWithWhereWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CollaborationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CollaborationUpdateManyMutationInputSchema),z.lazy(() => CollaborationUncheckedUpdateManyWithoutCollaboratorInputSchema) ]),
}).strict();

export default CollaborationUpdateManyWithWhereWithoutCollaboratorInputSchema;
