// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { SortOrderSchema } from './SortOrderSchema';
import { OtpOrderByRelevanceInputSchema } from './OtpOrderByRelevanceInputSchema';

export const OtpOrderByWithRelationInputSchema: z.ZodType<Prisma.OtpOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  exp: z.lazy(() => SortOrderSchema).optional(),
  _relevance: z.lazy(() => OtpOrderByRelevanceInputSchema).optional()
}).strict();

export default OtpOrderByWithRelationInputSchema;
