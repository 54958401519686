// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ProductExamsWhereInputSchema } from '../inputTypeSchemas/ProductExamsWhereInputSchema'
import { ProductExamsOrderByWithAggregationInputSchema } from '../inputTypeSchemas/ProductExamsOrderByWithAggregationInputSchema'
import { ProductExamsScalarFieldEnumSchema } from '../inputTypeSchemas/ProductExamsScalarFieldEnumSchema'
import { ProductExamsScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/ProductExamsScalarWhereWithAggregatesInputSchema'

export const ProductExamsGroupByArgsSchema: z.ZodType<Prisma.ProductExamsGroupByArgs> = z.object({
  where: ProductExamsWhereInputSchema.optional(),
  orderBy: z.union([ ProductExamsOrderByWithAggregationInputSchema.array(),ProductExamsOrderByWithAggregationInputSchema ]).optional(),
  by: ProductExamsScalarFieldEnumSchema.array(),
  having: ProductExamsScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ProductExamsGroupByArgsSchema;
