// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutOwnedAccountsInputSchema } from './AccountCreateWithoutOwnedAccountsInputSchema';
import { AccountUncheckedCreateWithoutOwnedAccountsInputSchema } from './AccountUncheckedCreateWithoutOwnedAccountsInputSchema';
import { AccountCreateOrConnectWithoutOwnedAccountsInputSchema } from './AccountCreateOrConnectWithoutOwnedAccountsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutOwnedAccountsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutOwnedAccountsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutOwnedAccountsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutOwnedAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutOwnedAccountsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutOwnedAccountsInputSchema;
