// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { IspProductWhereInputSchema } from '../inputTypeSchemas/IspProductWhereInputSchema'
import { IspProductOrderByWithAggregationInputSchema } from '../inputTypeSchemas/IspProductOrderByWithAggregationInputSchema'
import { IspProductScalarFieldEnumSchema } from '../inputTypeSchemas/IspProductScalarFieldEnumSchema'
import { IspProductScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/IspProductScalarWhereWithAggregatesInputSchema'

export const IspProductGroupByArgsSchema: z.ZodType<Prisma.IspProductGroupByArgs> = z.object({
  where: IspProductWhereInputSchema.optional(),
  orderBy: z.union([ IspProductOrderByWithAggregationInputSchema.array(),IspProductOrderByWithAggregationInputSchema ]).optional(),
  by: IspProductScalarFieldEnumSchema.array(),
  having: IspProductScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default IspProductGroupByArgsSchema;
