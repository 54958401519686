// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUpdateWithoutInitiatedCollaborationsInputSchema';
import { AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema } from './AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema';

export const AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutInitiatedCollaborationsInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutInitiatedCollaborationsInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutInitiatedCollaborationsInputSchema;
