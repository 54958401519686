// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateNestedOneWithoutPushTokensInputSchema } from './AccountCreateNestedOneWithoutPushTokensInputSchema';

export const PushTokensCreateInputSchema: z.ZodType<Prisma.PushTokensCreateInput> = z.object({
  id: z.string().optional(),
  pushToken: z.string(),
  created: z.coerce.date().optional(),
  updated: z.coerce.date().optional(),
  account: z.lazy(() => AccountCreateNestedOneWithoutPushTokensInputSchema)
}).strict();

export default PushTokensCreateInputSchema;
