// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationUpdateWithoutRolesInputSchema } from './CollaborationUpdateWithoutRolesInputSchema';
import { CollaborationUncheckedUpdateWithoutRolesInputSchema } from './CollaborationUncheckedUpdateWithoutRolesInputSchema';
import { CollaborationCreateWithoutRolesInputSchema } from './CollaborationCreateWithoutRolesInputSchema';
import { CollaborationUncheckedCreateWithoutRolesInputSchema } from './CollaborationUncheckedCreateWithoutRolesInputSchema';
import { CollaborationWhereInputSchema } from './CollaborationWhereInputSchema';

export const CollaborationUpsertWithoutRolesInputSchema: z.ZodType<Prisma.CollaborationUpsertWithoutRolesInput> = z.object({
  update: z.union([ z.lazy(() => CollaborationUpdateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedUpdateWithoutRolesInputSchema) ]),
  create: z.union([ z.lazy(() => CollaborationCreateWithoutRolesInputSchema),z.lazy(() => CollaborationUncheckedCreateWithoutRolesInputSchema) ]),
  where: z.lazy(() => CollaborationWhereInputSchema).optional()
}).strict();

export default CollaborationUpsertWithoutRolesInputSchema;
