// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { CollaborationInvitationWhereUniqueInputSchema } from './CollaborationInvitationWhereUniqueInputSchema';
import { CollaborationInvitationUpdateWithoutAccountInputSchema } from './CollaborationInvitationUpdateWithoutAccountInputSchema';
import { CollaborationInvitationUncheckedUpdateWithoutAccountInputSchema } from './CollaborationInvitationUncheckedUpdateWithoutAccountInputSchema';

export const CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => CollaborationInvitationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CollaborationInvitationUpdateWithoutAccountInputSchema),z.lazy(() => CollaborationInvitationUncheckedUpdateWithoutAccountInputSchema) ]),
}).strict();

export default CollaborationInvitationUpdateWithWhereUniqueWithoutAccountInputSchema;
