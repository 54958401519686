// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { SiteIncludeSchema } from '../inputTypeSchemas/SiteIncludeSchema'
import { SiteWhereUniqueInputSchema } from '../inputTypeSchemas/SiteWhereUniqueInputSchema'
import { AccountArgsSchema } from "../outputTypeSchemas/AccountArgsSchema"
// Select schema needs to be in file to prevent circular imports
//------------------------------------------------------

export const SiteSelectSchema: z.ZodType<Prisma.SiteSelect> = z.object({
  id: z.boolean().optional(),
  domain: z.boolean().optional(),
  status: z.boolean().optional(),
  accountId: z.boolean().optional(),
  created: z.boolean().optional(),
  deleted: z.boolean().optional(),
  updated: z.boolean().optional(),
  account: z.union([z.boolean(),z.lazy(() => AccountArgsSchema)]).optional(),
}).strict()

export const SiteFindUniqueArgsSchema: z.ZodType<Prisma.SiteFindUniqueArgs> = z.object({
  select: SiteSelectSchema.optional(),
  include: SiteIncludeSchema.optional(),
  where: SiteWhereUniqueInputSchema,
}).strict() ;

export default SiteFindUniqueArgsSchema;
