// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { ACCOUNT_VISIBILITYSchema } from './ACCOUNT_VISIBILITYSchema';
import { LANGSchema } from './LANGSchema';
import { NullableJsonNullValueInputSchema } from './NullableJsonNullValueInputSchema';
import { InputJsonValueSchema } from './InputJsonValueSchema';
import { RolesOnAccountUncheckedCreateNestedManyWithoutAccountInputSchema } from './RolesOnAccountUncheckedCreateNestedManyWithoutAccountInputSchema';
import { CollaborationUncheckedCreateNestedManyWithoutCollaboratorInputSchema } from './CollaborationUncheckedCreateNestedManyWithoutCollaboratorInputSchema';
import { CollaborationInvitationUncheckedCreateNestedManyWithoutInviteeInputSchema } from './CollaborationInvitationUncheckedCreateNestedManyWithoutInviteeInputSchema';
import { CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInputSchema } from './CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInputSchema';
import { EventUncheckedCreateNestedManyWithoutOrganizationInputSchema } from './EventUncheckedCreateNestedManyWithoutOrganizationInputSchema';
import { SiteUncheckedCreateNestedManyWithoutAccountInputSchema } from './SiteUncheckedCreateNestedManyWithoutAccountInputSchema';
import { AccountUncheckedCreateNestedManyWithoutOwnerInputSchema } from './AccountUncheckedCreateNestedManyWithoutOwnerInputSchema';
import { PushTokensUncheckedCreateNestedManyWithoutAccountInputSchema } from './PushTokensUncheckedCreateNestedManyWithoutAccountInputSchema';
import { ReportedContentUncheckedCreateNestedManyWithoutReportedByInputSchema } from './ReportedContentUncheckedCreateNestedManyWithoutReportedByInputSchema';

export const AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema: z.ZodType<Prisma.AccountUncheckedCreateWithoutInitiatedCollaborationsInput> = z.object({
  id: z.string().optional(),
  firebaseId: z.string().optional().nullable(),
  wordpressId: z.number().int(),
  wooCommerceTermId: z.number().int(),
  stripeCustomerId: z.string(),
  slug: z.string(),
  email: z.string(),
  visibility: z.lazy(() => ACCOUNT_VISIBILITYSchema).optional(),
  type: z.string().optional().nullable(),
  current_company_name: z.string().optional().nullable(),
  current_position_name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  plan: z.string(),
  language: z.lazy(() => LANGSchema).optional().nullable(),
  logo: z.string().optional().nullable(),
  profilePicture: z.string().optional().nullable(),
  backgroundImage: z.string().optional().nullable(),
  timeZone: z.string().optional().nullable(),
  quickBio: z.string().optional().nullable(),
  about: z.string().optional().nullable(),
  displayName: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  birthDate: z.string().optional().nullable(),
  prescriptionLogo: z.string().optional().nullable(),
  prescriptionSignature: z.string().optional().nullable(),
  medicalSchoolNumber: z.string().optional().nullable(),
  branches: z.string().optional().nullable(),
  workers: z.string().optional().nullable(),
  mainService: z.string().optional().nullable(),
  otherServices: z.string().optional().nullable(),
  color: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  categories: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  categoriesSlugs: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  defaultProductId: z.number().int().optional().nullable(),
  rating: z.number().int().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sex: z.string().optional().nullable(),
  mainMenuId: z.number().int().optional().nullable(),
  landingPageId: z.number().int().optional().nullable(),
  isHighlightedAccount: z.boolean().optional().nullable(),
  postal_code: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  address1: z.string().optional().nullable(),
  address2: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  nationalIdentityNumber: z.string().optional().nullable(),
  marketingConsent: z.boolean().optional().nullable(),
  termsConsent: z.boolean().optional().nullable(),
  ownerId: z.string().optional().nullable(),
  created: z.coerce.date().optional(),
  deleted: z.coerce.date().optional().nullable(),
  updated: z.coerce.date().optional(),
  roles: z.lazy(() => RolesOnAccountUncheckedCreateNestedManyWithoutAccountInputSchema).optional(),
  collaborations: z.lazy(() => CollaborationUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  receivedCollaborationInvitations: z.lazy(() => CollaborationInvitationUncheckedCreateNestedManyWithoutInviteeInputSchema).optional(),
  sentCollaborationInvitations: z.lazy(() => CollaborationInvitationUncheckedCreateNestedManyWithoutAccountInputSchema).optional(),
  events: z.lazy(() => EventUncheckedCreateNestedManyWithoutOrganizationInputSchema).optional(),
  sites: z.lazy(() => SiteUncheckedCreateNestedManyWithoutAccountInputSchema).optional(),
  ownedAccounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutOwnerInputSchema).optional(),
  pushTokens: z.lazy(() => PushTokensUncheckedCreateNestedManyWithoutAccountInputSchema).optional(),
  reported: z.lazy(() => ReportedContentUncheckedCreateNestedManyWithoutReportedByInputSchema).optional()
}).strict();

export default AccountUncheckedCreateWithoutInitiatedCollaborationsInputSchema;
