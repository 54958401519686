// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { PushTokensWhereUniqueInputSchema } from './PushTokensWhereUniqueInputSchema';
import { PushTokensUpdateWithoutAccountInputSchema } from './PushTokensUpdateWithoutAccountInputSchema';
import { PushTokensUncheckedUpdateWithoutAccountInputSchema } from './PushTokensUncheckedUpdateWithoutAccountInputSchema';

export const PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema: z.ZodType<Prisma.PushTokensUpdateWithWhereUniqueWithoutAccountInput> = z.object({
  where: z.lazy(() => PushTokensWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PushTokensUpdateWithoutAccountInputSchema),z.lazy(() => PushTokensUncheckedUpdateWithoutAccountInputSchema) ]),
}).strict();

export default PushTokensUpdateWithWhereUniqueWithoutAccountInputSchema;
