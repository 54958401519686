// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// PUSH TOKENS SCHEMA
/////////////////////////////////////////

export const PushTokensSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  pushToken: z.string(),
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export type PushTokens = z.infer<typeof PushTokensSchema>

export default PushTokensSchema;
