// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CollaborationSelectSchema } from '../inputTypeSchemas/CollaborationSelectSchema';
import { CollaborationIncludeSchema } from '../inputTypeSchemas/CollaborationIncludeSchema';

export const CollaborationArgsSchema: z.ZodType<Prisma.CollaborationDefaultArgs> = z.object({
  select: z.lazy(() => CollaborationSelectSchema).optional(),
  include: z.lazy(() => CollaborationIncludeSchema).optional(),
}).strict();

export default CollaborationArgsSchema;
