// @ts-nocheck
import { z } from 'zod';

/////////////////////////////////////////
// OTP SCHEMA
/////////////////////////////////////////

export const OtpSchema = z.object({
  id: z.string(),
  email: z.string(),
  password: z.string(),
  exp: z.coerce.date(),
})

export type Otp = z.infer<typeof OtpSchema>

export default OtpSchema;
