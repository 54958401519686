// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';
import { AccountUpdateWithoutOwnerInputSchema } from './AccountUpdateWithoutOwnerInputSchema';
import { AccountUncheckedUpdateWithoutOwnerInputSchema } from './AccountUncheckedUpdateWithoutOwnerInputSchema';

export const AccountUpdateWithWhereUniqueWithoutOwnerInputSchema: z.ZodType<Prisma.AccountUpdateWithWhereUniqueWithoutOwnerInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateWithoutOwnerInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutOwnerInputSchema) ]),
}).strict();

export default AccountUpdateWithWhereUniqueWithoutOwnerInputSchema;
