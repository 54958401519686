// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountWhereInputSchema } from './AccountWhereInputSchema';
import { AccountUpdateWithoutRolesInputSchema } from './AccountUpdateWithoutRolesInputSchema';
import { AccountUncheckedUpdateWithoutRolesInputSchema } from './AccountUncheckedUpdateWithoutRolesInputSchema';

export const AccountUpdateToOneWithWhereWithoutRolesInputSchema: z.ZodType<Prisma.AccountUpdateToOneWithWhereWithoutRolesInput> = z.object({
  where: z.lazy(() => AccountWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AccountUpdateWithoutRolesInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutRolesInputSchema) ]),
}).strict();

export default AccountUpdateToOneWithWhereWithoutRolesInputSchema;
