// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationCreateManyCollaborationInputSchema } from './RolesOnCollaborationCreateManyCollaborationInputSchema';

export const RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema: z.ZodType<Prisma.RolesOnCollaborationCreateManyCollaborationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RolesOnCollaborationCreateManyCollaborationInputSchema),z.lazy(() => RolesOnCollaborationCreateManyCollaborationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export default RolesOnCollaborationCreateManyCollaborationInputEnvelopeSchema;
