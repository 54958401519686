// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { RolesOnCollaborationWhereUniqueInputSchema } from './RolesOnCollaborationWhereUniqueInputSchema';
import { RolesOnCollaborationCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationCreateWithoutCollaborationInputSchema';
import { RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema } from './RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema';

export const RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema: z.ZodType<Prisma.RolesOnCollaborationCreateOrConnectWithoutCollaborationInput> = z.object({
  where: z.lazy(() => RolesOnCollaborationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RolesOnCollaborationCreateWithoutCollaborationInputSchema),z.lazy(() => RolesOnCollaborationUncheckedCreateWithoutCollaborationInputSchema) ]),
}).strict();

export default RolesOnCollaborationCreateOrConnectWithoutCollaborationInputSchema;
