// @ts-nocheck
import type { Prisma } from '@prisma/client';

import { z } from 'zod';
import { AccountCreateWithoutCollaborationsInputSchema } from './AccountCreateWithoutCollaborationsInputSchema';
import { AccountUncheckedCreateWithoutCollaborationsInputSchema } from './AccountUncheckedCreateWithoutCollaborationsInputSchema';
import { AccountCreateOrConnectWithoutCollaborationsInputSchema } from './AccountCreateOrConnectWithoutCollaborationsInputSchema';
import { AccountWhereUniqueInputSchema } from './AccountWhereUniqueInputSchema';

export const AccountCreateNestedOneWithoutCollaborationsInputSchema: z.ZodType<Prisma.AccountCreateNestedOneWithoutCollaborationsInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutCollaborationsInputSchema),z.lazy(() => AccountUncheckedCreateWithoutCollaborationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AccountCreateOrConnectWithoutCollaborationsInputSchema).optional(),
  connect: z.lazy(() => AccountWhereUniqueInputSchema).optional()
}).strict();

export default AccountCreateNestedOneWithoutCollaborationsInputSchema;
