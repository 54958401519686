// @ts-nocheck
import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { ReportedContentWhereInputSchema } from '../inputTypeSchemas/ReportedContentWhereInputSchema'
import { ReportedContentOrderByWithAggregationInputSchema } from '../inputTypeSchemas/ReportedContentOrderByWithAggregationInputSchema'
import { ReportedContentScalarFieldEnumSchema } from '../inputTypeSchemas/ReportedContentScalarFieldEnumSchema'
import { ReportedContentScalarWhereWithAggregatesInputSchema } from '../inputTypeSchemas/ReportedContentScalarWhereWithAggregatesInputSchema'

export const ReportedContentGroupByArgsSchema: z.ZodType<Prisma.ReportedContentGroupByArgs> = z.object({
  where: ReportedContentWhereInputSchema.optional(),
  orderBy: z.union([ ReportedContentOrderByWithAggregationInputSchema.array(),ReportedContentOrderByWithAggregationInputSchema ]).optional(),
  by: ReportedContentScalarFieldEnumSchema.array(),
  having: ReportedContentScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export default ReportedContentGroupByArgsSchema;
